import React, { useState } from "react";

// Components
import Screen from "../../../components/atoms/Screen";

import InProcessOrders from "./containers/InProcessOrders";
import CompletedOrders from "./containers/CompletedOrders";
import SectionSelector from "./components/SectionSelector";

const OrdersHomeScreen = ({ navigation }) => {
  const [section, setSection] = useState(0);

  return (
    <Screen>
      <SectionSelector setSection={setSection} section={section} />
      {section === 0 && <InProcessOrders navigation={navigation} />}
      {section === 1 && <CompletedOrders navigation={navigation} />}
    </Screen>
  );
};

export default OrdersHomeScreen;
