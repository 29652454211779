import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useState, useEffect, createContext } from "react";
import { firebaseApp } from "../services/firebase";
import { signInFirebase } from "../redux/user/userActions";
import { connect } from "react-redux";

export const AuthContext = createContext();
const auth = getAuth(firebaseApp);

const AuthProvider = ({ children, signInFirebase }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
   
    onAuthStateChanged(auth, async (user) => {
      setUser(user);
      if (user) {
        const token = await user.getIdToken(true);
        signInFirebase(token);
      }
    });
  }, []);
  
  return (
    <AuthContext.Provider value={{ user }}>{children}</AuthContext.Provider>
  );
};

const mapDispatchToProps = {
  signInFirebase,
};

export default connect(null, mapDispatchToProps)(AuthProvider);
