import React from "react";
import { StyleSheet, View, Text } from "react-native";

// Components
import Screen from "../../../components/atoms/Screen";
import AuthContainer from "../../../components/organisms/auth/AuthContainer";

// Config
import { version } from "../../../../config";

// Constants
import { colors, family, size } from "../../../constants/theme";

const OrdersAuthScreen = () => {
  return (
    <Screen>
      <View style={styles.container}>
        <AuthContainer />
      </View>
      <View style={styles.textContainer}>
        <Text style={styles.text}>{version}</Text>
      </View>
    </Screen>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  textContainer: {
    width: "100%",
    alignItems: "center",
    height: 120,
  },
  text: {
    fontFamily: family.normal,
    color: colors.darkGrey,
    fontSize: size.small,
  },
});

export default OrdersAuthScreen
