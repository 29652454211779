import React from "react";
import { View, StyleSheet, Text } from "react-native";
import { connect } from "react-redux";

// Components
import Header from "./Header";
import IconButton from "../../../components/buttons/IconButton";
import Divider from "../../../components/atoms/Divider";
import Screen from "../../../components/atoms/Screen";

// Constants
import { colors, family, size } from "../../../constants/theme";

// Config
import { version } from "../../../../config";

// Redux
import { logOut } from "../../../redux";

const ProfileHomeScreen = ({ navigation, logOut }) => {
  const buttonHandler = (page) => {
    navigation.navigate("ProfileStack", {
      screen: page,
    });
  };

  const logOutHandler = async () => {
    logOut();
  };

  return (
    <Screen>
      <Header />
      <View style={styles.container}>
        <IconButton
          title="Mi perfil"
          icon="user"
          onPress={() => buttonHandler("ProfileDataScreen")}
        />
        <Divider height={20} />
        <IconButton
          title="Información"
          icon="info"
          onPress={() => buttonHandler("ProfileLegalScreen")}
        />
        <Divider height={20} />
        <IconButton
          title="Cerrar sesión"
          icon="log-out"
          onPress={logOutHandler}
        />
        <Divider height={20} />
      </View>
      <View style={styles.textContainer}>
        <Text style={styles.text}>{version}</Text>
      </View>
    </Screen>
  );
};

const styles = StyleSheet.create({
  container: { flex: 1, padding: 18 },
  textContainer: {
    width: "100%",
    alignItems: "center",
    height: 50
  },
  text: {
    fontFamily: family.normal,
    color: colors.darkGrey,
    fontSize: size.small,
  },
});

const mapDispatchToProps = {
  logOut,
};

export default connect(null, mapDispatchToProps)(ProfileHomeScreen);
