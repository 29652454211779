import React from "react";
import StyleSheet from "react-native-media-query";
import { Image, View } from "react-native";

// Components
import Divider from "../../components/atoms/Divider";

const ProductImage = ({ image, soldOut }) => {
  return (
    <View style={styles.imageContainer}>
      {image ? (
        <Image
          style={styles.image}
          dataSet={{ media: ids.image }}
          source={{ uri: image }}
        />
      ) : soldOut === 1 ? (
        <Divider width={65} />
      ) : (
        <View></View>
      )}
      {soldOut === 1 && (
        <Image
          style={[styles.soldOut, !image && styles.noImage]}
          dataSet={{ media: ids.soldOut }}
          source={require("../../assets/sold_out.png")}
        />
      )}
    </View>
  );
};

export default ProductImage;

const { ids, styles } = StyleSheet.create({
  image: {
    width: 55,
    height: 55,
    borderRadius: 5,
    marginRight: 10,
  },
  imageContainer: {
    position: "relative",
  },
  soldOut: {
    position: "absolute",
    top: 0,
    left: 0,
    width: 55,
    height: 55,
    borderRadius: 5,
    marginRight: 10,
  },
  noImage: {
    position: "absolute",
    top: 0,
    left: 0,
    width: 55,
    height: 55,
    marginRight: 10,
  },
});
