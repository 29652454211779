import React from "react";
import { TouchableOpacity } from "react-native";
import { StyleSheet, Text } from "react-native";

// Constants
import { colors, family } from "../../../../../constants/theme";

const ScheduleButton = ({ title, subtitle, active, onPress }) => {
  const buttonStyle = {
    backgroundColor: active ? colors.secondary : colors.white,
    borderColor: active ? colors.secondary : colors.brown,
  };

  const textStyle = {
    color: active ? colors.white : colors.brown,
  };

  return (
    <TouchableOpacity onPress={onPress} style={[styles.container, buttonStyle]}>
      <Text style={[styles.title, textStyle]}>{title}</Text>
      {subtitle && <Text style={[styles.subtitle, textStyle]}>{subtitle}</Text>}
    </TouchableOpacity>
  );
};

export default ScheduleButton;

const styles = StyleSheet.create({
  container: {
    borderRadius: 25,
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: 8,
    borderWidth: 1,
  },
  title: {
    fontSize: 14,
    fontFamily: family.bold,
    textAlign: "center",
  },
  subtitle: {
    fontSize: 12,
    fontFamily: family.normal,
  },
});
