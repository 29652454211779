import { filterByHavingOrderLines } from "../../../../utils/orderMethods";

export const canASAP = (shops) => {
  let result = false;

  shops.filter(filterByHavingOrderLines).map((shop) => {
    if (shop.orderTypes.includes("ASAP") && shop.opened) {
      result = true;
    }
  });
  
  return result;
};
