import {
  FETCH_LOCATION,
  AUTH_USER,
  LOG_OUT,
  EDIT,
  USER_DELETE,
} from "./userTypes";
import * as Location from "expo-location";

// Constants
import { URLbase, UrlBaseV2 } from "../../constants/urls";

// Config
import { app } from "../../../config";

// Utils
import { getGeoCode } from "../../utils/locationMethods";

// Services
import { commonHeaders } from "../../services/backend";
import { getFCMToken } from "../../services/firebase";

export const fetchLocation = () => {
  return async (dispatch) => {
    try {
      const { coords } = await Location.getCurrentPositionAsync();

      const { latitude = null, longitude = null } = coords;

      getGeoCode(coords).then((res) => {
        dispatch({
          type: FETCH_LOCATION,
          payload: {
            lat: latitude,
            lng: longitude,
            address: res,
          },
        });
      });
    } catch (e) {
      console.log("error fetching location", e.message);
    }
  };
};

export const logOut = () => {
  return (dispatch) => {
    dispatch({
      type: LOG_OUT,
    });
  };
};

export const editUser = ({ token, properties }) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${UrlBaseV2}customer`, {
        headers: commonHeaders(token),
        method: "PUT",
        body: JSON.stringify({
          ...properties,
        }),
      });
      const resData = await response.json();

      if (resData.status === "success") {
        dispatch({
          type: EDIT,
          payload: resData.data,
        });
      }
      return { status: resData.status, data: resData.data };
    } catch (err) {
      console.log("error: ", err);
    }
  };
};

export const deleteCustomer = (token) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${URLbase}/v2/customer`, {
        headers: commonHeaders(token),
        method: "DELETE",
      });
      if (response.status === 204) {
        dispatch({
          type: USER_DELETE,
        });
        return "success";
      } else {
        const resData = await response.json();
        return resData.message;
      }
    } catch (err) {
      console.log("error: ", err);
      return err.message;
    }
  };
};

/////////// Firebase /////////

export const signUpFirebase = (jwt) => {
  return async (dispatch) => {
    let token;
    if (app === "pwa") {
      token = await getFCMToken();
    }

    try {
      const response = await fetch(`${UrlBaseV2}auth/sign-up`, {
        headers: commonHeaders(),
        method: "POST",
        body: JSON.stringify({ firebaseToken: jwt, fcmToken: token }),
      });

      const resData = await response.json();

      if (resData.status === "success") {
        dispatch({
          type: AUTH_USER,
          payload: resData.data,
        });
        return {
          status: resData.status,
        };
      } else {
        return { status: resData.status, data: resData.message };
      }
    } catch (err) {
      console.log("signUpFirebase error: ", err.message);
      return { status: "fail", data: err.message };
    }
  };
};

export const signInFirebase = (jwt) => {
  return async (dispatch) => {
    try {
      let token;
      if (app === "pwa") {
        token = await getFCMToken();
      }

      const response = await fetch(`${UrlBaseV2}customer`, {
        headers: commonHeaders(jwt),
        method: "POST",
        body: JSON.stringify({
          firebaseToken: jwt,
          fcmToken: token,
        }),
      });

      const resData = await response.json();
      if (resData.status === "success") {
        dispatch({
          type: AUTH_USER,
          payload: {
            gid: resData.data.gid,
            name: resData.data.name,
            email: resData.data.email,
          },
        });
        return {
          status: resData.status,
        };
      } else {
        return { status: resData.status, data: resData.message };
      }
    } catch (err) {
      console.log("signInFirebase error: ", err.message);
      return { status: resData.status, data: err.message };
    }
  };
};
