import * as Localization from "expo-localization";
import { UrlBaseV2 } from "../../constants/urls";
import { fetchUrl } from "../calls/fetchUrl";

const timezone = Localization.timezone;

export const fetchPointSales = async (shopId, opened, scheduleable) => {
  const url = scheduleable
    ? `${UrlBaseV2}point-sale?timezone=${timezone}&shopId=${shopId}&sortBy=asc&active=true`
    : `${UrlBaseV2}point-sale?${
        opened ? `opened=${opened}&` : ""
      }timezone=${timezone}&shopId=${shopId}&sortBy=asc&active=true`;
  const response = await fetchUrl(url);

  return response;
};
