import React from "react";
import { StyleSheet, useWindowDimensions, View } from "react-native";

// Components
import Divider from "../../../../atoms/Divider";
import GoogleAuthButton from "../../mediaButtons/GoogleAuthButton";
import IosAuthButton from "../../mediaButtons/IosAuthButton";

const MediaSection = ({ setError, onAuth, sectionProps }) => {
  const width = useWindowDimensions().width;
  return (
    <View style={styles.mediaContainer}>
      <GoogleAuthButton
        onError={setError}
        onAuth={onAuth}
        sectionProps={sectionProps}
      />
      <Divider width={width > 320 ? 20 : 10} />
      <IosAuthButton
        onError={setError}
        onAuth={onAuth}
        sectionProps={sectionProps}
      />
    </View>
  );
};

export default MediaSection;

const styles = StyleSheet.create({
  mediaContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
});
