import React from "react";
import StyleSheet from "react-native-media-query";
import { Text, View } from "react-native";
import { v4 as uuidv4 } from "uuid";

// Constants
import { colors, family } from "../../constants/theme";

// Utils
import { getIngredientsText } from "../../utils/productMethods";

const IngredientsText = ({ ingredients = [], color }) => {
  return ingredients.length > 0 ? (
    <Text dataSet={{ media: ids.text }} style={styles.text}>
      {getIngredientsText(ingredients).map((text) => {
        const key = uuidv4();
        return (
          <Text
            dataSet={{ media: ids.text }}
            key={key}
            style={[
              styles.text,
              {
                color: color,
                fontFamily: text.type === "bold" ? family.bold : family.normal,
              },
            ]}
          >
            {text.content}
          </Text>
        );
      })}
    </Text>
  ) : (
    <View></View>
  );
};

export default IngredientsText;

const { ids, styles } = StyleSheet.create({
  text: {
    fontSize: 12,
    fontFamily: family.normal,
    color: colors.darkGrey,
    marginTop: 5,
  },
});
