import React, { useState, useEffect } from "react";
import { StyleSheet, View } from "react-native";

// Components
import ManagedList from "./components/ManagedList";
import CustomAlert from "../../../components/molecules/CustomAlert";
import Screen from "../../../components/atoms/Screen";
import LoadingScreen from "./components/LoadingScreen";
import MessageScreen from "./components/MessageScreen";
import ServicePicker from "./components/ServicePicker";
import NoLocationScreen from "./components/NoLocationScreen";
import Header from "./components/Header";
import Footer from "./components/Footer";

// Utils
import { getShopAlert } from "../../../utils/orderMethods";
import { getCategoriesArray } from "../../../utils/products/getCategoriesArray";
import { getProductSections } from "../../../utils/products/getProductSections";

const ShopScreen = ({ route, navigation }) => {
  const { data, location, pointSales } = route.params;
  const [status, setStatus] = useState("idle");
  const [modal, setModal] = useState();
  const [modalData, setModalData] = useState();
  const [error, setError] = useState();
  const [productList, setProductList] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [trigger, setTrigger] = useState();

  const productsHandler = (products) => {
    setProductList(getProductSections(products));
    setCategoriesList(getCategoriesArray(products));
  };

  useEffect(() => {
    let alerts = getShopAlert(data, "store");
    if (alerts !== false) {
      setModalData({
        title: alerts.title,
        message: alerts.message,
        action: () => setModal(),
      });
      setModal("Alert");
    }
  }, []);

  const footerHandler = () => {
    let alerts = getShopAlert(data, "finish_store");
    const action = () => {
      setModal();
      navigation.navigate("ConfirmOrderScreen");
    };
    if (alerts !== false) {
      setAlertData({
        title: alerts.title,
        message: alerts.message,
        action: action,
      });
      setModal("Alert");
    } else {
      action();
    }
  };

  const servicesTrigger = () => {
    setTrigger(!trigger);
  };
  
  const shopContent = () => {
    try {
      if (status === "idle") {
        return <NoLocationScreen onPress={servicesTrigger} />;
      }
      if (status === "loading") {
        return <LoadingScreen />;
      }

      if (status === "fail") {
        return <MessageScreen message={error} />;
      }

      if (status === "empty") {
        return (
          <MessageScreen
            message={error}
            buttonText={"Cambia la localización"}
            onPress={servicesTrigger}
          />
        );
      }

      return (
        <ManagedList
          productList={productList}
          catList={categoriesList}
          navigation={navigation}
          shopData={data}
          productsHandler={productsHandler}
          setStatus={setStatus}
          setError={setError}
          pointSales={pointSales}
        />
      );
    } catch (error) {
      setStatus("fail");
      setError(error.message);
    }
  };

  return (
    <Screen paddingHorizontal={0}>
      <View style={styles.container}>
        <Header
          data={data}
          navigation={navigation}
          servicePicker={
            <ServicePicker
              trigger={trigger}
              shopServices={data.services}
              setStatus={setStatus}
              setError={setError}
              productsHandler={productsHandler}
              shop={data}
              initialLocation={location}
              showLocation={false}
              pointSales={pointSales}
            />
          }
        />
        {shopContent()}
      </View>
      <Footer action={footerHandler} />
      <CustomAlert
        setModalVisible={setModal}
        modalVisible={modal === "Alert"}
        alertData={modalData}
      />
    </Screen>
  );
};

export default ShopScreen;

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  serviceWrapper: {
    width: "100%",
    paddingTop: 3,
    justifyContent: "center",
  },
});
