import React from "react";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { StyleSheet, Text, View } from "react-native";

// Components
import Divider from "../../../../../components/atoms/Divider";

// Constants
import { family } from "../../../../../constants/theme";

const Title = ({ customStyle, validValue, discountName }) => {
  const { accentColor, textColor } = customStyle;

  return (
    <View style={styles.container}>
      {validValue ? (
        <View style={styles.validTitle}>
          <Text style={[styles.text, { color: textColor, fontSize: 16 }]}>
            {`Descuento ${discountName}`}
          </Text>
          <Text style={[styles.text, { color: accentColor }]}>Editar</Text>
        </View>
      ) : (
        <>
          <MaterialCommunityIcons
            name="tag-outline"
            size={16}
            color={accentColor}
          />
          <Divider width={10} />
          <Text style={[styles.text, { color: accentColor }]}>
            ¿Tiene un código de descuento?
          </Text>
        </>
      )}
    </View>
  );
};

export default Title;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: 8,
    width: "100%",
  },
  text: {
    fontFamily: family.bold,
    fontSize: 14,
  },
  validTitle: {
    width: "100%",
    justifyContent: "space-between",
    flexDirection: "row",
  },
});
