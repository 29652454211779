import React, { useState, useEffect } from "react";

// Components
import ShopList from "./components/ShopList";
import Screen from "../../../components/atoms/Screen";
import LoadingScreen from "./components/LoadingScreen";
import MessageScreen from "./components/MessageScreen";

// Utils
import { fetchShopsByEvent } from "../../../utils/event/fetchShopsByEvent";

const EventScreen = ({ navigation, route }) => {
  const { eventId, eventData } = route.params;

  const [status, setStatus] = useState("idle");
  const [message, setMessage] = useState();
  const [shopList, setShopList] = useState([]);

  const fetchShops = async () => {
    try {
      setStatus("loading");
      const response = await fetchShopsByEvent(eventId);
      if (response.status === "success") {
        if (response.data.length === 0) {
          setMessage("No se encuentran tiendas para este evento");
          setStatus("fail");
        } else {
          setShopList(response.data);
          setStatus("success");
        }
      } else {
        setMessage("Error al obtener las tiendas del evento");
        setStatus("fail");
      }
    } catch (error) {
      setMessage(error.message);
      setStatus("fail");
    }
  };

  useEffect(() => {
    fetchShops();
  }, []);

  if (status === "loading" || status === "idle") {
    return <LoadingScreen />;
  }

  if (status === "fail") {
    return <MessageScreen message={message} />;
  }

  if (status === "empty") {
    return <MessageScreen message={message} />;
  }

  return (
    <Screen>
      <ShopList data={shopList} navigation={navigation} eventData={eventData} />
    </Screen>
  );
};

export default EventScreen;
