import React from "react";
import { Octicons, Feather } from "@expo/vector-icons";

// Constants
import { colors } from "../../../constants/theme";

const Icon = ({ index, active }) => {
  const color = active ? colors.primaryColor : colors.brown;

  switch (index) {
    case 0:
      return <Octicons name="home" size={30} color={color} />;
    case 1:
      return <Feather name="shopping-bag" size={30} color={color} />;
    case 2:
      return <Octicons name="person" size={30} color={color} />;
  }
};

export default Icon;
