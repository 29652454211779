import React from "react";
import { View, StyleSheet, useWindowDimensions } from "react-native";
import { ScrollView } from "react-native-gesture-handler";

// Components
import Divider from "../../../../components/atoms/Divider";
import LiveItem from "./LiveItem";

const LiveList = ({ navigation, events, status, error }) => {
  const { width } = useWindowDimensions();

  const numOfItems = events.length;
  const itemWidth = width < 370 ? width * 0.8 : 320;
  let scrollWidth = numOfItems * (itemWidth + (numOfItems > 1 ? 28 : 0));

  if (scrollWidth > 1200) {
    scrollWidth = 1200;
  }
  if (scrollWidth > width) {
    scrollWidth = width;
  }

  if (status === "fail") {
    <View style={styles.container}>
      <Text style={styles.error}>{error}</Text>
    </View>;
  }

  if (status === "empty" || events.length === 0) {
    return <View></View>;
  }
  return (
    <View style={styles.scrollContainer}>
      <View style={styles.scrollContent}>
        <ScrollView
          style={[styles.scroll, { width: scrollWidth }]}
          horizontal
          showsHorizontalScrollIndicator={false}
        >
          {numOfItems > 1 && <Divider width={18} />}
          {events.map((event) => (
            <>
              <LiveItem data={event} navigation={navigation} />
              {numOfItems > 1 && <Divider width={10} />}
            </>
          ))}
        </ScrollView>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  scroll: {
    height: 480,
  },
  scrollContainer: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  scrollContent: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    maxWidth: 1200,
  },
});

export default LiveList;
