import React, { useState } from "react";
import { View, StyleSheet } from "react-native";
import { connect } from "react-redux";

// Components
import WarningModal from "../../../../../components/organisms/modals/WarningModal";
import Divider from "../../../../../components/atoms/Divider";
import Wrapper from "./Wrapper";
import Title from "./Title";
import ScheduleTag from "./ScheduleTag";
import PrecomprasTag from "./PrecomprasTag";
import TimeLine from "./TimeLine";
import Description from "./Description";
import ClosedTag from "./ClosedTag";
import WaitTimeTag from "./WaitTimeTag";

// Redux
import { addShop } from "../../../../../redux";

// Utils
import { navigateToShop } from "../../../../../utils/redirection/navigateToShop";
import { isShopScheduleable } from "../../../../../utils/shop/isShopScheduleable";
import { isShopOpened } from "../../../../../utils/shop/isShopOpened";

const ShopItem = ({ data, navigation, addShop }) => {
  const { image, name, gid, description, schedules, waitTime, services } = data;
  const [modal, setModal] = useState();
  const [modalText, setModalText] = useState();

  const isPrecompras = services.some((service) => service.service_id === 4);
  const scheduleable = isShopScheduleable(data);
  const shopOpened = isShopOpened(data);

  const errorHandler = (text) => {
    setModalText(text);
    setModal("Warning");
  };

  const buttonHandler = () => {
    navigateToShop({
      gid: gid,
      onError: errorHandler,
      navigation,
      addShop,
    });
  };

  return (
    <Wrapper opened={shopOpened} image={image} onPress={buttonHandler}>
      <View style={styles.content}>
        <View style={styles.tagContainer}>
          {!shopOpened && (
            <>
              <ClosedTag />
              <Divider width={5} />
            </>
          )}
          {isPrecompras && (
            <>
              <PrecomprasTag />
              <Divider width={5} />
            </>
          )}
          {scheduleable && shopOpened && (
            <>
              <ScheduleTag />
              <Divider width={5} />
            </>
          )}
          {waitTime && (
            <>
              <WaitTimeTag waitTime={waitTime} />
            </>
          )}
        </View>
        <View style={styles.infoContainer}>
          <Title title={name} />
          <Divider height={5} />
          {description && <Description description={description} />}
          {description && <Divider height={5} />}
          {!isPrecompras && <TimeLine schedules={schedules} />}
        </View>
      </View>
      <WarningModal
        setModal={setModal}
        visible={modal === "Warning"}
        text={modalText}
      />
    </Wrapper>
  );
};

const styles = StyleSheet.create({
  content: {
    width: "100%",
    height: "100%",
    paddingHorizontal: 18,
    paddingVertical: 12,
    justifyContent: "space-between",
  },
  infoContainer: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
  tagContainer: {
    flexDirection: "row",
    alignItems: "flex-start",
    display: "flex",
    flexWrap: "wrap",
  },
});

const mapDispatchToProps = {
  addShop,
};

export default connect(null, mapDispatchToProps)(ShopItem);
