import React from "react";
import { View, Platform } from "react-native";
import StyleSheet from "react-native-media-query";

// Constants
import { colors } from "../../constants/theme";

const Container = ({ children, ...props }) => (
  <View style={styles.container} {...props}>
    <View style={styles.content} dataSet={{ media: ids.content }}>
      {children}
    </View>
  </View>
);

export default Container;

const { ids, styles } = StyleSheet.create({
  container: {
    width: "100%",
    backgroundColor: colors.white,
    zIndex: 10,
    height: Platform.select({
      ios: 90,
      web: 60,
      android: 60,
    }),
    paddingTop: Platform.select({
      ios: 40,
    }),
    paddingHorizontal: 18,
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    width: "100%",
    maxWidth: 400,
    justifyContent: "center",
    alignItems: "flex-start",
    height: "100%",
    "@media (min-width: 400px)": {
      paddingHorizontal: 18,
    },
  },
});
