import { UrlBaseV2 } from "../../../../constants/urls";
import { commonHeaders } from "../../../../services/backend";

export const reactivateAccount = async (email) => {
  try {
    const response = await fetch(`${UrlBaseV2}customer/enable-email`, {
      headers: commonHeaders(),
      method: "POST",
      body: JSON.stringify({
        email: email,
      }),
    });

    const resData = await response.json();

    return {
      status: resData.status,
      data: resData.data,
      message: resData.message,
    };
  } catch (err) {
    console.log("reactivateAccount error: ", err);
    return { status: "fail", data: "Algo salió mal" };
  }
};