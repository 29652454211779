import React, { useEffect, useState, useRef, useContext } from "react";
import { ActivityIndicator, StyleSheet, View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { Platform } from "react-native";
import { connect } from "react-redux";
import AdyenCheckout from "@adyen/adyen-web";
import "@adyen/adyen-web/dist/adyen.css";

// Redux
import { resetCart } from "../../redux";

// Adyen functions & config
import getAdyenSession from "../functions/getAdyenSession";
import getFinalOrder from "../functions/getFinalOrder";
import { paymentConfig } from "../paymentConfig";
import { disableMethod } from "../functions/disableMethod";

// Constants
import { colors } from "../../constants/theme";

// Others
import { environment } from "../../../config";

import { AuthContext } from "../../contexts/AuthContext";

const PaymentContainerWeb = ({
  cart,
  user,
  modalHandler,
  navigation,
  resetCart,
  setWarningText,
  visible,
}) => {
  const { user: firebaseUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const paymentContainer = useRef(null);
  const initiateCheckout = async (amount) => {
    try {
      setLoading(true);
      const token = await firebaseUser.getIdToken(true);
      const order = await getFinalOrder({ cart: cart, user: user });
      const response = await getAdyenSession(token, {
        ...order,
        channel: Platform.OS,
        shoppperEmail: user?.email,
        returnUrl:
          environment === "dev"
            ? "https://userdev.gopick-app.com"
            : "https://user.gopick-app.com",
      });

      if (response?.status === "success") {
        if (response.data?.resultCode === "Authorised") {
          resetCart();
          navigation.navigate("PaymentSuccess");
        } else {
          const session = response.data?.session;
          const { config } = paymentConfig(amount, session);
          const configToSend = {
            ...config,
            session: session,
            clientKey: config.clientKey,
            onPaymentCompleted: (response, _component) => {
              if (response?.resultCode === "Authorised") {
                modalHandler();
                resetCart();
                navigation.navigate("PaymentSuccess");
              }
              if (response?.resultCode === "Refused") {
                modalHandler();
                setWarningText(
                  "Pago rechazado, comprueba que los datos introducidos son correctos"
                );
              }
            },
            onError: (error, _component) => {
              if (!error?.message?.includes("AbortError")) {
                modalHandler();
                navigation.navigate("PaymentError", {
                  from: "payment",
                  errorData: error.message,
                });
              }
            },
            onDisable: () => {
              modalHandler();
            },
          };
          const createCheckout = async () => {
            const checkout = await AdyenCheckout({
              ...configToSend,
            });
            if (paymentContainer.current) {
              checkout
                .create("dropin", {
                  showPaymentMethods: true,
                  showStoredPaymentMethods: true,
                  showRemovePaymentMethodButton: true,
                  instantPaymentTypes: ["applepay", "googlepay"],
                  onDisableStoredPaymentMethod: async (
                    storedPaymentMethodId,
                    resolve,
                    reject
                  ) => {
                    const response = await disableMethod(
                      session?.shopperReference,
                      storedPaymentMethodId
                    );
                    if (response === "success") {
                      resolve();
                    } else {
                      reject();
                    }
                  },
                })
                .mount(paymentContainer.current);
            }
          };
          createCheckout();
        }
      } else {
        setWarningText(response?.message);
        modalHandler();
      }
    } catch (error) {
      setWarningText(error.message);
      modalHandler();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (visible) initiateCheckout(cart.totalToPay);
  }, [visible, cart.totalToPay]);

  return (
    <ScrollView style={{ width: "100%", height: "auto", maxHeight: 400 }}>
      <div id="payment-page">
        <div className="container">
          <div className="payment-container">
            {loading ? (
              <View style={styles.loading}>
                <ActivityIndicator color={colors.primaryColor} size={"small"} />
              </View>
            ) : (
              <div ref={paymentContainer} className="payment"></div>
            )}
          </div>
        </div>
      </div>
    </ScrollView>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
    cart: state.cart,
  };
};

const mapDispatchToProps = {
  resetCart,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentContainerWeb);

const styles = StyleSheet.create({
  loading: {
    height: 220,
    justifyContent: "center",
    alignItems: "center",
  },
});
