import React from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";

// Components
import Divider from "../../../../components/atoms/Divider";
import ShopServiceLine from "./ShopServiceLine";
import ProductItem from "./ProductItem";
import Title from "./Title";

const ProductsSection = ({ orders }) => {
  return (
    <View style={styles.container}>
      {orders.map(
        (shop) =>
          shop.orderLines.length > 0 && (
            <>
              <View style={styles.shopContainer}>
                <View style={styles.titleContainer}>
                  <Title shop={shop} />
                  <ShopServiceLine shop={shop}/>
                </View>
                <Divider height={10} />
                {shop.orderLines.map((line) => (
                  <View style={{ flexDirection: "column" }} key={line.gid}>
                    <ProductItem data={line} shop={shop} />
                    <Divider height={10} />
                  </View>
                ))}
              </View>
              <Divider height={20} />
            </>
          )
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    paddingVertical: 12,
    paddingHorizontal: 18,
    flexDirection: "column",
  },
  shopContainer: {
    width: "100%",
  },
  titleContainer: {
    width: "100%",
  },
});

const mapStateToProps = (state) => {
  return {
    orders: state.laterOrders.orders,
  };
};

export default connect(mapStateToProps)(ProductsSection);
