import React, { useEffect, useState, useContext } from "react";
import {
  StyleSheet,
  View,
  Platform,
  useWindowDimensions,
  ScrollView,
} from "react-native";
import { connect } from "react-redux";

// Components
import Divider from "../../../components/atoms/Divider";
import Screen from "../../../components/atoms/Screen";
import DetailsSection from "./DetailsSection";
import Header from "./Header";
import InfoSection from "./InfoSection";
import LocationLine from "./LocationLine";
import QrSection from "./QrSection";
import ResumeSection from "./ResumeSection";
import TitleRow from "./TitleRow";
import LoadingScreen from "./LoadingScreen";
import ErrorScreen from "./ErrorScreen";
import FinishButton from "./FinishButton";

// Constants
import { family } from "../../../constants/theme";

// Context
import { AuthContext } from "../../../contexts/AuthContext";

// Methods
import { fetchOrderDetail } from "./methods/fetchOrderDetail";

// Services
import { initiateSocket, ordersUpdate } from "../../../services/orders";

const PastOrderDetailScreen = ({ route, navigation }) => {
  const { user: firebaseUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [orderData, setOrderData] = useState(null);

  const [orderStatus, setOrderStatus] = useState(null);

  const { shop, gid } = route.params;
  const height = useWindowDimensions().height;
  const showFinishButton =
    orderStatus === "shipped" || orderStatus === "await_pickup";

  const getData = async () => {
    setLoading(true);
    const token = await firebaseUser.getIdToken(true);
    const response = await fetchOrderDetail(gid, token);
    if (response?.status === "fail") {
      setError(response?.message);
    } else {
      setOrderData(response?.data);
      setOrderStatus(response.data.status.key);
    }
    setLoading(false);
  };

  const updateOrder = (result) => {
    if (result.status === "success") {
      if (result.data?.gid === gid) {
        setOrderStatus(result.data.status);
      }
    }
  };

  async function initiateSocketWithToken() {
    if (firebaseUser) {
      const token = await firebaseUser.getIdToken(true);
      initiateSocket(token, "orders").then(() => ordersUpdate(updateOrder));
    }
  }

  useEffect(() => {
    getData();
    initiateSocketWithToken();
  }, []);

  if (loading) {
    return (
      <LoadingScreen
        navigation={navigation}
        image={shop?.image}
        name={shop?.name}
        loading={loading}
      />
    );
  }

  if (error) {
    return (
      <ErrorScreen
        navigation={navigation}
        image={shop?.image}
        name={shop?.name}
        error={error}
      />
    );
  }

  return (
    <Screen hasFooter header={"hidden"} hideActions>
      <View style={styles.container}>
        <Header
          image={shop?.image}
          navigation={navigation}
          status={orderStatus}
          name={shop?.name}
          gid={gid}
        />
        <View style={Platform.OS === "web" ? { height: height } : {}}>
          <ScrollView
            style={styles.scroll}
            showsVerticalScrollIndicator={false}
          >
            <TitleRow
              name={"Tu pedido"}
              orderNumber={orderData?.orderNumber}
              openTime={null}
            />
            <Divider height={20} />
            <DetailsSection data={orderData} />
            <Divider height={20} />
            <LocationLine location={shop?.pickUpPoint} />
            <Divider height={10} />
            <QrSection gid={gid} />
            <Divider height={20} />
            <InfoSection
              data={orderData}
              gid={gid}
            />
            <Divider height={30} />
            <ResumeSection data={orderData} />
            <Divider height={300} />
          </ScrollView>
        </View>
        {showFinishButton && <FinishButton id={gid} onSuccess={getData} />}
      </View>
    </Screen>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(PastOrderDetailScreen);

const styles = StyleSheet.create({
  container: {
    position: "relative",
    flex: 1,
  },
  scroll: {
    padding: 18,
  },
  error: {
    fontFamily: family.normal,
    fontSize: 14,
    textAlign: "center",
  },
});
