import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";

// Components
import CustomButton from "../../../../components/atoms/CustomButton";
import WarningModal from "../../../../components/organisms/modals/WarningModal";

const ConfirmButton = ({ navigation, orders, services }) => {
  const [modal, setModal] = useState();

  const orderHaveService = () => {
    let result = false;
    services.map((service) => {
      if (service.shopId === orders[0].shopId) {
        result = true;
      }
    });
    return result;
  };
  const buttonHandler = () => {
    if (orderHaveService()) {
      navigation.navigate("CheckoutLaterProducts");
    } else {
      setModal("Warning");
    }
  };

  return (
    <View style={styles.container}>
      <CustomButton
        onPress={buttonHandler}
        title="Confirmar"
        active={orders?.length > 0}
      />
      <WarningModal
        visible={modal === "Warning"}
        setModal={setModal}
        text={`Debes elegir el tipo de entrega para la tienda seleccionada`}
      />
    </View>
  );
};

const mapStateToProps = (state) => {
  return {
    orders: state.laterOrders.orders,
    services: state.laterOrders.services,
  };
};

export default connect(mapStateToProps)(ConfirmButton);

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: 60,
    paddingHorizontal: 30,
  },
});
