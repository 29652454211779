import { getLocalData, storeLocalData } from "../miscelanusUtils";

export const navigateToPayment = async (navigation) => {
  const paymentReturn = await getLocalData("paymentReturn");
  if (paymentReturn === "checkout") {
    const paymentSessionId = await getLocalData("paymentSessionId");
    const paymentRedirectResult = await getLocalData("paymentRedirectResult");
    navigation.navigate("PaymentResult", {
      sessionId: paymentSessionId,
      redirectResult: paymentRedirectResult,
    });
  }
  if (paymentReturn === "success") {
    navigation.navigate("PaymentSuccess");
  }
  if (paymentReturn === "error") {
    navigation.navigate("PaymentError", {
      from: "events",
      errorData: "",
    });
  }
  storeLocalData("paymentSessionId", null);
  storeLocalData("paymentReturn", false);
};
