import React, { useState, useEffect } from "react";
import { StyleSheet, View, TouchableOpacity, Text } from "react-native";
import { connect } from "react-redux";

// Components
import Divider from "../../../../../components/atoms/Divider";
import ServiceItem from "./ServiceItem";
import LocationsModal from "../LocationsModal";

// Contants
import { colors, family } from "../../../../../constants/theme";

// Redux
import { resetShopOrders, setLocation, setService } from "../../../../../redux";

// Utils
import { fetchProducts } from "../../../../../utils/products/fetchProducts";

const ServicePicker = (props) => {
  const {
    shopServices,
    setService,
    pointSales,
    setError,
    setLocation,
    productsHandler,
    shop,
    cartLocation,
    cartServices,
    setStatus,
    initialLocation,
    resetShopOrders,
    trigger,
    showLocation = true,
  } = props;
  const [modal, setModal] = useState();
  const [modalService, setModalService] = useState();
  const [selectedService, setSelectedService] = useState(
    cartServices.filter((service) => service.shopId === shop.gid)[0]
  );
  const laterShop =
    shopServices.filter((service) => service.service_id === 4).length > 0;

  const serviceHandler = async ({ service, locationText, locationGid }) => {
    if (
      selectedService?.service_id !== service?.service_id ||
      locationGid !== cartLocation.gid_location
    ) {
      resetShopOrders(shop.gid);
    }
    setStatus("loading");
    setLocation(locationText, locationGid);
    setService({
      service: service,
      shop: shop,
      location: locationText,
      gid_location: locationGid,
    });
    const response = await fetchProducts({
      shopId: shop.gid,
      locId: locationGid,
      pointSales,
    });
    const { data, status, message } = response;
    if (status === "success") {
      if (data.length === 0) {
        setStatus("empty");
        setError(`No hay productos disponibles.`);
      } else {
        productsHandler(data);
        setStatus("success");
      }
    } else {
      setStatus("fail");
      setError(message);
    }
  };

  useEffect(() => {
    setSelectedService(
      cartServices.filter((service) => service.shopId === shop.gid)[0]
    );
  }, [cartServices]);

  const locationsHandler = async (service) => {
    let simpleService;
    let locationsService;
    let laterService;
    if (service) {
      if (service.service_id === 4) {
        laterService = service;
      } else if (service.levels_locations === "not_show") {
        simpleService = service;
      } else {
        locationsService = service;
      }
    } else {
      laterService = shopServices.find((service) => service.service_id === 4);
      simpleService = shopServices.find(
        (service) => service.levels_locations === "not_show"
      );
      locationsService = shopServices.find(
        (service) => service.levels_locations !== "not_show"
      );
    }

    if (laterService) {
      serviceHandler({ service: laterService });
    } else if (simpleService) {
      serviceHandler({ service: simpleService });
    } else if (locationsService) {
      setModalService(locationsService);
      console.log("EHE");
      setModal("Locations");
    }
  };


  useEffect(
    (prevState) => {
      fetchData();
    },
    [trigger]
  );

  const fetchData = async () => {
    if (!selectedService) {
      if (initialLocation?.id) {
        const { id, name } = initialLocation;
        serviceHandler({
          service: { key: "my_site" },
          locationGid: id,
          locationText: name,
        });
      } else {
        locationsHandler();
      }
    } else {
      locationsHandler(selectedService);
    }
  };

  // const onDismissLocations = () => {
  //   navigation.goBack();
  // };

  useEffect(() => {
    fetchData();
  }, []);

  if (laterShop) {
    return (
      <Text style={[styles.serviceTitle, { fontFamily: family.bold }]}>
        Preventa
      </Text>
    );
  }

  if (shopServices.length === 1) {
    return (
      <>
        <Text style={styles.serviceTitle}>
          Servicio: {shopServices[0].label}
        </Text>
        <LocationsModal
          visible={modal === "Locations"}
          setModal={setModal}
          service={modalService}
          shop={shop}
          canClose={false}
          // onDismiss={onDismissLocations}
          onFinish={serviceHandler}
        />
      </>
    );
  }

  return (
    <View style={styles.container}>
      <View style={styles.picker}>
        {shopServices
          .filter((service) => service.service_id !== 4)
          .map((service) => (
            <ServiceItem
              key={service.service_id}
              service={service}
              selected={service.key === selectedService?.key}
              onPress={() => locationsHandler(service)}
            />
          ))}
        <LocationsModal
          visible={modal === "Locations"}
          setModal={setModal}
          service={modalService}
          shop={shop}
          canClose={false}
          // onDismiss={onDismissLocations}
          onFinish={serviceHandler}
        />
      </View>
      {cartLocation?.location && showLocation && (
        <View style={styles.locationContainer}>
          <Divider height={10} />
          <TouchableOpacity
            onPress={() => {
              // setModal("Locations");
            }}
          >
            <Text style={styles.location}>{cartLocation.location}</Text>
          </TouchableOpacity>
        </View>
      )}
    </View>
  );
};
const mapStateToProps = (state) => {
  return {
    cartServices: state.cart.services,
    cartLocation: state.cart.location,
  };
};

const mapDispatchToProps = {
  setService,
  resetShopOrders,
  setLocation,
};

export default connect(mapStateToProps, mapDispatchToProps)(ServicePicker);

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
  },
  picker: {
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: 100,
    borderColor: colors.darkGrey,
    borderWidth: 1,
    flexDirection: "row",
    height: 35,
    backgroundColor: colors.white,
  },
  serviceTitle: {
    fontFamily: family.normal,
    fontSize: 14,
    color: colors.white,
    textAlign: "center",
  },
  location: {
    fontFamily: family.bold,
    fontSize: 14,
    color: colors.secondary,
    textAlign: "center",
  },
  locationContainer: {
    height: 30,
  },
});
