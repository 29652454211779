export const config = {
  screens: {
    Home: {
      path: "..",
    },
    EventsStack: {
      screens: {
        HomeScreen: {
          path: "/",
        },
        EventScreen: {
          path: "/event/:eventId",
          parse: {
            eventId: (eventId) => `${eventId}`,
          },
        },
        EventListScreen: {
          path: "/places",
        },
        ShopScreen: {
          path: "/shop/:shopId",
          parse: {
            shopId: (shopId) => `${shopId}`,
          },
        },
        MealVariablesScreen: {
          path: "/variants",
        },
        ConfirmOrderScreen: {
          path: "/confirm",
        },
        PaymentSuccess: {
          path: "/paymentSuccess",
        },
        PaymentError: {
          path: "/paymentError",
        },
        PaymentResult: {
          path: "/paymentResult",
        },
        MenuSelectorScreen: {
          path: "/menu",
        },
      },
    },
    ProfileStack: {
      screens: {},
    },

    OrdersStack: {
      screens: {
        MyProductsScreen: {
          path: "/misProductos",
        },
        CheckoutLaterProducts: {
          path: "/laterCheckout",
        },
        OrdersHomeScreen: {
          path: "/orders",
        },
        PastOrderDetailScreen: {
          path: "/orders/detail",
        },
      },
    },
    Tickets: {
      path: "/tickets/:shopId",
      parse: {
        shopId: (shopId) => `${shopId}`,
      },
    },
    TicketDetailScreen: {
      path: "/ticket_details/:ticketId",
      parse: {
        ticketId: (ticketId) => `${ticketId}`,
      },
    },
    TicketCheckoutScreen: {
      path: "/ticket_checkout",
    },
    TicketLastStepScreen: {
      path: "/ticket_purchase/:token",
      parse: () => "ticket_purchase",
    },
    TicketTermsScreen: {
      path: "/terms",
    },
    TicketPrivacyScreen: {
      path: "/privacy",
    },
    TicketsPaymentSuccess: {
      path: "/success",
    },
    TicketsPaymentError: {
      path: "/error",
    },
    NotFound: "*",
  },
};

export const prefixes = [
  "localhost:19006",
  "https://tickets.gopick-app.com",
  "https://tickets-dev.gopick-app.com",
  "https://userdev.gopick-app.com",
  "https://user.gopick-app.com",
];
