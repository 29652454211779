import {
  FETCH_TEXTUAL_PAGES,
  FETCH_TEXTUAL_PRIVACY,
  FETCH_TEXTUAL_COOKIES,
  FETCH_TEXTUAL_LEGAL,
} from "./configTypes";

import { URlTextualBase } from "../../constants/urls";
import { baseVersion } from "../../../config";

export const fetchTextualPages = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${URlTextualBase}/get-all`, {
        headers: {
          "Content-Type": "application/json",
          Connection: "keep-alive",
          mode: "no-cors",
          crossorigin: "anonymous",
          "X-Client-Version": baseVersion,
        },
        method: "GET",
      });

      const resData = await response.json();
      dispatch({
        type: FETCH_TEXTUAL_PAGES,
        payload: resData,
      });
    } catch (err) {
      console.log("error fetching all textual pages: ", err);
    }
  };
};

export const fetchTextualPrivacy = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${URlTextualBase}/get/general/privacy`, {
        headers: {
          "Content-Type": "application/json",
          Connection: "keep-alive",
          "X-Client-Version": baseVersion,
        },
        method: "GET",
      });

      const resData = await response.json();

      dispatch({
        type: FETCH_TEXTUAL_PRIVACY,
        payload: resData,
      });
    } catch (err) {
      console.log("error fetching textual privacy: ", err);
    }
  };
};

export const fetchTextualLegal = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${URlTextualBase}/get/general/legal`, {
        headers: {
          "Content-Type": "application/json",
          Connection: "keep-alive",
          "X-Client-Version": baseVersion,
        },
        method: "GET",
      });

      const resData = await response.json();
      dispatch({
        type: FETCH_TEXTUAL_LEGAL,
        payload: resData,
      });
    } catch (err) {
      console.log("error fetching textual legal: ", err);
    }
  };
};

export const fetchTextualTerms = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${URlTextualBase}/get/general/term_sales`, {
        headers: {
          "Content-Type": "application/json",
          Connection: "keep-alive",
          "X-Client-Version": baseVersion,
        },
        method: "GET",
      });

      const resData = await response.json();
      
      dispatch({
        type: FETCH_TEXTUAL_COOKIES,
        payload: resData,
      });
    } catch (err) {
      console.log("error fetching textual cookies: ", err);
    }
  };
};
