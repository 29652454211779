import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

// Components
import LegalContainer from "./LegalContainer";

// Redux
import { fetchTextualTerms } from "../../../redux/config/configActions";

const TermsText = ({ cookies, setStep, fetchTextualTerms }) => {
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    setLoading(true);
    await fetchTextualTerms();
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return ( 
    <LegalContainer
      text={cookies}
      loading={loading}
      setStep={setStep}
    />
  );
};

const mapStateToProps = (state) => {
  return { cookies: state.config.textualPages.cookies };
};

const mapDispatchToProps = {
  fetchTextualTerms,
};

export default connect(mapStateToProps, mapDispatchToProps)(TermsText);