import React from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  useWindowDimensions,
  Platform,
} from "react-native";

// Components
import BottomSheet from "../../../../components/atoms/BottomSheet";
import Divider from "../../../../components/atoms/Divider";
import EmptyForLater from "./EmptyForLater";
import ForLaterItem from "./ForLaterItem";

// Constants
import { colors, family } from "../../../../constants/theme";

const LaterOrdersList = ({ data = [], navigation }) => {
  const height = useWindowDimensions().height;

  const openPos = -0.8 * height - 40;
  const closePos = Platform.OS === "web" ? -217 : -0.5 * height + 190;
  const startPos = Platform.OS === "web" ? -380 : -0.5 * height + 30;

  const title = "Mi despensa";
  const text =
    "Estos productos ya están pagados. Pídelos cuando quieras consumirlos.";

  return (
    <BottomSheet
      openPosition={openPos}
      closePosition={closePos}
      startPosition={startPos}
    >
      {data.length === 0 ? (
        <EmptyForLater />
      ) : (
        <ScrollView
          style={{ height: data.length * 70 }}
          showsVerticalScrollIndicator={false}
        >
          <View style={styles.titleContainer}>
            <Text style={styles.title}>{title}</Text>
          </View>
          <View style={styles.subtitleContainer}>
            <Text style={styles.text}>{text}</Text>
          </View>
          {data.map((item) => (
            <ForLaterItem key={item.gid} data={item} navigation={navigation} />
          ))}
          <Divider height={100} />
        </ScrollView>
      )}
    </BottomSheet>
  );
};

export default LaterOrdersList;

const styles = StyleSheet.create({
  titleContainer: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginBottom: 10,
  },
  title: {
    fontFamily: family.bold,
    color: colors.brown,
    fontSize: 20,
  },
  subtitleContainer: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginBottom: 5,
  },
  text: {
    fontFamily: family.normal,
    color: colors.darkGrey,
    fontSize: 16,
    textAlign: "center",
  },
});
