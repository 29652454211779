export const getLaterOrder = (cart) => {
  let auxOrders = [];

  cart.orders.forEach((shop) => {
    const service = cart.services.filter(
      (ser) => ser.shopId === shop.shopId
    )[0];
    shop.orderLines.forEach((orderLine) => {
      if (
        auxOrders.filter((od) => od.parentOrderId === orderLine.parentOrder)
          .length === 0
      ) {
        let aux = {
          shopId: shop.shopId,
          orderType: cart.orderType,
          serviceTypeId: service.service_id,
          parentOrderId: orderLine.parentOrder,
          orderLines: [
            {
              pointSaleId: orderLine.pointSaleId,
              productId: orderLine.productId,
              quantity: orderLine.quantity,
            },
          ],
        };

        if (service.location !== null) {
          aux.location = service.location;
        }
        if (service.locationId !== null) {
          aux.locationId = parseInt(service.locationId);
        }

        if (cart.date !== null) {
          const [hour, minutes] = cart.date.split(":");
          const today = new Date();
          const dateWithTime = new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate(),
            hour,
            minutes
          );
          aux.date = dateWithTime;
        }
        auxOrders.push(aux);
      } else {
        auxOrders
          .filter((od) => od.parentOrderId === orderLine.parentOrder)[0]
          .orderLines.push({
            pointSaleId: orderLine.pointSaleId,
            productId: orderLine.productId,
            quantity: orderLine.quantity,
          });
      }
    });
  });

  const object = {
    eventId: cart.eventId,
    orders: auxOrders,
    paymentMethodId: 1,
  };
  if (cart.comment !== "") {
    object.comment = cart.comment;
  }

  return object;
};
