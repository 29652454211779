import { UrlBaseV2 } from "../../constants/urls";
import * as Localization from "expo-localization";
import { fetchUrl } from "../calls/fetchUrl";

const timezone = Localization.timezone;

export const fetchShopsByEvent = async (gid) => {
  const response = await fetchUrl(
    `${UrlBaseV2}shop?timezone=${timezone}&eventId=${gid}&sortBy=asc&active=true&hidden=false`
  );
  return response
};
