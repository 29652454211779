import {
  ADD_SHOP,
  ADD_SIMPLE_PRODUCT,
  ADD_PRODUCT,
  RESET_SHOP_ORDERS,
  RESET_CART,
  ADD_FORMED_MENU,
  REMOVE_FORMED_MENU,
  RESET_FORMED_MENU,
  SET_SERVICE,
  SET_DISCOUNT,
  SET_LOCATION,
  SET_DATE,
  SET_COMMENT,
  ADD_MENU_VARIANTS,
  PURGUE_CART,
  SET_PAYMENT_METHOD,
  INCREMENT_PRODUCT,
  DECREMENT_PRODUCT,
  SET_CARD,
  SET_TOTAL_TO_PAY,
  SET_ORDER_TYPE,
  DECREMENT_MENU_PRODUCT,
  INCREMENT_MENU_PRODUCT,
} from "./cartTypes";

export const addSimpleProduct = (data) => {
  return (dispatch) => {
    dispatch({
      type: ADD_SIMPLE_PRODUCT,
      payload: data,
    });
  };
};

export const addShop = (shop, orderType) => {
  return (dispatch) => {
    dispatch({
      type: ADD_SHOP,
      payload: {
        shop: shop,
        orderType: orderType,
      },
    });
  };
};

export const addProduct = ({
  shop,
  gid,
  only_legal_age,
  not_pay_service,
  not_pay_commission,
  fk_point_sale,
  increment,
  type,
  name,
  menuData,
  quantity,
  amount,
  variants,
  ingredients,
  exclusive_order,
  stock,
  family,
}) => {
  return (dispatch) => {
    dispatch({
      type: ADD_PRODUCT,
      payload: {
        shop: shop,
        quantity: quantity,
        only_legal_age: only_legal_age,
        not_pay_service: not_pay_service,
        not_pay_commission: not_pay_commission,
        increment: increment,
        id_point_sale: fk_point_sale,
        type: type,
        exclusive_order: exclusive_order,
        menuData: menuData,
        family: family,
        stock:stock,
        gid: gid,
        amount: amount,
        name: name,
        variants: variants,
        ingredients: ingredients,
      },
    });
  };
};

export const incrementProduct = (internal_id) => {
  return (dispatch) => {
    dispatch({
      type: INCREMENT_PRODUCT,
      payload: internal_id,
    });
  };
};

export const decrementProduct = (internal_id) => {
  return (dispatch) => {
    dispatch({
      type: DECREMENT_PRODUCT,
      payload: internal_id,
    });
  };
};

export const addFormedMenu = (shopId, menu) => {
  return (dispatch) => {
    dispatch({
      type: ADD_FORMED_MENU,
      payload: {
        shopId: shopId,
        menu: menu,
      },
    });
  };
};
export const resetFormedMenu = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_FORMED_MENU,
    });
  };
};

export const setMenuVariants = (variants, gid) => {
  return (dispatch) => {
    dispatch({
      type: ADD_MENU_VARIANTS,
      payload: { variants: variants, gid: gid },
    });
  };
};
export const removeFormedMenu = (shop, menu) => {
  return (dispatch) => {
    dispatch({
      type: REMOVE_FORMED_MENU,
      payload: {
        shopId: shop.gid,
        menu: menu,
      },
    });
  };
};

export const resetCart = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_CART,
    });
  };
};

export const purgueCart = () => {
  return (dispatch) => {
    dispatch({
      type: PURGUE_CART,
    });
  };
};

export const setService = ({
  service: service,
  shop: shop,
  location: location,
  gid_location: gid_location,
}) => {
  return (dispatch) => {
    dispatch({
      type: SET_SERVICE,
      payload: {
        service: service,
        shop: shop,
        location: location,
        gid_location: gid_location,
      },
    });
  };
};

export const setDiscount = ({ discount, discountLines = [] }) => {
  return (dispatch) => {
    dispatch({
      type: SET_DISCOUNT,
      payload: { discount: discount, discountLines: discountLines },
    });
  };
};

export const setLocation = (location, gid) => {
  return (dispatch) => {
    dispatch({
      type: SET_LOCATION,
      payload: { location: location, gid_location: gid },
    });
  };
};

export const setDate = (date, shopId) => {
  return (dispatch) => {
    dispatch({
      type: SET_DATE,
      payload: {
        date,
        shopId,
      },
    });
  };
};

export const setComment = (comment) => {
  return (dispatch) => {
    dispatch({
      type: SET_COMMENT,
      payload: comment,
    });
  };
};

export const setOrderType = (type) => {
  return (dispatch) => {
    dispatch({
      type: SET_ORDER_TYPE,
      payload: type,
    });
  };
};

export const setCard = (card) => {
  return (dispatch) => {
    dispatch({
      type: SET_CARD,
      payload: card,
    });
  };
};


export const resetShopOrders = (shopId) => {
  return (dispatch) => {
    dispatch({
      type: RESET_SHOP_ORDERS,
      payload: shopId,
    });
  };
};

export const setTotalToPay = (amount) => {
  return (dispatch) => {
    dispatch({
      type: SET_TOTAL_TO_PAY,
      payload: amount,
    });
  };
};

export const setPaymentMethod = (paymentMethod) => {
  return (dispatch) => {
    dispatch({
      type: SET_PAYMENT_METHOD,
      payload: {
        paymentMethod,
      },
    });
  };
};

export const decrementMenuProduct = (id) => {
  return (dispatch) => {
    dispatch({
      type: DECREMENT_MENU_PRODUCT,
      payload: id,
    });
  };
};

export const incrementMenuProduct = (id) => {
  return (dispatch) => {
    dispatch({
      type: INCREMENT_MENU_PRODUCT,
      payload: id,
    });
  };
};
