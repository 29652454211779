import React, { useEffect } from "react";
import { StyleSheet, View, Text, ActivityIndicator } from "react-native";
import { connect } from "react-redux";

// Components
import Divider from "../../../components/atoms/Divider";

// Constants
import { colors, family } from "../../../constants/theme";

// Methods
import getPaymentDetails from "../../../adyen/functions/getPaymentDetails";

const PaymentResult = ({ navigation, route }) => {
  const getDetails = async () => {
    const details = await getPaymentDetails(route?.params?.redirectResult);
    const { resultCode, refusalReason, refusalReasonCode } = details;
    
    if (resultCode === "Authorised") {
      navigation.navigate("PaymentSuccess");
    } else {
      navigation.navigate("PaymentError", {
        from: "events",
        errorData: refusalReason,
        refusalReasonCode: refusalReasonCode,
      });
    }
  };

  useEffect(() => {
    getDetails();
  }, []);

  return (
    <View style={styles.container}>
      <ActivityIndicator color={colors.primaryColor} size={"small"} />
      <Divider height={15} />
      <Text style={styles.text}>
        Espera unos segundos, tu pago se está realizando...
      </Text>
    </View>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(PaymentResult);

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    padding: 18,
    backgroundColor: colors.white,
  },
  text: {
    fontFamily: family.normal,
    color: colors.brown,
    fontSize: 14,
  },
});
