import React, { useEffect, useState } from "react";
import { Text, View } from "react-native";
import StyleSheet from "react-native-media-query";

// Components
import { getFormattedPrice } from "../../utils/productMethods";
import CustomCheckBox from "../atoms/CustomCheckBox";
import RadioButton from "../atoms/RadioButton";
import CustomAlert from "../molecules/CustomAlert";
import IconButton from "../atoms/IconButton";

// Utils
import {
  variantsCheckHandler,
  variantsRadioHandler,
  getVariantGroupIndex,
  getVariantVariantIndex,
  handleQtyVariant,
} from "../../utils/variantsMethods";

// Hooks
import useForceRender from "../../hooks/useForceRender";
import { colors, family, size } from "../../constants/theme";

const Variants = ({
  data,
  setVariants,
  variantsList,
  color,
  textColor,
  actionColor,
}) => {
  const [forceRender] = useForceRender();
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertData, setAlertData] = useState({});
  useEffect(() => {
    data.map((variant) => {
      if (variant.type === "radio") {
        const variantObject = variant.variants || variant.options;
        variantObject.map((op) => {
          if (op.default === 1) {
            variantsRadioHandler(variant, op, variantsList, setVariants);
          }
        });
      }
    });
    forceRender();
  }, []);

  const qtyHandler = (op, option, variant) => {
    const response = handleQtyVariant(
      op,
      option,
      variant,
      variantsList,
      setVariants
    );
    if (response === false) {
      setAlertData({
        title: `Sólo se puede añadir ${variant?.max_select} ${variant?.name}`,
      });
      setAlertVisible(true);
    }
  };

  const handleRadio = (variant, op) => {
    variantsRadioHandler(variant, op, variantsList, setVariants);
    forceRender();
  };

  const handleCheck = (variant, op) => {
    variantsCheckHandler(variant, op, variantsList, setVariants);
    forceRender();
  };

  const containsVariant = (op) => {
    let result = false;
    variantsList.map((group) => {
      const variantObject = group.variants || group.options;
      variantObject.map((option) => {
        if (option.gid === op.gid) {
          result = true;
        }
      });
    });
    return result;
  };

  return (
    <View style={styles.varsContainer}>
      {data.map((variant) => {
        return (
          <View key={variant.name}>
            <View style={{ marginTop: 10 }}>
              <Text style={[styles.title, textColor && { color: textColor }]}>
                {variant.name}
              </Text>
            </View>
            {(variant.variants || variant.options).map((op) => {
              if (containsVariant(op)) {
                var groupIndex = getVariantGroupIndex(
                  variant.gid,
                  variantsList
                );
                var variantIndex = getVariantVariantIndex(
                  op.gid,
                  variantsList[groupIndex].options
                );
              }
              if (variant.active) {
                return (
                  <View style={styles.option} key={op.name}>
                    <View style={{ flex: 1 }}>
                      {variant.type === "radio" ? (
                        <RadioButton
                          textColor={textColor || colors.brown}
                          selected={containsVariant(op)}
                          label={
                            op.increment > 0
                              ? op.name +
                                " (+" +
                                getFormattedPrice(op.increment) +
                                ")"
                              : op.name
                          }
                          onPress={() => handleRadio(variant, op)}
                        />
                      ) : (
                        <CustomCheckBox
                          key={op.gid}
                          textColor={textColor || colors.brown}
                          label={
                            op.increment > 0
                              ? op.name +
                                " (+" +
                                getFormattedPrice(op.increment) +
                                ")"
                              : op.name
                          }
                          value={containsVariant(op)}
                          onPress={() => handleCheck(variant, op)}
                        />
                      )}
                    </View>

                    {variant.allow_quantity === 1 && containsVariant(op) && (
                      <View
                        style={styles.actions}
                        dataSet={{ media: ids.actions }}
                      >
                        <IconButton
                          icon="minus"
                          onPress={() => qtyHandler(op, "remove", variant)}
                          color={color || colors.brown}
                          textColor={actionColor || colors.white}
                        />
                        <Text
                          style={[
                            styles.qtyText,
                            textColor && { color: textColor },
                          ]}
                          dataSet={{ media: ids.qtyText }}
                        >
                          {variantsList[groupIndex]?.options[variantIndex].qty}
                        </Text>
                        <IconButton
                          icon="plus"
                          onPress={() => qtyHandler(op, "add", variant)}
                          color={color || colors.brown}
                          textColor={actionColor || colors.white}
                        />
                      </View>
                    )}
                  </View>
                );
              } else {
                return <View></View>;
              }
            })}
          </View>
        );
      })}
      <CustomAlert
        setModalVisible={setAlertVisible}
        modalVisible={alertVisible}
        alertData={alertData}
      />
    </View>
  );
};

export default Variants;

const { ids, styles } = StyleSheet.create({
  varsContainer: {
    paddingVertical: 20,
    marginVertical: 10,
    paddingBottom: 20,
  },
  title: {
    fontSize: size.small,
    fontFamily: family.bold,
    color: colors.brown,
  },
  option: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "space-between",
    width: "100%",
    height: 26,
    marginTop: 5,
  },
  actions: {
    flexDirection: "row",
    width: 100,
    height: 26,
    paddingLeft: 5,
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width: 320px)": {
      width: 60,
    },
  },
  qtyText: {
    fontSize: 20,
    fontFamily: family.normal,
    color: colors.darkGrey,
    "@media (max-width: 320px)": {
      fontSize: 14,
    },
  },
});
