import React, { useState, useEffect } from "react";
import { StyleSheet, View } from "react-native";

// Components
import Screen from "../../../components/atoms/Screen";
import EmptyScreen from "../../../components/molecules/EmptyScreen";
import EventList from "./components/EventList";
import LoadingScreen from "./components/LoadingScreen";

// Methods
import { fetchEventsOfType } from "./methods/fetchEventsOfType";

const EventListScreen = ({ navigation, route }) => {
  const [loading, setLoading] = useState(false);
  const [listItems, setListItems] = useState([]);
  const [error, setError] = useState();

  const { idEvent } = route.params;

  const getData = async () => {
    setLoading(true);
    fetchEventsOfType(idEvent).then((response) => {
      if (response.status === "success") {
        setListItems(response.data);
      } else {
        setError(response.message);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  if (error) {
    return <EmptyScreen id="places" title={error} />;
  }

  if (listItems.length === 0) {
    return <EmptyScreen id="places" title="No hay eventos actualmente" />;
  }

  return (
    <Screen>
      <View style={styles.container}>
        <EventList navigation={navigation} data={listItems} />
      </View>
    </Screen>
  );
};

export default EventListScreen;

const styles = StyleSheet.create({
  container: {
    flex:1,
    alignItems: "center",
  },
});
