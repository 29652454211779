import React, { useState } from "react";
import { StyleSheet, ScrollView, View } from "react-native";
import { connect } from "react-redux";

// Components
import ScheduleItem from "./ScheduleItem";
import Divider from "../../../../../../components/atoms/Divider";

// Constants
import { family } from "../../../../../../constants/theme";

// Redux
import { setDate } from "../../../../../../redux/cart/cartActions";
import { setOrderType } from "../../../../../../redux/cart/cartActions";

// Utils
import { getArrayOfSchedules } from "../../../../../../utils/schedules/getArrayOfSchedules";

const SchedulesContainer = ({ customStyle, shop, setDate, setOrderType }) => {
  const [selectedItem, setSelectedItem] = useState(shop.delivery);

  const setScheduleHandler = (date) => {
    if (date) {
      setDate(date);
      setSelectedItem(date);
      setOrderType("Scheduled");
    } else {
      setDate(null);
    }
  };

  const generateArrayOfSchedules = () => {
    let schedules = [];
    shop?.schedules.map((item) => {
      const schedule = getArrayOfSchedules(item, shop)
      schedules.push(...schedule);
    });
    return schedules
  };

  return (
    <View style={styles.container}>
      <ScrollView
        style={[styles.horizontalScroll]}
        showsHorizontalScrollIndicator={false}
      >
        {generateArrayOfSchedules().map((item) => (
          <ScheduleItem
            key={item}
            text={item}
            onPress={() => setScheduleHandler(item)}
            selected={selectedItem === item}
            customStyle={customStyle}
          />
        ))}
        <Divider width={50} />
      </ScrollView>
    </View>
  );
};

const mapDispatchToProps = {
  setDate,
  setOrderType,
};

export default connect(null, mapDispatchToProps)(SchedulesContainer);

const styles = StyleSheet.create({
  container: {
    maxHeight: 200,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  listContainer: {
    borderLeftWidth: 1,
    width: "100%",
  },
  horizontalScroll: {
    maxHeight: 200,
  },
  title: {
    fontFamily: family.normal,
    fontSize: 10,
  },
});
