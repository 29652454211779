import React from "react";
import { Text, StyleSheet, View } from "react-native";

// Constants
import { colors, family } from "../../../../constants/theme";

const MessageScreen = ({ message }) => (
  <View style={styles.screen}>
    <Text style={styles.text}>{message}</Text>
  </View>
);

const styles = StyleSheet.create({
  text: {
    fontSize: 18,
    color: colors.brown,
    fontFamily: family.normal,
    textAlign:'center'
  },
  screen: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default MessageScreen;