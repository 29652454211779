import * as Localization from "expo-localization";
import { UrlBaseV2 } from "../../constants/urls";
import { fetchUrl } from "../calls/fetchUrl";

const timezone = Localization.timezone;

export const fetchAllEvents = async () => {
  const response = await fetchUrl(
    `${UrlBaseV2}event?page=1&limit=50&sortBy=asc&timezone=${timezone}&active=true`
  );
  return response;
};
