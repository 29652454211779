import React, { useState } from "react";
import { StyleSheet, Text, ScrollView, View } from "react-native";
import { connect } from "react-redux";

// Components
import ScheduleItem from "./ScheduleItem";
import Divider from "../../../../../../components/atoms/Divider";

// Constants
import { family } from "../../../../../../constants/theme";

// Redux
import {
  setDate,
  setOrderType,
} from "../../../../../../redux/laterCart/laterCartActions";
import { getArrayOfSchedules } from "../../../../../../utils/schedules/getArrayOfSchedules";

const SchedulesContainer = ({ shop, setDate, setOrderType, date }) => {
  const setScheduleHandler = (date) => {
    if (date) {
      setDate(date);
      setOrderType("Scheduled");
    } else {
      setDate(null);
    }
  };

  return (
    <View style={styles.container}>
      {shop?.schedules?.hours.map((schedule) => {
        const schedules = getArrayOfSchedules(schedule, shop);
        return (
          <ScrollView
            style={[styles.horizontalScroll]}
            showsHorizontalScrollIndicator={false}
          >
            {schedules.map((item) => (
              <ScheduleItem
                key={item}
                text={item}
                onPress={() => setScheduleHandler(item)}
                selected={date === item}
              />
            ))}
            <Divider width={50} />
          </ScrollView>
        );
      })}
    </View>
  );
};

const mapStateToProps = (state) => {
  return {
    date: state.laterOrders.date,
  };
};

const mapDispatchToProps = {
  setDate,
  setOrderType,
};

export default connect(mapStateToProps, mapDispatchToProps)(SchedulesContainer);

const styles = StyleSheet.create({
  container: {
    maxHeight: 167,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  listContainer: {
    borderLeftWidth: 1,
    width: "100%",
  },
  horizontalScroll: {
    maxHeight: 170,
  },
  title: {
    fontFamily: family.normal,
    fontSize: 10,
  },
});
