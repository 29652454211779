import React from "react";
import { Text, View } from "react-native";
import StyleSheet from "react-native-media-query";

// Constants
import { colors, family } from "../../../../../constants/theme";

const LiveTag2 = () => {
  return (
    <View style={styles.tag} dataSet={{ media: ids.tag }}>
      <Text style={styles.text} dataSet={{ media: ids.text }}>
        Live
      </Text>
    </View>
  );
};

export default LiveTag2;

const { ids, styles } = StyleSheet.create({
  tag: {
    borderRadius: 8,
    backgroundColor: colors.lightPrimary,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    flexShrink: 1,
    flexGrow: 0,
    paddingVertical: 5,
    paddingHorizontal: 24,
  },
  text: {
    fontFamily: family.normal,
    fontSize: 14,
    color: colors.white,
  },
});
