import React from "react";
import { StyleSheet, Text, View } from "react-native";

// Constants
import { family } from "../../../../constants/theme";

const Description = ({ description, customStyle }) => {
  const { custom_color_letter_2_actions } = customStyle;
  return description ? (
    <View style={styles.description}>
      <Text
        style={[
          styles.descriptionText,
          { color: custom_color_letter_2_actions },
        ]}
      >
        {description}
      </Text>
    </View>
  ) : (
    <View></View>
  );
};

export default Description;

const styles = StyleSheet.create({
  description: {
    marginTop: 10,
  },
  descriptionText: {
    fontFamily: family.normal,
    fontSize: 16,
  },
});
