export const ADD_PRODUCT = "ADD_PRODUCT";

export const ADD_SIMPLE_PRODUCT = "ADD_SIMPLE_PRODUCT";
export const ADD_COMPLEX_PRODUCT = "ADD_COMPLEX_PRODUCT";
export const ADD_MENU = "ADD_MENU";

export const DECREMENT_PRODUCT = "DECREMENT_PRODUCT";
export const DECREMENT_MENU_PRODUCT = "DECREMENT_MENU_PRODUCT";
export const INCREMENT_MENU_PRODUCT = "INCREMENT_MENU_PRODUCT";
export const INCREMENT_PRODUCT = "INCREMENT_PRODUCT";
export const RESET_CART = "RESET_CART";
export const ADD_FORMED_MENU = "ADD_FORMED_MENU";
export const REMOVE_FORMED_MENU = "REMOVE_FORMED_MENU";
export const RESET_FORMED_MENU = "RESET_FORMED_MENU";
export const ADD_MENU_VARIANTS = "ADD_MENU_VARIANTS";
export const ADD_SHOP = "ADD_SHOP";
export const RESET_SHOP_ORDERS = "RESET_SHOP_ORDERS";

export const SET_SERVICE = "SET_SERVICE";
export const SET_DISCOUNT = "SET_DISCOUNT";
export const SET_COMISSION = "SET_COMISSION";
export const SET_TOTAL_TO_PAY = "SET_TOTAL_TO_PAY";

export const SET_LOCATION = "SET_LOCATION";
export const SET_DATE = "SET_DATE";
export const SET_ORDER_TYPE = "SET_ORDER_TYPE";
export const SET_COMMENT = "SET_COMMENT";
export const SET_PAYMENT_METHOD = "SET_PAYMENT_METHOD";

export const PURGUE_CART = "PURGUE_CART";
export const SET_CARD = "SET_CARD";
