import { baseVersion } from "../../../../../config";
import { UrlBaseV2 } from "../../../../constants/urls";

export const setFinishedOrder = async (id, token) => {
  try {
    const response = await fetch(`${UrlBaseV2}order/status`, {
      headers: {
        "Content-Type": "application/json",
        Connection: "keep-alive",
        Authorization: `Bearer ${token}`,
        "X-Client-Version": baseVersion,
      },
      body: JSON.stringify({
        orderIds: [id],
        status: 9,
      }),
      method: "PATCH",
    });

    const resData = await response.json();
    
    if (resData.status === "success") {
      return { status: resData.status };
    } else {
      return { status: "fail", message: resData.message };
    }
  } catch (error) {
    console.log("[error fetching order] ", JSON.stringify(error));
    return { status: "fail", message: error?.message };
  }
};
