import React from "react";
import { connect } from "react-redux";

// Components
import Product from "../../../../components/product/Product";
import AddedProduct from "../../../../components/product/AddedProduct";

// Redux
import {
  addProduct,
  decrementProduct,
  decrementMenuProduct,
  incrementMenuProduct,
} from "../../../../redux";

const PlateProduct = ({
  onPress,
  addProduct,
  data,
  setModal,
  shopData,
  navigation,
  menuData,
  canBeAdded = true,
  currentMenu,
  setSelections,
  selections,
  decrementMenuProduct,
  incrementMenuProduct,
}) => {
  const currentProduct = currentMenu?.plates.filter(
    (el) => el.gid === data.gid && menuData.typePlate === el.id_menu_type_plate
  );

  const canBeSelected =
    data.stock !== 0 &&
    data.stock !== undefined &&
    (currentProduct?.quantity !== undefined && currentProduct?.quantity !== null
      ? currentProduct?.quantity < data.stock
      : true);

  const removeHandler = (internalId) => {
    if (selections > 0) {
      decrementMenuProduct(internalId);
      setSelections((prev) => prev - 1);
    }
  };

  const incrementHandler = (internalId) => {
    if (canBeAdded) {
      incrementMenuProduct(internalId);
      setSelections((prev) => prev + 1);
    }
  };

  const hasComplexPlates = () => {
    let result = false;
    if (data?.variants?.length > 0 || data?.ingredients?.length > 0) {
      result = true;
    }
    return result;
  };

  const addHandler = () => {
    if (canBeAdded) {
      if (canBeSelected) {
        if (hasComplexPlates()) {
          navigation.navigate("EventsStack", {
            screen: "MealVariablesScreen",
            params: {
              data: data,
              shopData: shopData,
              product_type: data?.product_type,
              currentQuantity: 0,
              onPress: onPress,
              setSelections: setSelections,
              menuData: menuData,
            },
          });
        } else {
          setSelections((prev) => prev + 1);
          addProduct({
            shop: shopData,
            fk_point_sale: data.fk_point_sale,
            gid: data.gid,
            family: data.family,
            only_legal_age: data.only_legal_age,
            not_pay_service: data.not_pay_service,
            not_pay_commission: data.not_pay_commission,
            exclusive_order: data.exclusive_order,
            type: data.type,
            increment: data.increment,
            amount: data.amount,
            name: data.name,
            menuData: menuData,
            quantity: 1,
          });
          onPress();
        }
      } else {
        setModal("Max");
      }
    }
  };

  return (
    <>
      <Product
        data={{ ...data, amount: data.increment }}
        onPress={addHandler}
        onAction={() => removeHandler(currentProduct[0].internal_id)}
        showActions={!hasComplexPlates()}
        quantity={currentProduct[0]?.quantity || null}
      />
      {currentProduct.map((product) => {
        if (hasComplexPlates()) {
          return (
            <AddedProduct
              onPress={() => incrementHandler(product.internal_id)}
              key={product.internal_id}
              data={product}
              startAmount={data.amount}
              quantity={product.quantity}
              onAction={() => removeHandler(product.internal_id)}
            />
          );
        }
      })}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    currentMenu: state.cart.currentMenu,
  };
};

const mapDispatchToProps = {
  addProduct,
  decrementProduct,
  decrementMenuProduct,
  incrementMenuProduct,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlateProduct);
