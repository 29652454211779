import React, { useState } from "react";
import StyleSheet from "react-native-media-query";
import {
  Text,
  View,
  TouchableOpacity,
  Image,
  useWindowDimensions,
} from "react-native";

// Components
import Divider from "../../atoms/Divider";
import DescriptionModal from "../modals/DescriptionModal";

// Utils
import { getFormattedPrice } from "../../../utils/productMethods";

// Constants
import { family } from "../../../constants/theme";
import IconButtonTickets from "./IconButtonTickets";
import TicketWarningModal from "../modals/TicketWarningModal";

const TicketDetailItem = ({ data, onAdd, onRemove }) => {
  const [qty, setQty] = useState(0);
  const [modal, setModal] = useState();
  const width = useWindowDimensions().width;

  const addHandler = (data) => {
    if (data.sold_out === 0) {
      if (qty >= data.stock) {
        setModal("Max");
      } else {
        setQty(qty + 1);
        onAdd(data);
      }
    }
  };

  const removeHandler = (data) => {
    if (qty > 0) {
      setQty(qty - 1);
      onRemove(data);
    }
  };

  return (
    <View style={styles.container} dataSet={{ media: ids.container }}>
      {data?.stock === 0 ||
        (data?.sold_out === 1 && (
          <View style={styles.imageContainer}>
            <Image
              style={styles.soldOut}
              source={require("../../../assets/sold_out.png")}
            />
          </View>
        ))}
      {data.image !== null && data.image !== undefined && width > 300 && (
        <Image style={styles.imageWrapper} source={{ uri: data.image }} />
      )}
      <View
        style={[
          styles.infoContainer,
          data.sold_out === 1 && { paddingLeft: data.image !== null ? 10 : 50 },
          data.sold_out === 1 && width < 300 && { paddingLeft: 50 },
        ]}
      >
        <Text
          //numberOfLines={2}
          style={styles.title}
          dataSet={{ media: ids.title }}
        >
          {data.name}
        </Text>
        {data.short_description !== null &&
          data.short_description !== undefined && (
            <>
              <Divider height={5} />
              <Text
                numberOfLines={2}
                style={styles.text}
                dataSet={{ media: ids.text }}
              >
                {data.short_description}
              </Text>
            </>
          )}
        {data?.long_description !== undefined &&
          data?.long_description !== null &&
          data?.long_description !== "" && (
            <>
              <Divider height={5} />
              <TouchableOpacity onPress={() => setModal("Description")}>
                <Text style={styles.infoText}>Más información</Text>
              </TouchableOpacity>
            </>
          )}
      </View>
      <View style={styles.actionsContainer}>
        <View
          style={styles.actionWrapper}
          dataSet={{ media: ids.actionWrapper }}
        >
          <View style={styles.priceContainer}>
            <Text style={styles.qtyText}>{getFormattedPrice(data.amount)}</Text>
          </View>
          <Divider height={10} />
          <View style={styles.actions} dataSet={{ media: ids.actions }}>
            <IconButtonTickets
              color={"white"}
              textColor={"black"}
              icon="minus"
              onPress={() => removeHandler(data)}
            />
            <View style={{ alignItems: "center", width: 30 }}>
              <Text style={styles.qtyText}>{qty}</Text>
            </View>
            <IconButtonTickets
              color={"white"}
              textColor={"black"}
              icon="plus"
              onPress={() => addHandler(data)}
            />
          </View>
        </View>
      </View>
      <TicketWarningModal
        setModal={setModal}
        visible={modal === "Max"}
        text={"Se ha alcanzado el máximo de existencias"}
      />
      <DescriptionModal
        setModal={setModal}
        visible={modal === "Description"}
        data={data?.long_description}
      />
    </View>
  );
};

export default TicketDetailItem;

const { ids, styles } = StyleSheet.create({
  container: {
    width: 350,
    marginBottom: 10,
    minHeight: 80,
    borderRadius: 15,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0,0,0,0.7)",
    "@media (max-width: 700px)": {
      width: "100%",
    },
  },
  imageContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 10,
  },
  imageWrapper: {
    minHeight: 80,
    height: "100%",
    width: 80,
    borderBottomLeftRadius: 15,
    borderTopLeftRadius: 15,
  },
  soldOut: {
    position: "absolute",
    top: 0,
    left: 0,
    minHeight: 80,
    height: "100%",
    width: 80,
    borderBottomLeftRadius: 15,
    borderTopLeftRadius: 15,
  },
  qtyText: {
    fontSize: 14,
    fontFamily: family.normal,
    color: "white",
    "@media (max-width: 500px)": {
      fontSize: 10,
    },
  },
  infoText: {
    fontFamily: family.normal,
    fontSize: 12,
    color: "white",
  },
  infoContainer: {
    padding: 10,
    width: "100%",
    height: "100%",
    flex: 1,
    justifyContent: "space-evenly",
  },
  actionWrapper: {
    flexDirection: "column",
    alignItems: "center",
    "@media (max-width: 700px)": {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    "@media (max-width: 500px)": {
      flexDirection: "column",
    },
  },
  actionsContainer: {
    height: "100%",
    padding: 10,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  actions: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width: 700px)": {
      marginLeft: 10,
    },
    "@media (max-width: 500px)": {
      marginLeft: 0,
    },
  },
  priceContainer: {
    paddingTop: 2,
    justifyContent: "center",
    alignItems: "flex-start",
  },
  text: {
    fontFamily: family.normal,
    fontSize: 12,
    color: "white",
    "@media (max-width: 500px)": {
      fontSize: 10,
    },
  },
  title: {
    fontFamily: family.bold,
    fontSize: 14,
    color: "white",
    "@media (max-width: 500px)": {
      fontSize: 10,
    },
  },
});
