import React from "react";
import { Text, View, Platform } from "react-native";
import StyleSheet from "react-native-media-query";

// Constants
import { colors, family } from "../../../../constants/theme";

const ClosedTag = () => {
  return (
    <View style={styles.tag} dataSet={{ media: ids.tag }}>
      <Text style={styles.text} dataSet={{ media: ids.text }}>
        Cerrado
      </Text>
    </View>
  );
};

export default ClosedTag;

const { ids, styles } = StyleSheet.create({
  tag: {
    borderRadius: 8,
    backgroundColor: colors.primaryColor,
    paddingVertical: 3,
    paddingHorizontal: 6,
  },
  text: {
    fontFamily: family.semibold,
    fontSize: 15,
    color: colors.white,
  },
});
