import React from "react";
import { Feather } from "@expo/vector-icons";
import { StyleSheet, View } from "react-native";

// Components
import Divider from "../../components/atoms/Divider";
import ActionButton from "./ActionButton";
import Price from "./Price";

// Constants
import { colors } from "../../constants/theme";

const AddProduct = ({
  amount,
  textColor,
  showButton = true,
  onAdd,
  buttonSize = 25,
  iconSize = 20,
}) => {
  return (
    <View style={styles.container}>
      <Price amount={amount} color={textColor} />
      {showButton && (
        <>
          <Divider height={10} />
          <ActionButton
            color={colors.lightenGreen}
            onPress={onAdd}
            size={buttonSize}
          >
            <Feather name="plus" size={iconSize} color={colors.lightGreen} />
          </ActionButton>
        </>
      )}
    </View>
  );
};

export default AddProduct;

const styles = StyleSheet.create({
  container: {
    flex:1,
    maxWidth: 60,
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
});
