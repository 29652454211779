import React, { useState } from "react";
import { View, StyleSheet } from "react-native";
import { connect } from "react-redux";

// Components
import Divider from "../../../../../components/atoms/Divider";
import Wrapper from "./Wrapper";
import Title from "./Title";
import ScheduleTag from "./ScheduleTag";
import ClosedTag from "./ClosedTag";
import CustomAlert from "../../../../../components/molecules/CustomAlert";

// Redux
import { addShop } from "../../../../../redux";

// Utils
import { navigateToEvent } from "../../../../../utils/redirection/navigateToEvent";

const EventItem = ({ data, navigation, addShop }) => {
  const [modal, setModal] = useState();
  const [alertData, setAlertData] = useState();

  const { image, name, schedule } = data;
  const abierto = schedule.opened || schedule?.scheduled;

  const alertHandler = (text) => {
    setAlertData({
      title: text,
    });
    setModal("Alert");
  };

  const itemHandler = () => {
    navigateToEvent({
      gid: data.gid,
      onError: alertHandler,
      navigation: navigation,
      addShop
    });
  };

  return (
    <Wrapper opened={abierto} image={image} onPress={itemHandler}>
      <View style={styles.content}>
        <View style={styles.tagContainer}>{!abierto && <ClosedTag />}</View>
        <View style={styles.infoContainer}>
          <Title title={name} />
          <Divider height={5} />
          {schedule?.scheduled === 1 && <ScheduleTag />}
        </View>
      </View>
      <CustomAlert
        setModalVisible={setModal}
        modalVisible={modal === "Alert"}
        alertData={alertData}
      />
    </Wrapper>
  );
};

const styles = StyleSheet.create({
  content: {
    width: "100%",
    height: "100%",
    padding: 24,
    justifyContent: "space-between",
  },
  infoContainer: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
  tagContainer: {
    flexDirection: "row",
    alignItems: "flex-start",
  },
});

const mapDispatchToProps = {
  addShop
}

export default connect(null,addShop)(EventItem);
