import React from "react";
import { Text, StyleSheet } from "react-native";
import { Octicons, Feather } from "@expo/vector-icons";

// Constants
import { colors, family } from "../../../constants/theme";

const Title = ({ index, active }) => {
  const color = active ? colors.primaryColor : colors.brown;
  const fontFamily = active ? family.bold : family.normal;

  const styles = { color, fontFamily, fontSize: 10 };
  switch (index) {
    case 0:
      return <Text style={styles}>Eventos</Text>;
    case 1:
      return <Text style={styles}>Pedidos</Text>;
    case 2:
      return <Text style={styles}>Perfil</Text>;
  }
};

export default Title;
