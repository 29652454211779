import React from "react";
import { StyleSheet, Text, View } from "react-native";
import Divider from "../../../../../components/atoms/Divider";
import { colors, family } from "../../../../../constants/theme";

const ErrorContainer = ({ error }) => {
  return (
    <View style={styles.container}>
      <Text style={styles.title}>Algo salió mal</Text>
      <Divider height={22} />
      <Text style={styles.text}>{error}</Text>
    </View>
  );
};

export default ErrorContainer;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontSize: 22,
    color: colors.brown,
    fontFamily: family.bold,
  },
  text: {
    fontSize: 18,
    color: colors.brown,
    fontFamily: family.normal,
    textAlign: "center",
  },
});
