import React from "react";
import StyleSheet from "react-native-media-query";

import { Text, View, TouchableOpacity } from "react-native";
import { family, RADIUS } from "../../../constants/theme";
import Divider from "../../atoms/Divider";
import Link from "../../atoms/Link";
import { openURL } from "expo-linking";

const LastStepInfo = ({ action, buttonTitle, textArray, children, error }) => {
  const linkHandler = () => {
    openURL("mailto:soporte@gopick-app.com");
  };
  return (
    <View style={styles.container} dataSet={{ media: ids.container }}>
      {textArray.map((text, index) => (
        <View key={index} style={{ flexDirection: "column" }}>
          <Text
            style={[styles.text, error && { color: "#FD5D5D" }]}
            dataSet={{ media: ids.text }}
          >
            {text}
          </Text>
          <Divider height={30} />
        </View>
      ))}
      {children && (
        <>
          {children}
          <Divider height={30} />
        </>
      )}
      <Link
        name={
          <>
            <Text style={styles.text}>
              Si has tenido algún problema al comprar tu entrada, contacta con{" "}
            </Text>
            <Text
              style={[
                styles.text,
                styles.underline,
                { fontFamily: family.bold },
              ]}
            >
              soporte@gopick-app.com
            </Text>
          </>
        }
        onPress={linkHandler}
      />
      <Divider height={20} />
      {action && (
        <View style={styles.buttonContainer}>
          <TouchableOpacity
            style={styles.button}
            onPress={action}
            activeOpacity={0.3}
            dataSet={{ media: ids.button }}
          >
            <Text
              style={[styles.text, { color: "black" }]}
              dataSet={{ media: ids.text }}
            >
              {buttonTitle}
            </Text>
          </TouchableOpacity>
        </View>
      )}
    </View>
  );
};

export default LastStepInfo;

const { ids, styles } = StyleSheet.create({
  container: {
    width: "100%",
    paddingHorizontal: 10,
    alignItems: "center",
    justifyContent: "center",
  },
  underline: {
    textDecorationLine: "underline",
    textDecorationStyle: "solid",
    textDecorationColor: "white",
  },
  buttonContainer: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 18,
  },
  button: {
    width: "100%",
    maxWidth: 200,
    height: 46,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: RADIUS,
    "@media (max-width: 400px)": {
      maxWidth: 100,
      height: 30,
    },
  },
  text: {
    fontFamily: family.bold,
    fontSize: 18,
    textAlign: "center",
    color: "white",
    "@media (max-width: 400px)": {
      fontSize: 12,
    },
  },
});
