import React, { useState, useEffect } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";

// Components
import CustomButton from "../../../components/atoms/CustomButton";
import CustomError from "../../../components/atoms/CustomError";
import CustomText from "../../../components/atoms/CustomText";
import Divider from "../../../components/atoms/Divider";
import TextInput from "../../../components/atoms/TextInput";
import Hero from "../../../components/organisms/auth/components/Hero";

// Constants
import { colors } from "../../../constants/theme";

// Methods
import { lostPassword } from "../../../components/organisms/auth/methods/lostPassword";

// Utils
import { validateMail } from "../../../utils/arrayMethods";

const ChangePasswordSection = ({ setStep }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSucess] = useState(false);

  const validEmail = validateMail(email);

  const passswordChangeHandler = async () => {
    setError();
    setLoading(true);
    const response = await lostPassword(email);

    if (response?.status === "success") {
      setEmail("");
      setSucess(true);
    } else if (response?.status === "fail") {
      setError(response.message);
    } else {
      setError("Algo salió mal");
    }
    setLoading(false);
  };

  return (
    <View style={styles.container}>
      {success === false ? (
        <>
          <Hero
            title={"Cambio de contraseña"}
            subtitle={
              "Introduce el correo electrónico vinculado a tu cuenta y te enviaremos un enlace para cambiar la contraseña."
            }
            customStyle={{ textColor: colors.brown }}
          />
          <Divider height={20} />
          <TextInput
            marginVertical={14}
            placeholder={"Email"}
            value={email}
            onChange={setEmail}
          />
          <View style={styles.buttonContainer}>
            <CustomButton
              color={colors.primaryColor}
              textColor={colors.white}
              active={validEmail}
              title={"Aceptar"}
              onPress={passswordChangeHandler}
              loading={loading}
            />
          </View>
          <CustomError error={error} color={colors.primaryColor} />
          <TouchableOpacity onPress={() => setStep(0)} style={{ marginTop: 4 }}>
            <CustomText color={colors.brown} text={"Volver"} />
          </TouchableOpacity>
        </>
      ) : (
        <>
          <Hero
            title={"¡Hecho!"}
            subtitle={
              "Revisa el correo electrónico introducido. \nSe ha enviado un email para cambiar la contraseña."
            }
            customStyle={{ textColor: colors.brown }}
          />
          <Divider height={20} />
          <View style={styles.buttonContainer}>
            <CustomButton
              color={colors.primaryColor}
              textColor={colors.white}
              active
              title={"Volver"}
              onPress={() => setStep(0)}
            />
          </View>
        </>
      )}
    </View>
  );
};

export default ChangePasswordSection;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonContainer: {
    width: "100%",
    height: 70,
  },
});
