import { baseVersion } from "../../../config";
import { URlOrder } from "../../constants/urls";

export const validateMerchant = async (validationURL) => {
  try {
    const response = await fetch(`${URlOrder}/pay/adyen/apple/session`, {
      headers: {
        "Content-Type": "application/json",
        "X-Client-Version": baseVersion,
      },
      body: JSON.stringify({ validationURL }),
      method: "POST",
    });

    const resData = await response.json();
    return resData.data;
  } catch (error) {
    console.log("[error] ", JSON.stringify(error));
    return "fail";
  }
};
