import React, { useState, useContext } from "react";
import { connect } from "react-redux";

// Components
import Divider from "../../../components/atoms/Divider";
import ErrorMessage from "../../../components/atoms/ErrorMessage";
import IconButton from "../../../components/buttons/IconButton";
import CustomAlert from "../../../components/molecules/CustomAlert";
import WarningModal from "../../../components/organisms/modals/WarningModal";

// Constants
import { colors } from "../../../constants/theme";

// Context
import { AuthContext } from "../../../contexts/AuthContext";

// Redux
import { deleteCustomer, logOut } from "../../../redux/user/userActions";

const DataSection = ({ user, logOut, setStep, deleteCustomer, navigation }) => {
  const [error, setError] = useState();
  const [modal, setModal] = useState();

  const { user: firebaseUser } = useContext(AuthContext);

  const passwordChangeHandler = () => {
    setStep(1);
  };

  const deleteAccount = async () => {
    setError();
    const token = await firebaseUser.getIdToken(true);
    const response = await deleteCustomer(token);
    if (response === "success") {
      setModal("Warning");
    } else {
      setError(response);
    }
  };

  const handleDeleteModal = () => {
    logOut();
    navigation.goBack();
  };

  const deactivateHandler = () => {
    setModal("DeleteAccount");
  };

  const alertData = {
    title: "Desactivar cuenta",
    subtitle:
      "Si continuas con el borrado de cuenta perderás todos tus pedidos hechos y no habrá manera de recuperarlos ni relacionarte con ellos",
    actions: [
      {
        title: "Cancelar",
        disableVisible: false,
        onPress: () => {
          setModal(false);
        },
        textColor: colors.primaryColor,
        color: colors.white,
      },
      {
        title: "Aceptar",
        disableVisible: false,
        onPress: () => {
          setModal(false);
          deleteAccount();
        },
      },
    ],
  };

  return (
    <>
      <IconButton title={user?.name} icon="user" color={colors.brown} />
      <Divider height={20} />
      <IconButton title={user?.email} icon="mail" color={colors.brown} />
      <Divider height={20} />
      <IconButton
        title={"Cambiar contraseña"}
        icon="lock"
        onPress={passwordChangeHandler}
        color={colors.secondary}
      />
      <Divider height={20} />
      <IconButton
        title={"Desactivar cuenta"}
        icon="trash-2"
        onPress={deactivateHandler}
        color={colors.primaryColor}
      />
      {error && <ErrorMessage error={error} />}
      <CustomAlert
        modalVisible={modal === "DeleteAccount"}
        setModalVisible={setModal}
        alertData={alertData}
      />
      <WarningModal
        visible={modal === "Warning"}
        setModal={setModal}
        text={"Los datos de tu cuenta han sido borrados satisfactoriamente"}
        action={handleDeleteModal}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
  };
};

const mapDispatchToProps = {
  deleteCustomer,
  logOut,
};

export default connect(mapStateToProps, mapDispatchToProps)(DataSection);
