import React from "react";
import { StyleSheet } from "react-native";

// Components
import Divider from "../../../components/atoms/Divider";
import IconButton from "../../../components/buttons/IconButton";

const OptionsStep = ({ setStep }) => {
  return (
    <>
      <IconButton
        title={"Aviso legal"}
        icon="chevron-right"
        onPress={() => setStep(1)}
      />
      <Divider height={20} />
      <IconButton
        title={"Política de privacidad"}
        icon="chevron-right"
        onPress={() => setStep(2)}
      />
      <Divider height={20} />
      <IconButton
        title={"Términos y condiciones"}
        icon="chevron-right"
        onPress={() => setStep(3)}
      />
    </>
  );
};

export default OptionsStep;

const styles = StyleSheet.create({});
