import React, { useState, useEffect } from "react";
import { StyleSheet, View, TouchableOpacity, Text } from "react-native";
import { connect } from "react-redux";

// Components
import Divider from "../../../../components/atoms/Divider";
import ServiceItem from "../../../events/ShopScreen/components/ServicePicker/ServiceItem";
import LocationsModal from "../../../events/ShopScreen/components/LocationsModal";

// Contants
import { colors, family } from "../../../../constants/theme";

// Redux
import { setService } from "../../../../redux/laterCart/laterCartActions";

const ServicePicker = ({ shopServices, setService, services, shop }) => {
  const [modal, setModal] = useState();
  const [modalService, setModalService] = useState();
  const service = services.filter((ser) => ser.shopId === shop.gid)[0];

  const allowedServices = shopServices.filter(
    (service) => service.service_id !== 4
  );
 
  const serviceHandler = ({ service, locationText, locationGid }) => {
    setService({
      service: service,
      location: locationText,
      locationId: locationGid,
      shopId: shop.gid,
    });
  };

  const locationsHandler = (service) => {
    let simpleService;
    let locationsService;

    if (service) {
      if (service.levels_locations === "not_show") {
        simpleService = service;
      } else {
        locationsService = service;
      }
    } else {
      simpleService = allowedServices.find(
        (service) => service.levels_locations === "not_show"
      );
      locationsService = allowedServices.find(
        (service) => service.levels_locations !== "not_show"
      );
    }

    if (simpleService) {
      serviceHandler({ service: simpleService });
    } else if (locationsService) {
      setModalService(locationsService);
      setModal("Locations");
    }
  };

  useEffect(() => {
    locationsHandler(service);
  }, []);

  if (allowedServices.length === 1) {
    return (
      <TouchableOpacity onPress={locationsHandler}>
        <Text style={styles.serviceTitle}>
          Servicio: {allowedServices[0].label}
        </Text>
      </TouchableOpacity>
    );
  }

  return (
    <View style={styles.container}>
      <View style={styles.picker}>
        {allowedServices.map((ser) => (
          <ServiceItem
            key={ser.service_id}
            service={ser}
            selected={ser.key === service?.key}
            onPress={() => locationsHandler(ser)}
          />
        ))}
        <LocationsModal
          visible={modal === "Locations"}
          setModal={setModal}
          service={modalService}
          canClose={shopServices.length > 1}
          onFinish={serviceHandler}
          shop={shop}
        />
      </View>
      {service?.location && (
        <View style={styles.locationContainer}>
          <Divider height={10} />
          <TouchableOpacity
            onPress={() => {
              // setModal("Locations");
            }}
          >
            <Text style={styles.title}>{service?.location}</Text>
          </TouchableOpacity>
        </View>
      )}
    </View>
  );
};

const mapStateToProp = (state) => {
  return {
    services: state.laterOrders.services,
  };
};

const mapDispatchToProps = {
  setService,
};

export default connect(mapStateToProp, mapDispatchToProps)(ServicePicker);

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
  },
  picker: {
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: 100,
    borderColor: colors.darkGrey,
    borderWidth: 1,
    flexDirection: "row",
    height: 35,
  },
  title: {
    fontFamily: family.bold,
    fontSize: 14,
    color: colors.secondary,
    textAlign: "center",
  },
  locationContainer: {
    height: 30,
  },
  serviceTitle: {
    fontFamily: family.bold,
    fontSize: 14,
    color: colors.secondary,
    textAlign: "center",
  },
});
