import React, { useState, useEffect } from "react";
import { ActivityIndicator } from "react-native";

// Components
import Wrapper from "./Wrapper";
import Actions from "./Actions";
import ErrorContainer from "./ErrorContainer";
import LocationsSelector from "./LocationsSelector";
import Divider from "../../../../../components/atoms/Divider";

// Constants
import { colors } from "../../../../../constants/theme";

// Utils
import { fetchLocations } from "../../../../../utils/shop/fetchLocations";
import { getLocationText } from "../../../../../utils/locations/getLocationText";

const LocationsModal = ({
  visible,
  canClose,
  setModal,
  // onDismiss,
  service,
  shop,
  onFinish,
}) => {
  const { eventId, gid } = shop;
  const [error, setError] = useState();
  const [status, setStatus] = useState("idle");
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [locations, setLocations] = useState([]);
  const [acceptAvailable, setAcceptAvailable] = useState(false);

  const getLocations = async () => {
    if (visible) {
      setStatus("loading");
      const response = await fetchLocations({
        eventId,
        shopId: gid,
        service: service.key,
      });
      const { status, data, error } = response;
      if (status === "success") {
        if (data.length === 0) {
          setStatus("empty");
          setError("No se encontraron localizaciones para esta tienda");
        } else {
          setStatus("success");
          setLocations([data[0]]);
        }
      } else {
        setStatus("fail");
        setError(error);
      }
    }
  };

  useEffect(() => {
    getLocations();
  }, [visible]);

  const cancelHandler = () => {
    if (canClose) {
      setModal();
    } else {
      setModal();
      // onDismiss()
    }
  };

  const acceptHandler = () => {
    const { text, array } = getLocationText(locations, selectedLocation);
    setSelectedLocation([]);
    let locationText = text;
    let lastLocationGid =
      array.length > 0 ? array[array.length - 1].gid_location : null;

    onFinish({
      service: service,
      locationGid: lastLocationGid,
      locationText: locationText,
    });
    setModal();
  };

  if (status === "loading" || status === "idle") {
    return (
      <Wrapper visible={visible} setModal={setModal} canClose={canClose}>
        <ActivityIndicator color={colors.primaryColor} size="small" />
      </Wrapper>
    );
  }

  if (status === "empty" || status === "fail") {
    return (
      <Wrapper visible={visible} setModal={setModal} canClose={canClose}>
        <ErrorContainer error={error} />
      </Wrapper>
    );
  }

  return (
    <Wrapper visible={visible} setModal={setModal} canClose={canClose}>
      <LocationsSelector
        setAcceptAvailable={setAcceptAvailable}
        selectedLocation={selectedLocation}
        setSelectedLocation={setSelectedLocation}
        locations={locations}
        setLocations={setLocations}
        service={service}
      />
      <Divider height={10} />
      <Actions
        onAccept={acceptHandler}
        onCancel={cancelHandler}
        canCancel={!canClose}
        canAccept={acceptAvailable}
      />
    </Wrapper>
  );
};

export default LocationsModal;
