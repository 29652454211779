import React from "react";
import { Text, StyleSheet, View, TouchableOpacity } from "react-native";

// Constants
import { colors, family } from "../../../../constants/theme";

const MessageScreen = ({ message, onPress, buttonText }) => (
  <View style={styles.screen}>
    <Text style={styles.text}>{message}</Text>
    {onPress && (
      <TouchableOpacity onPress={onPress}>
        <Text style={styles.bold}>{buttonText}</Text>
      </TouchableOpacity>
    )}
  </View>
);

const styles = StyleSheet.create({
  text: {
    fontSize: 18,
    color: colors.brown,
    fontFamily: family.normal,
    textAlign: "center",
  },
  screen: {
    width: "100%",
    flex: 1,
    paddingHorizontal: 18,
    justifyContent: "center",
    alignItems: "center",
  },
  bold: {
    fontSize: 18,
    color: colors.secondary,
    fontFamily: family.bold,
    textAlign: "center",
  },
});

export default MessageScreen;
