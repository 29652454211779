import React from "react";
import { StyleSheet, Text, View } from "react-native";

// Components
import VariantsText from "../../../../../components/product/VariantsText";

// Constants
import { colors, family, size } from "../../../../../constants/theme";

const PlatesText = ({ data }) => {
  return data?.length > 0 ? (
    <View style={{ marginTop: 4 }}>
      {data.map((el) => (
        <Text>
          <Text key={el.name} style={styles.descText}>
            {el.quantity + "x " + el.name}
          </Text>
          {el?.variants.length > 0 && (
            <>
              <Text>: </Text>
              <View style={{ marginLeft: 8 }}>
                <VariantsText variants={el?.variants} />
              </View>
            </>
          )}
        </Text>
      ))}
    </View>
  ) : (
    <View></View>
  );
};

export default PlatesText;

const styles = StyleSheet.create({
  descText: {
    lineHeight: 16,
    fontFamily: family.normal,
    fontSize: size.small,
    color: colors.brown,
  },
  text: {
    fontFamily: family.normal,
    color: colors.brown,
    fontSize: size.small,
  },
});
