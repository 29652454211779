import React from "react";
import { StyleSheet, View, Text, useWindowDimensions } from "react-native";

// Components
import Divider from "../../../../components/atoms/Divider";
import Button from "./Button";

// Constants
import { colors, family } from "../../../../constants/theme";

const EmptyScreen = ({ navigation, text }) => {
  const height = useWindowDimensions().height;
  const buttonAction = () => {
    navigation.navigate("EventsStack", {
      screen: "HomeScreen",
    });
  };

  return (
    <View style={[styles.screen, { height: height }]}>
      <View style={styles.container}>
        <Text style={styles.text}>{text}</Text>
        <Divider height={20} />
        <Button title={"Ver qué me apetece"} onClick={buttonAction} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  screen: {
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 240,
    backgroundColor: "transparent",
  },
  text: {
    fontSize: 18,
    color: colors.brown,
    fontFamily: family.normal,
  },
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
});

export default EmptyScreen;
