import React, { useState } from "react";
import { StyleSheet, View } from "react-native";

// Components
import Screen from "../../../components/atoms/Screen";
import ChangePasswordSection from "./ChangePasswordSection";
import DataSection from "./DataSection";

const ProfileDataScreen = ({ navigation }) => {
  const [step, setStep] = useState(0);
  return (
    <Screen>
      <View style={styles.container}>
        {step === 0 && (
          <DataSection setStep={setStep} navigation={navigation} />
        )}
        {step === 1 && (
          <ChangePasswordSection setStep={setStep} navigation={navigation} />
        )}
      </View>
    </Screen>
  );
};

export default ProfileDataScreen;

const styles = StyleSheet.create({
  container: {
    padding: 18,
  },
});
