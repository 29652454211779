import React from "react";
import { View } from "react-native";

// Components
import CustomButton from "../../components/atoms/CustomButton";

const AdyenPaymentWeb = ({ color, textColor, amount, buttonHandler }) => {
  return (
    <View style={{ paddingHorizontal: 12, flex: 1 }}>
      <CustomButton
        onPress={buttonHandler}
        title={`Confirmar y pagar`}
        color={color}
        textColor={textColor}
        active
      />
    </View>
  );
};

export default AdyenPaymentWeb;
