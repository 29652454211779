import React, { useState, useCallback, useEffect } from "react";
import { StyleSheet, ScrollView, Platform, View } from "react-native";
import { useFocusEffect } from "@react-navigation/native";
import { connect } from "react-redux";

// Components
import Screen from "../../../components/atoms/Screen";
import LiveModal from "./modals/LiveModal";
import CustomAlert from "../../../components/molecules/CustomAlert";
import LiveList from "./components/LiveList";
import EventList from "./components/EventList";
import Divider from "../../../components/atoms/Divider";

// Utils
import { getDistances } from "../../../utils/distanceMethods";
import { getLocalData, storeLocalData } from "../../../utils/miscelanusUtils";
import { navigateToShop } from "../../../utils/redirection/navigateToShop";
import { navigateToPayment } from "../../../utils/redirection/navigateToPayment";
import { navigateToOrders } from "../../../utils/redirection/navigateToOrders";
import { navigateToEvent } from "../../../utils/redirection/navigateToEvent";
import { fetchAllEvents } from "../../../utils/event/fetchAllEvents";

// Redux
import { addShop } from "../../../redux";

// Hooks
import useBeforeRender from "../../../hooks/useBeforeRender";
import LoadingScreen from "./components/LoadingScreen";
import ErrorScreen from "./components/ErrorScreen";

const HomeScreen = ({ location, navigation, addShop }) => {
  const [status, setStatus] = useState("idle");
  const [error, setError] = useState();
  const [events, setEvents] = useState([]);
  const [closeEvents, setCloseEvents] = useState([]);
  const [carouselAvailable, setCarouselAvailable] = useState(true);
  const [modal, setModal] = useState();
  const [alertData, setAlertData] = useState({});

  const poupAvailable =
    carouselAvailable &&
    closeEvents.length > 0 &&
    closeEvents[12]?.popUp?.image !== null;

  const getData = async () => {
    setStatus("loading");
    const response = await fetchAllEvents();
    const { data } = response;
    if (response.status === "fail") {
      setStatus("fail");
      setError(response.error);
    } else if (data.length === 0) {
      setStatus("empty");
      setEvents([]);
    } else {
      setCLoseEventsHandler(data);
      setEvents(data);
      setStatus("success");
    }
  };

  useEffect(() => {
    navigateToOrders(navigation);
  }, []);

  useBeforeRender(async () => {
    await navigateToPayment(navigation);
    await getEventPath();
    await getShopPath();
  }, []);

  useFocusEffect(
    useCallback(() => {
      getData();
    }, [])
  );

  const setCLoseEventsHandler = (array) => {
    if (location?.lat) {
      let distances = getDistances({ array: array, ref: location });
      if (carouselAvailable === true) {
        setCloseEvents(
          array
          .filter((item, index) => {
            if (distances[index] < item?.popUp?.distance && item.opened) {
              return true;
            }
          })
        );
        setModal("CloseEvent");
      }
    }
  };

  const getShopPath = async () => {
    const shopIdUrl = await getLocalData("shopIdUrl");
    const locationId = await getLocalData("locationId");
    const locationName = await getLocalData("locationName");
    storeLocalData("shopIdUrl", "");
    storeLocalData("locationId", null);
    storeLocalData("locationName", null);

    navigateToShop({
      gid: shopIdUrl,
      onError: alertHandler,
      navigation,
      onStart: () => setCarouselAvailable(false),
      location: { id: locationId, name: locationName },
      addShop,
    });
  };

  const getEventPath = async () => {
    const eventIdUrl = await getLocalData("eventIdUrl");
    storeLocalData("eventIdUrl", "");
    navigateToEvent({
      gid: eventIdUrl,
      onError: alertHandler,
      navigation: navigation,
      onStart: () => setCarouselAvailable(false),
      addShop,
    });
  };

  const alertHandler = (text) => {
    setAlertData({
      title: text,
    });
    setModal("Alert");
  };

  const liveItemHandler = (data) => {
    setCarouselAvailable(false);
    setModal();
    navigateToEvent({
      gid: data.gid,
      onError: alertHandler,
      navigation: navigation,
      addShop,
    });
  };

  const liveEvents = events?.filter(
    (event) => event.highlighted && event.opened
  );

  if (status === "idle" || status === "loading") {
    return <LoadingScreen />;
  }

  if (status === "fail") {
    return <ErrorScreen error={error} />;
  }

  return (
    <Screen maxWidth={null}>
      <View style={{ flex: 1 }}>
        <ScrollView
          style={styles.scrollContainer}
          showsVerticalScrollIndicator={false}
        >
          <LiveList
            events={liveEvents}
            status={status}
            error={error}
            navigation={navigation}
          />
          <Divider height={20} />
          <EventList navigation={navigation} events={events} />
        </ScrollView>
      </View>
      <CustomAlert
        setModalVisible={setModal}
        modalVisible={modal === "Alert"}
        alertData={alertData}
      />
      {poupAvailable && (
        <LiveModal
          setModal={setModal}
          modal={modal === "CloseEvent"}
          data={closeEvents}
          liveItemHandler={liveItemHandler}
        />
      )}
    </Screen>
  );
};

const styles = StyleSheet.create({
  scrollContainer: {
    height: 1,
  },
});

const mapStateToProps = (state) => {
  return {
    location: state.user.location,
  };
};

const mapDispatchToProps = {
  addShop,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeScreen);
