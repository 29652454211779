import React from "react";
import { Text, View, TouchableOpacity, Platform } from "react-native";
import { connect } from "react-redux";
import StyleSheet from "react-native-media-query";

// Constants
import { colors, family } from "../../../../constants/theme";

// Utils
import { hasOrders } from "../../../../utils/orderMethods";
import {
  getFormattedPrice,
  getProductsCost,
} from "../../../../utils/productMethods";

const Footer = ({ orders, action }) => {
  const price = getFormattedPrice(getProductsCost(orders));

  if (!hasOrders(orders)) {
    return <View></View>;
  }
  return (
    <View style={styles.footerContainer}>
      <TouchableOpacity
        dataSet={{ media: ids.confirmButton }}
        style={styles.confirmButton}
        onPress={action}
      >
        <Text dataSet={{ media: ids.confirmText }} style={styles.confirmText}>
          Ir a la cesta ({price})
        </Text>
      </TouchableOpacity>
    </View>
  );
};

const mapStateToProps = (state) => {
  return {
    orders: state.cart.orders,
  };
};

export default connect(mapStateToProps)(Footer);

const { ids, styles } = StyleSheet.create({
  footerContainer: {
    position: "absolute",
    bottom: 0,
    height: Platform.OS === "web" ? 150 : 68,
    width: "100%",
    paddingBottom: 18,
    paddingHorizontal: 18,
    maxWidth: 400,
  },
  confirmButton: {
    width: "100%",
    height: 46,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.primaryColor,
    borderRadius: 60,
    "@media (max-width: 320px)": {
      height: 32,
    },
  },
  confirmText: {
    color: colors.white,
    fontFamily: family.bold,
    fontSize: 20,
    "@media (max-width: 320px)": {
      fontSize: 14,
    },
  },
});
