export const environment = "prod"; //dev || prod
export const app = "pwa"; // pwa || tickets

export const baseVersion = "2.3.5";
const date = "240424";

export const version =
  environment === "dev"
    ? `${baseVersion}-dev-${date}`
    : `${baseVersion}.${date}`;
