import { UrlBaseV2 } from "../../constants/urls";
import { fetchUrl } from "../calls/fetchUrl";

export const fetchProducts = async ({ shopId, locId, pointSales }) => {
  let productsArray = [];
  const response = { status: "fail", data: [], message: "" };

  const status = "success";
  for (const gid of pointSales) {
    const fetchResponse = await fetchUrl(
      `${UrlBaseV2}product?onlyMenu=false&shopId=${shopId}&pointSaleId=${gid}${
        locId ? `&locationId=${locId}` : ""
      }&active=true`
    );
    if (fetchResponse.status === "success") {
      productsArray = [...productsArray, ...fetchResponse.data];
    } else {
      status = "fail";
      response.message = fetchResponse.message;
      break;
    }
  }
  if (status === "fail") {
    response.status = "success";
    response.data = [];
  } else if (productsArray.length === 0) {
    response.status = "success";
    response.data = [];
  } else {
    response.status = "success";
    response.data = productsArray;
  }

  return response;
};
