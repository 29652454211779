import React from "react";
import { openURL } from "expo-linking";
import { Platform, StyleSheet, TouchableOpacity } from "react-native";

import Svg, { Image } from "react-native-svg";
const WhatsappIcon = () => {
  const url = "https://wa.me/+34622675452";
  return (
    <TouchableOpacity
      style={styles.wrapper}
      onPress={() =>
        Platform.OS === "web" ? window.open(url, "_blank") : openURL(url)
      }
    >
      <Svg style={styles.image}>
        <Image
          style={styles.image}
          href={require("../../../assets/whatsapp.svg")}
        />
      </Svg>
    </TouchableOpacity>
  );
};

export default WhatsappIcon;

const styles = StyleSheet.create({
  wrapper: {
    position: Platform.OS === "web" ? "fixed" : "absolute",
    bottom: 20,
    right: 20,
    width: 45,
    height: 45,
  },
  image: {
    width: 45,
    height: 45,
  },
});
