import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

// Components
import LegalContainer from "./LegalContainer";

// Redux
import { fetchTextualPrivacy } from "../../../redux/config/configActions";

const PrivacyText = ({ privacy, setStep, fetchTextualPrivacy }) => {
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    setLoading(true);
    await fetchTextualPrivacy();
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <LegalContainer
      text={privacy}
      loading={loading}
      setStep={setStep}
    />
  );
};

const mapStateToProps = (state) => {
  return { privacy: state.config.textualPages.privacy };
};

const mapDispatchToProps = {
  fetchTextualPrivacy,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyText);
