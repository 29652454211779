import React from "react";
import { StyleSheet, View, Platform } from "react-native";

// Components
import CompletedButton from "./CompletedButton";
import Title from "./Title";

const SectionSelector = ({ section, setSection }) => {
  return (
    <View style={styles.container}>
      <View style={styles.picker}>
        <Title selected={section === 0} setSection={setSection} />
        <CompletedButton selected={section === 1} setSection={setSection} />
      </View>
    </View>
  );
};

export default SectionSelector;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 10,
    paddingHorizontal: 18,
    height: Platform.OS === "ios" ? 100 : 70,
    paddingTop: Platform.select({
      ios: 40,
    }),
  },
  picker: {
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
    maxWidth: 360,
  },
});
