import { baseVersion } from "../../../config";
import { URlOrder } from "../../constants/urls";

const getPaymentDetails = async (text) => {
  const defaultError = {
    resultCode: "Refused",
    refusalReasonCode: 0,
    refusalReason: "No se pudo finalizar el pago",
  };
  try {
    const response = await fetch(`${URlOrder}/pay/payment/details`, {
      headers: {
        "Content-Type": "application/json",
        "X-Client-Version": baseVersion,
      },
      body: JSON.stringify({
        redirectResult: text,
      }),
      method: "POST",
    });

    const resData = await response.json();
    if (resData.status === "success") {
      return resData?.data;
    } else {
      return defaultError;
    }
  } catch (error) {
    console.log("[error] ", JSON.stringify(error));
    return defaultError;
  }
};

export default getPaymentDetails;
