import React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { Feather } from "@expo/vector-icons";

// Components
import Divider from "../atoms/Divider";

// Constants
import { colors, family } from "../../constants/theme";

const IconButton = ({ title, icon, onPress, color }) => {
  return (
    <View style={styles.container}>
      <TouchableOpacity style={styles.content} onPress={onPress}>
        <View style={styles.iconContainer}>
          <Feather name={icon} size={18} color={color || colors.primaryColor} />
        </View>
        <Divider width={5} />
        <Text style={[styles.title, color && { color: color }]}>{title}</Text>
      </TouchableOpacity>
    </View>
  );
};

export default IconButton;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  content: {
    flexDirection: "row",
    alignItems: "center",
  },
  iconContainer: { width: 30 },
  title: {
    fontFamily: family.normal,
    fontSize: 18,
    color: colors.brown,
  },
});
