import { variantsEqual } from "./variantsMethods";
import currencyFormatter from "currency-formatter";
// TODO: fix circular dependency
import { getCommissionsCost, getShopServiceCost } from "./orderMethods";

export const getProductsQty = (orders = []) => {
  let qty = 0;
  orders.map((shop) => {
    shop.order_lines.map((line) => {
      qty += line.quantity;
    });
  });
  return qty;
};

export const getProductVariantsCost = (variants = []) => {
  let result = 0;

  variants.map((variant) => {
    variant?.options.map((op) => {
      result = result + parseFloat(op.increment) * op.qty;
    });
  });

  return result;
};

export const getPastProductVariantsCost = (variants = []) => {
  let result = 0;
  if (variants !== null) {
    variants.map((variant) => {
      variant?.variants.map((op) => {
        if (op.amount !== null) {
          result = result + parseFloat(op.amount) * op.quantity;
        }
      });
    });
  }

  return result;
};

export const getProductPlatesCost = (plates = []) => {
  let result = 0;

  if (plates[0] !== null) {
    plates.map((plate) => {
      if (plate?.increment !== null) {
        result += plate.increment * plate?.quantity;
      }
      result += getProductVariantsCost(plate?.variants) * plate?.quantity;
    });
  }
  return result;
};

export const getPastProductPlatesCost = (plates = []) => {
  let result = 0;

  if (Array.isArray(plates) && plates?.length > 0) {
    plates.map((plate) => {
      result += plate?.product?.increment;
      result += getPastProductVariantsCost(plate?.variants);
    });
  }
  return result;
};

export const getSingleProductCost = (amount, variants = [], plates = []) => {
  var productPrice = parseFloat(amount);
  productPrice += getProductVariantsCost(variants);
  productPrice += getProductPlatesCost(plates);
  return productPrice;
};

export const getPastProductPrice = (product) => {
  var productPrice = parseFloat(product.amount);
  productPrice +=
    getPastProductVariantsCost(product?.groups) * product.quantity;
  productPrice +=
    getPastProductPlatesCost(product?.menu_selection) * product.quantity;
  return productPrice;
};
export const getProductsCost = (orders = []) => {
  let totalPrice = 0;
  orders.map((shop) => {
    shop.order_lines.map((line) => {
      totalPrice +=
        getSingleProductCost(line.amount, line?.variants, line?.plates) *
        line.quantity;
    });
  });
  return totalPrice;
};

export const getTotalServiceAmount = (services, orders) => {
  let amount = 0;
  orders.map((shop) => {
    amount += getShopServiceCost(services, shop);
  });
  return amount;
};

export const getTotalPrice = (cart) => {
  const { orders, discount, services } = cart;
  const commission = getCommissionsCost(orders);
  const productsPrice = getProductsCost(orders);
  const serviceAmount = getTotalServiceAmount(services, orders);
  const discountAmount = discount.discount.amount;
  if (productsPrice + serviceAmount + commission - discountAmount <= 0) {
    return 0;
  } else {
    return productsPrice + commission + serviceAmount - discountAmount;
  }
};

export const getTotalCost = ({ orders = [], increasers = [] }) => {
  let totalPrice = getProductsCost(orders);
  let increment = 0;
  increasers.map((inc) => {
    increment += inc;
  });
  if (totalPrice <= 0) {
    return 0;
  } else {
    totalPrice += increment;
  }

  if (totalPrice < 0) {
    return 0;
  } else {
    return totalPrice;
  }
};

export const ingredientsEqual = (first, second) => {
  if (
    (first && !second) ||
    (second && !first) ||
    first.length !== second.length
  ) {
    return false;
  } else if (!first && !second) {
    return true;
  } else {
    first.sort();
    second.sort();

    if (first.sort().join(",") === second.sort().join(",")) {
      return true;
    } else {
      return false;
    }
  }
};

export const productEqual = (first, second) => {
  if (first?.gid !== second?.gid) {
    return false;
  } else {
    if (ingredientsEqual(first?.ingredients, second?.ingredients) === true) {
      if (variantsEqual(first?.variants, second?.variants) === true) {
        return true;
      }
    }
  }
  return false;
};

export const ticketProductEqual = (first, second) => {
  if (first.id_product !== second.id_product) {
    return false;
  } else {
    if (ingredientsEqual(first?.ingredients, second?.ingredients) === true) {
      if (variantsEqual(first?.variants, second?.variants) === true) {
        return true;
      }
    }
  }
  return false;
};

export const getIngredientsText = (ingredients) => {
  let texts = [{ content: "Sin ", type: "bold" }];
  const qty = ingredients.length;

  ingredients.map((ing, index) => {
    if (index < qty && index !== 0) {
      texts.push({
        content: `\n`,
        type: "bold",
      });
      texts.push({ content: "Sin ", type: "bold" });
    }
    texts.push({ content: `${ing}`, type: "normal" });
  });
  return texts;
};

export const getFormattedPrice = (amount, currency = "EUR") => {
  const price = Math.floor(amount) / 100;
  const formatted = currencyFormatter.format(price, { code: currency });
  return formatted;
};

export const shopAmount = (shop) => {
  let amount = 0;
  shop.order_lines.map((line) => {
    amount +=
      getSingleProductCost(line.amount, line?.variants, line?.plates) *
      line.quantity;
  });
  return amount;
};

export const shopAmountById = (orders = [], shopId) => {
  let amount = 0;
  orders.map((shop) => {
    if (shop.id_shop === shopId) {
      amount += shopAmount(shop);
    }
  });
  return amount;
};

export const getTicketsPromoQuantity = (data = []) => {
  let qty = 0;
  data.map((el) => {
    qty += el.quantity;
  });
  return qty;
};

export const getTotalTicketDiscountPromo = ({
  type,
  discount,
  max_discount = 9999999,
  from_amount = -1,
  numProducts = 999999,
  totalPrice,
  data,
  gid,
}) => {
  let auxDiscount = 0;
  let discount_qty = 0;
  let discount_lines = [];

  let use = 0;
  if (numProducts > getTicketsPromoQuantity(data)) {
    use = getTicketsPromoQuantity(data);
  } else {
    use = numProducts;
  }
  data.map((el) => {
    for (let i = 0; i < el.quantity; i++) {
      if (
        auxDiscount < max_discount &&
        from_amount <= totalPrice &&
        discount_qty < use
      ) {
        let aux = 0;
        discount_qty++;

        if (type === "percentage") {
          aux = el.amount * (discount / 10000);
        } else if (type === "fixed") {
          if (discount > el.amount) {
            aux = el.amount;
          } else {
            aux = discount;
          }
        }

        if (auxDiscount + aux >= max_discount) {
          aux = max_discount - auxDiscount;
        }

        discount_lines.push({
          gid: el.gid,
          type: el.type,
          amount: Math.trunc(-aux),
          id_discount: gid,
        });
        auxDiscount += Math.trunc(aux);
        aux = 0;
      }
    }
  });

  if (auxDiscount > max_discount) {
    auxDiscount = max_discount;
  } else {
    auxDiscount = Math.trunc(auxDiscount);
  }

  return { auxDiscount, discount_lines };
};

export const menuEqual = (first, second) => {
  let result = true;
  first.plates.map((plate, index) => {
    if (!productEqual(plate, second.plates[index])) {
      result = false;
    }
  });
  return result;
};

export const islaterOrder = (orders) => {
  const hasLaterShops =
    orders.filter(
      (shop) => shop?.order_lines?.length > 0 && shop.hasLaterOrders
    ).length === 1;
  return hasLaterShops;
};
