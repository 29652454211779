import React from "react";
import { StyleSheet, View, Platform } from "react-native";

import CustomButton from "../../../../components/atoms/CustomButton";

const StepButton = ({ onPress, title, loading }) => {
  return (
    <View style={styles.actions}>
      <CustomButton title={title} onPress={onPress} active loading={loading} />
    </View>
  );
};

export default StepButton;

const styles = StyleSheet.create({
  actions: {
    width: "100%",
    paddingBottom: Platform.OS === "ios" ? 10 : 0,
    flexDirection: "row",
    justifyContent: "space-between",
  },
});
