import React from "react";
import { StyleSheet, Text, View } from "react-native";

// Components
import CustomModal from "../../../../../components/atoms/CustomModal";
import CustomButton from "../../../../../components/atoms/CustomButton"
import SchedulesContainer from "./components/SchedulesContainer";

// Constants
import { colors, family } from "../../../../../constants/theme";

const ScheduleModal = ({ visible, setModal, shop }) => {
  return (
    <CustomModal modalVisible={visible} handleModal={setModal}>
      <View style={styles.titleContainer}>
        <Text style={styles.title}>¿Cuándo quieres tu pedido?</Text>
      </View>
      <SchedulesContainer shop={shop} />
      <View style={styles.buttonContainer}>
        <CustomButton
          onPress={() => {
            setModal();
          }}
          title={`Aceptar`}
          color={colors.primaryColor}
          textColor={colors.white}
          active
        />
      </View>
    </CustomModal>
  );
};

export default ScheduleModal;

const styles = StyleSheet.create({
  titleContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginBottom: 20,
  },
  title: {
    fontFamily: family.bold,
    color: colors.primary,
    fontSize: 16,
  },
  buttonContainer: {
    width: "100%",
    marginTop: 30,
    height: 50,
  },
});
