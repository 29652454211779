export const getStatusText = (status) => {
  switch (status) {
    case "completed":
      return "Completado";
    case "cancelled":
      return "Cancelado";
    case "reimbursed_pending":
      return "Reembolso pendiente";
    case "reimbursed":
      return "Reembolsado";
    case "failed_reimbursement":
      return "Reembolso fallido";
    case "payment_cancelled":
      return "Cancelado en trámite de compra";
    case "failed":
      return "Fallido";
    case "ready_to_consume":
      return "Preventa";
    case "payment_rejected":
      return "Pago rechazado";
    default:
      null;
  }
};
