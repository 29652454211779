export const getPointSalesArray = (array, isPreSale, scheduled) => {
  let result = [];
  array.map((pointSale) => {
    const { schedules, opened } = pointSale;

    const variant1 = schedules.openToday && opened;
    const variant2 = !opened && isPreSale;
    const variant3 = schedules.openToday && !opened && !isPreSale && scheduled;

    const available = variant1 || variant2 || variant3;

    if (available) {
      return result.push(pointSale.gid);
    }
  });
  return result;
};
