import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { connect } from "react-redux";
import { family } from "../../../../../../constants/theme";
import { getCommissionsCost } from "../../../../../../utils/orderMethods";
import { getFormattedPrice } from "../../../../../../utils/productMethods";

const CommissionLine = ({ orders, customStyle }) => {
  const { textColor } = customStyle;
  const textStyle = [styles.text, { color: textColor }];

  const commissionCost = () => {
    let amount = getCommissionsCost(orders);
    return amount > 0 ? getFormattedPrice(amount) : "GRATIS";
  };
  return (
    <View style={styles.container}>
      <Text style={textStyle}>Gastos de gestión</Text>
      <Text style={textStyle}>{commissionCost()}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    justifyContent: "space-between",
    marginBottom: 5,
    flexDirection: "row",
  },
  text: {
    fontFamily: family.normal,
    fontSize: 12,
  },
});

const mapStateToProps = (state) => {
  return {
    orders: state.cart.orders,
  };
};
export default connect(mapStateToProps)(CommissionLine);
