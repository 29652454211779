import { baseVersion } from "../../../config";
import { URlManagement } from "../../constants/urls";
import { FETCH_SHOP_BY_ID,FETCH_ALL_SHOPS } from "./shopTypes";

export const fetchShopById = ({ id, service, locId }) => {
  return async (dispatch) => {
    try {
      const url =
        service === "my_site"
          ? `${URlManagement}/shop/get/${id.toString()}?location=${locId}&service=my_site`
          : `${URlManagement}/shop/get/${id.toString()}?service=${service}`;

      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Connection: "keep-alive",
          "X-Client-Version": baseVersion,
        },
        method: "GET",
      });
      const resData = await response.json();
      const data = resData.message === "success" ? resData.data[0] : [];
      dispatch({
        type: FETCH_SHOP_BY_ID,
        payload: data,
      });
      return data;
    } catch (err) {
      console.log("error fetching shop data: ", err);
    }
  };
};


export const fetchAllShops = () => {
  return async (dispatch) => {
    try {
      const url = `${URlManagement}/shop/getAll`;
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Connection: "keep-alive",
          "X-Client-Version": baseVersion,
        },
        method: "GET",
      });

      const resData = await response.json();

      dispatch({
        type: FETCH_ALL_SHOPS,
        payload: resData,
      });
    } catch (err) {
      console.log("error fetching event types: ", err);
    }
  };
};
