import React, { useState, useEffect } from "react";
import { StyleSheet, View, ActivityIndicator, Text } from "react-native";
import { connect } from "react-redux";

// Components
import ScheduleButton from "../../../events/ConfirmOrderScreen/sections/ScheduleSection/ScheduleButton";
import Divider from "../../../../components/atoms/Divider";
import ScheduleModal from "../modals/ScheduleModal";

// Constants
import { colors, family } from "../../../../constants/theme";

// Methods
import { getShopSchedules } from "../methods/getShopSchedules";

// Redux
import {
  setDate,
  setOrderType,
} from "../../../../redux/laterCart/laterCartActions";

// Utils
import { fetchShopData } from "../../../../utils/shop/fetchShopData";

const ScheduleSection = ({
  orders,
  setOrderType,
  orderType,
  date,
  setDate,
}) => {
  const [modal, setModal] = useState();
  const [status, setStatus] = useState("idle");
  const [shopData, setShopData] = useState();
  const [error, setError] = useState();

  const setUpSchedules = async () => {
    setStatus("loading");
    try {
      const response = await fetchShopData(orders[0].shopId);
      const { data, status, message } = response;
      if (status === "fail") {
        setStatus("error");
        setError(message);
      } else {
        setStatus("success");
        setShopData(data);
      }
    } catch (error) {
      console.log("error getting schedules");
    }
  };

  const canASAP = shopData?.orderTypes.includes("ASAP") && shopData.opened;
  const canSchedule =
    shopData?.orderTypes.includes("Scheduled") && shopData.schedules.openToday;
  const noOrderTypes =
    shopData?.orderTypes.filter((item) => item !== "Later").length === 0;

  useEffect(() => {
    setUpSchedules();
    if (canASAP && !orderType) {
      setOrderType("ASAP");
      setDate(null);
    }
  }, [orders]);

  const asapHandler = () => {
    setOrderType("ASAP");
    setDate(null);
  };

  const scheduleHandler = () => {
    setModal("Schedule");
  };

  if (status === "loading" || status === "idle") {
    return <ActivityIndicator size="small" color={colors.secondary} />;
  }
  if (status === "error") {
    return <Text style={styles.error}>{error}</Text>;
  }

  if (status === "success" && noOrderTypes) {
    return (
      <Text style={styles.error}>
        Esta tienda no cuenta con tipos de entrega
      </Text>
    );
  }
  return (
    <>
      <View style={styles.container}>
        <View style={styles.row}>
          {canASAP && (
            <ScheduleButton
              title={"Lo antes posible"}
              active={orderType === "ASAP"}
              onPress={asapHandler}
            />
          )}
          {canSchedule && <Divider width={10} />}
          {canSchedule && (
            <ScheduleButton
              title={"Programar pedido"}
              subtitle={getShopSchedules(date)}
              active={orderType === "Scheduled"}
              onPress={scheduleHandler}
            />
          )}
        </View>
      </View>
      <ScheduleModal
        visible={modal === "Schedule"}
        setModal={setModal}
        shop={shopData}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    orderType: state.laterOrders.orderType,
    orders: state.laterOrders.orders,
    date: state.laterOrders.date,
  };
};
const mapDispatchToProps = {
  setOrderType,
  setDate,
};

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    width: "100%",
  },
  error: {
    fontFamily: family.normal,
    color: colors.browm,
    fontSize: 14,
    textAlign: "center",
  },
  container: {
    height: 60,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    paddingHorizontal: 18,
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleSection);
