import React, { useContext, useState, useEffect } from "react";
import { ScrollView } from "react-native";
import { connect } from "react-redux";

import EmptyScreen from "../components/EmptyScreen";
import ErrorScreen from "../components/ErrorScreen";
import LoadingScreen from "../components/LoadingScreen";
import Divider from "../../../../components/atoms/Divider";

import CompletedOrder from "../../../../components/organisms/completedOrder/CompletedOrder";

// Context
import { AuthContext } from "../../../../contexts/AuthContext";

// Redux
import { fetchCompletedOrders } from "../../../../redux/orders/ordersActions";

const CompletedOrders = ({
  fetchCompletedOrders,
  completedOrders,
  navigation,
  userGid,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const { user: firebaseUser } = useContext(AuthContext);

  const getData = async () => {
    setLoading(true);
    try {
      const token = await firebaseUser.getIdToken(true);
      await fetchCompletedOrders(userGid, token);
    } catch (err) {
      console.log("error fetching completed orders");
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  if (error) {
    return <ErrorScreen error={error} />;
  }

  if (completedOrders.length === 0) {
    return <EmptyScreen text="¡No has completado ningún pedido todavía!" />;
  }

  return (
    <ScrollView style={{ height: 1 }} showsVerticalScrollIndicator={false}>
      {completedOrders.map((order) => (
        <CompletedOrder key={order.gid} data={order} navigation={navigation} />
      ))}
      <Divider height={100} />
    </ScrollView>
  );
};

const mapDispatchToProps = {
  fetchCompletedOrders,
};

const mapStateToProps = (state) => {
  return {
    completedOrders: state.orders.completedOrders,
    userGid: state.user.user.gid,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompletedOrders);
