import React, { useState, useContext } from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";

// Components
import CustomButton from "../../../../components/atoms/CustomButton";
import WarningModal from "../../../../components/organisms/modals/WarningModal";

// Redux
import { resetCart } from "../../../../redux/laterCart/laterCartActions";

// Methods
import { getLaterOrder } from "../methods/getLaterOrder";
import { postLaterOrder } from "../methods/postLaterOrder";

import { AuthContext } from "../../../../contexts/AuthContext";

const ConfirmButton = ({ navigation, cart, resetCart }) => {
  const { user: firebaseUser } = useContext(AuthContext);
  const [modal, setModal] = useState(false);
  const [error, setError] = useState();
  const buttonHandler = async () => {
    if (!cart.orderType) {
      setModal("Warning");
      setError("Debes seleccionar tipo de entrega");
    } else {
      const object = getLaterOrder(cart);
      const token = await firebaseUser.getIdToken(true);
      const response = await postLaterOrder(object, token);
      if (response.status === "success") {
        resetCart();
        navigation.navigate("PaymentSuccess");
      } else {
        setError(
          `No se pudo realizar el pedido.\nInténtalo más tarde\n${response.data}`
        );
        setModal("Warning");
      }
    }
  };

  return (
    <View style={styles.container}>
      <CustomButton
        onPress={buttonHandler}
        title="Finalizar"
        active={cart?.orders?.length > 0}
      />
      <WarningModal
        visible={modal === "Warning"}
        setModal={setModal}
        text={error}
      />
    </View>
  );
};

const mapDispatchToProps = {
  resetCart,
};

const mapStateToProps = (state) => {
  return {
    cart: state.laterOrders,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmButton);

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: 100,
    paddingHorizontal: 30,
  },
});
