export const compareWaitTimes = (orders) => {
  const timesArray = orders?.map((order) => order?.wait_time);

  const timeSplit = timesArray?.reduce(
    (max, item) => {
      const time = parseInt(item?.split("-")[1]);
      return time > max.time ? { time, range: item } : max;
    },
    { time: 0, range: "" }
  );

  return timeSplit?.range;
};
