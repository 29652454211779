import React, { useState } from "react";
import { StyleSheet, Text, View, Image, TouchableOpacity } from "react-native";
import { connect } from "react-redux";
import { Feather } from "@expo/vector-icons";

// Components
import WarningModal from "../../../../components/organisms/modals/WarningModal";
import RemoveProduct from "../../../../components/product/RemoveProduct";
import Tag from "../../../../components/organisms/pastOrder/Tag";
import Divider from "../../../../components/atoms/Divider";
import ActionButton from "./ActionButton";

// Constants
import { colors, family } from "../../../../constants/theme";

// Redux
import { deleteProduct } from "../../../../redux/laterCart/laterCartActions";
import { addProduct } from "../../../../redux/laterCart/laterCartActions";
import { canConsume } from "../methods/canConsume";

const ForLaterProduct = ({
  data,
  shop,
  shopData,
  orders,
  psData,
  addProduct,
  deleteProduct,
}) => {
  const {
    name,
    description,
    quantityAvailable,
    product,
    pointSale,
    internalId,
  } = data;

  console.log("data", data);

  const [modal, setModal] = useState();
  let selectedProduct = orders
    ?.filter((order) => order.shopId === shop.gid)[0]
    ?.orderLines.filter((line) => line.internalId === internalId)[0];

  if (!selectedProduct) {
    selectedProduct = {
      quantity: 0,
    };
  }

  let currentPs;

  if (Array.isArray(psData)) {
    currentPs = psData?.filter((ps) => ps.gid === pointSale.gid)[0];
  } else {
    if (psData.gid === pointSale.gid) {
      currentPs = psData;
    }
  }

  let schedulesAvailable;
  let hourText;
  let opened = false;
  let tagText;

  if (currentPs !== undefined) {
    schedulesAvailable =
      currentPs?.schedules !== undefined &&
      currentPs?.schedules !== null &&
      !Array.isArray(currentPs?.schedules);

    hourText = currentPs?.schedules?.hours
      .map((schedule) => schedule + " ")
      .join(" / ");
    opened = canConsume(currentPs, shopData);

    tagText = schedulesAvailable
      ? `${
          currentPs?.schedules?.openToday
            ? hourText
            : currentPs?.schedules?.day + " " + hourText
        }`
      : "No disponible";
  } else {
    tagText = "No disponible";
  }

  const onAdd = () => {
    const currentShop = orders[0];
    if (currentShop === undefined || shop.gid === currentShop?.shopId) {
      if (opened && selectedProduct?.quantity < quantityAvailable) {
        addProduct({ ...data, shop: shop });
      }
    } else {
      setModal("Warning");
    }
  };

  const onRemove = () => {
    deleteProduct({ shopId: shop.gid, internalId: internalId });
  };

  return (
    <TouchableOpacity style={styles.container} onPress={onAdd}>
      <RemoveProduct
        showActions={true}
        quantity={selectedProduct?.quantity || 0}
        onPress={onRemove}
      />
      {product?.image && (
        <Image style={styles.image} source={{ uri: product?.image }} />
      )}
      <View style={styles.info}>
        <Text style={styles.title}>{product?.name || name}</Text>
        {opened && (
          <Text numberOfLines={2} style={styles.description}>
            {product?.description}
          </Text>
        )}
        {!opened && (
          <View style={styles.row}>
            <Tag
              bgColor={colors.lightPrimary}
              textColor={colors.white}
              text={tagText}
            />
          </View>
        )}
      </View>
      {quantityAvailable - selectedProduct?.quantity > 0 && (
        <View style={styles.actionContainer}>
          <View style={styles.row}>
            <Text style={styles.text}>
              {quantityAvailable - selectedProduct?.quantity > 1
                ? "Quedan"
                : "Queda"}
            </Text>
            <Divider width={5} />
            <Text style={[styles.title, { fontSize: 16 }]}>
              {quantityAvailable - selectedProduct?.quantity}
            </Text>
          </View>

          {opened && (
            <ActionButton color={colors.lightenGreen} onPress={onAdd}>
              <Feather name="plus" size={20} color={colors.lightGreen} />
            </ActionButton>
          )}
        </View>
      )}
      <WarningModal
        visible={modal === "Warning"}
        setModal={setModal}
        text={`Sólo se pueden añadir productos de una tienda en cada pedido.\nRevisa tu carrito`}
      />
    </TouchableOpacity>
  );
};

const mapStateToProps = (state) => {
  return {
    orders: state.laterOrders.orders,
  };
};

const mapDispatchToProps = {
  addProduct,
  deleteProduct,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForLaterProduct);

const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexDirection: "row",
    height: 60,
  },
  image: {
    resizeMode: "cover",
    width: 60,
    height: 60,
    borderRadius: 8,
  },
  info: {
    marginLeft: 10,
    flexDirection: "column",
    flex: 1,
    justifyContent: "space-between",
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    fontFamily: family.bold,
    fontSize: 14,
    color: colors.brown,
  },
  text: {
    fontFamily: family.normal,
    fontSize: 12,
    color: colors.brown,
  },
  description: {
    fontFamily: family.normal,
    fontSize: 12,
    color: colors.brown,
  },
  actionContainer: {
    flexDirection: "column",
    width: 80,
    justifyContent: "space-between",
    alignItems: "center",
  },
});
