import React from "react";
import { StyleSheet, Text, TouchableOpacity } from "react-native";
import { Entypo } from "@expo/vector-icons";

// Components
import Divider from "../../../../../components/atoms/Divider";

// Constants
import { colors, family } from "../../../../../constants/theme";

const Title = ({ selected, setSection }) => {
  const clickHandler = () => {
    if (!selected) {
      setSection(0);
    }
  };
  return (
    <TouchableOpacity style={styles.container} onPress={clickHandler}>
      {!selected && (
        <Entypo name="chevron-left" size={20} color={colors.brown} />
      )}
      {!selected && <Divider width={5} />}
      <Text style={styles.text}>Tus pedidos</Text>
    </TouchableOpacity>
  );
};

export default Title;

const styles = StyleSheet.create({
  container: {
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
  },
  text: {
    fontFamily: family.bold,
    fontSize: 20,
    color: colors.brown,
  },
});
