import React from "react";
import { Text, useWindowDimensions, View,Platform } from "react-native";
import { Feather } from "@expo/vector-icons";
import StyleSheet from "react-native-media-query";

// Components
import Divider from "../../../../components/atoms/Divider";

// Constants
import { colors, family } from "../../../../constants/theme";

const ScheduleTag = () => {
  const { height, width } = useWindowDimensions();
  const isSmallScreen = width < 340 || height < 700;
  return (
    <View style={styles.tag} dataSet={{ media: ids.tag }}>
      <Feather
        name="calendar"
        size={isSmallScreen ? 10 : 12}
        color={colors.brown}
      />
      <Divider width={5} />
      <Text style={styles.text} dataSet={{ media: ids.text }}>
        Programar pedido
      </Text>
    </View>
  );
};

export default ScheduleTag;

const { ids, styles } = StyleSheet.create({
  tag: {
    borderRadius: 8,
    backgroundColor: colors.white,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    flexShrink: 1,
    flexGrow: 0,
    paddingVertical: 3,
    paddingHorizontal: 6,
  },
  text: {
    fontFamily: family.semibold,
    fontSize: 15,
    color: colors.brown,
  },
});
