export const isShopOpened = (shop) => {
  const { schedules, opened, services, orderTypes } = shop;
  const isPreSale = services.some((service) => service.service_id === 4);
  const scheduled = orderTypes.includes("Scheduled");

  const variant1 = schedules.openToday && opened;
  const variant2 = !opened && isPreSale;
  const variant3 = schedules.openToday && !opened && !isPreSale && scheduled;

  return variant1 || variant2 || variant3;
};
