import React from "react";
import { TouchableOpacity, StyleSheet } from "react-native";

// Components
import EventImage from "./EventImage";

const Wrapper = ({ onPress, image, children }) => {
  return (
    <TouchableOpacity style={styles.wrapper} onPress={onPress}>
      <EventImage image={image} />
      {children}
    </TouchableOpacity>
  );
};

export default Wrapper;

const styles = StyleSheet.create({
  wrapper: {
    height: 200,
    width: 280,
    borderRadius: 15,
  },
});
