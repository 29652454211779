import React from "react";
import { StyleSheet, Text, View, TouchableOpacity } from "react-native";
import { colors, family } from "../../../../constants/theme";

const NoLocationScreen = ({ onPress }) => {
  return (
    <View style={styles.screen}>
      <Text style={styles.text}>No has seleccionado tu sitio</Text>
      <TouchableOpacity onPress={onPress}>
        <Text style={styles.bold}>Debes elegir una localización</Text>
      </TouchableOpacity>
    </View>
  );
};

export default NoLocationScreen;

const styles = StyleSheet.create({
  bold: {
    fontSize: 18,
    color: colors.secondary,
    fontFamily: family.bold,
    textAlign: "center",
  },
  text: {
    fontSize: 18,
    color: colors.brown,
    fontFamily: family.normal,
    textAlign: "center",
  },
  screen: {
    width: "100%",
    flex: 1,
    paddingHorizontal: 18,
    justifyContent: "center",
    alignItems: "center",
  },
});
