import {
  LATER_ADD_PRODUCT,
  LATER_DELETE_PRODUCT,
  LATER_INCREMENT_PRODUCT,
  LATER_SET_ORDER_TYPE,
  LATER_SET_COMMENT,
  LATER_SET_DATE,
  LATER_SET_SERVICE,
  LATER_SET_EVENT,
  LATER_RESET_CART,
} from "./laterCartTypes";

const initialState = {
  orders: [],
  comment: "",
  date: null,
  services: [],
  orderType: "ASAP",
  eventId: null,
  paymentMethodId: 1,
};

const laterCartReducer = (state = initialState, action) => {
  switch (action.type) {
    case LATER_ADD_PRODUCT: {
      const auxOrders = [...state.orders];
      const { product, shop } = action.payload;

      const shopIndex = auxOrders.findIndex(
        (order) => order.shopId === shop.gid
      );

      if (shopIndex === -1) {
        auxOrders.push({
          shopId: shop.gid,
          shopName: shop.name,
          orderLines: [product],
        });
      } else {
        const lineIndex = auxOrders[shopIndex].orderLines.findIndex(
          (line) => line.internalId === product.internalId
        );
        if (lineIndex === -1) {
          auxOrders[shopIndex].orderLines.push(product);
        } else {
          auxOrders[shopIndex].orderLines[lineIndex].quantity += 1;
        }
      }
      return { ...state, orders: auxOrders };
    }
    case LATER_INCREMENT_PRODUCT: {
      const auxOrders = [...state.orders];
      const { shopId, internalId } = action.payload;

      const shopIndex = auxOrders.findIndex((order) => order.shopId === shopId);

      const lineIndex = auxOrders[shopIndex].orderLines.findIndex(
        (line) => line.internalId === internalId
      );
      if (shopIndex !== -1 && lineIndex !== -1) {
        auxOrders[shopIndex].orderLines[lineIndex].quantity += 1;
      }

      return { ...state, orders: auxOrders };
    }
    case LATER_DELETE_PRODUCT: {
      const auxOrders = [...state.orders];
      const { shopId, internalId } = action.payload;

      const shopIndex = auxOrders.findIndex((order) => order.shopId === shopId);

      const lineIndex = auxOrders[shopIndex].orderLines.findIndex(
        (line) => line.internalId === internalId
      );
      const quantity = auxOrders[shopIndex].orderLines[lineIndex].quantity;
      if (quantity > 1) {
        auxOrders[shopIndex].orderLines[lineIndex].quantity -= 1;
      } else {
        auxOrders[shopIndex].orderLines.splice(lineIndex, 1);

        if (auxOrders[shopIndex].orderLines.length === 0) {
          auxOrders.splice(shopIndex, 1);
        }
      }

      return { ...state, orders: auxOrders };
    }
    case LATER_SET_ORDER_TYPE: {
      return { ...state, orderType: action.payload };
    }

    case LATER_SET_COMMENT: {
      return { ...state, comment: action.payload };
    }

    case LATER_SET_DATE: {
      return { ...state, date: action.payload };
    }

    case LATER_SET_SERVICE: {
      const {
        service,
        locationId = null,
        location = null,
        shopId,
      } = action.payload;

      const auxService = { ...service, locationId, location, shopId };
      let auxServices = state.services;
      let found = false;

      for (let i = 0; i < auxServices.length; i++) {
        if (shopId === auxServices[i].shopId) {
          auxServices[i] = auxService;
          found = true;
          break;
        }
      }

      if (!found) {
        auxServices.push(auxService);
      }

      return {
        ...state,
        services: [...auxServices],
      };
    }

    case LATER_SET_EVENT: {
      return { ...state, eventId: action.payload };
    }

    case LATER_RESET_CART: {
      return {
        orders: [],
        comment: "",
        date: null,
        services: [],
        orderType: "ASAP",
        eventId: null,
        paymentMethodId: 1,
      };
    }

    default:
      return state;
  }
};

export default laterCartReducer;
