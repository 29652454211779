import React from "react";
import { StyleSheet, View } from "react-native";

// Components
import ShortDescription from "./ShortDescription";
import Divider from "../../components/atoms/Divider";
import ProductName from "./ProductName";
import Allergens from "./Allergens";
import AddProduct from "./AddProduct";

const InfoContainer = ({ data, customStyle, onAdd, showAddButton }) => {
  const { name, short_description, allergens, amount, increment } = data;

  const price =
    increment === 0
      ? 0
      : increment > 0
      ? increment
      : increment === undefined || increment === null
      ? amount
      : null;

  return (
    <View style={styles.infoContainer}>
      <View style={styles.infoColumn}>
        <ProductName name={name} color={customStyle?.title1} />
        <Divider height={5} />
        <ShortDescription
          description={short_description}
          color={customStyle?.letter1}
        />
        <Divider height={5} />
        <Allergens allergens={allergens} />
      </View>
      <AddProduct
        amount={price}
        textColor={customStyle?.letter1}
        showButton={showAddButton}
        onAdd={onAdd}
      />
    </View>
  );
};

export default InfoContainer;

const styles = StyleSheet.create({
  infoContainer: {
    flex: 1,
    justifyContent: "space-between",
    flexDirection: "row",
  },
  infoColumn: {
    flexDirection: "column",
    flex: 1,
  },
});
