import React, { useState } from "react";
import { View } from "react-native";
import StyleSheet from "react-native-media-query";

// Sections
import RegisterSection from "./sections/RegisterSection";
import TermsSection from "./sections/TermsSection";
import LoginSection from "./sections/LoginSection";
import ForgotPasswordSection from "./sections/ForgotPasswordSection";
import PrivacySection from "./sections/PrivacySection";
import ReactivateAccount from "./sections/ReactivateAccount";

// Constants
import { colors } from "../../../constants/theme";

const AuthContainer = ({ customStyle, onAuth }) => {
  const [step, setStep] = useState(0);

  const sectionProps = {
    setStep: setStep,
    step: step,
    onAuth: onAuth,
    customStyle: {
      backgroundColor: customStyle?.backgroundColor || colors.primaryColor,
      buttonTextColor: customStyle?.buttonTextColor || colors.white,
      textColor: customStyle?.textColor || colors.brown,
      checkColor: customStyle?.checkColor || colors.brown,
      errorColor: customStyle?.textColor || colors.primaryColor,
    },
  };

  return (
    <View style={styles.container} dataSet={{ media: ids.container }}>
      {step === 0 && <LoginSection sectionProps={sectionProps} />}
      {step === 1 && <RegisterSection sectionProps={sectionProps} />}
      {step === 2 && <TermsSection sectionProps={sectionProps} />}
      {step === 3 && <PrivacySection sectionProps={sectionProps} />}
      {step === 4 && <ForgotPasswordSection sectionProps={sectionProps} />}
      {step === 5 && <ReactivateAccount sectionProps={sectionProps} />}
    </View>
  );
};

export default AuthContainer;

const { ids, styles } = StyleSheet.create({
  container: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 10,
    paddingHorizontal: 18,
    "@media (max-width: 320px)": {
      paddingHorizontal: 10,
    },
  },
});
