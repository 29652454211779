import React from "react";

// React Navigation
import { createAppContainer, createSwitchNavigator } from "react-navigation";
import { createStackNavigator } from "@react-navigation/stack";

// Navigation Components
import TicketsShopScreen from "../screens/tickets/TicketsShopScreen";
import TicketDetailScreen from "../screens/tickets/TicketDetailScreen";
import NotFound from "../screens/tickets/NotFound";
import TicketTermsScreen from "../screens/tickets/TicketTermsScreen";
import TicketPrivacyScreen from "../screens/tickets/TicketPrivacyScreen";
import TicketsPaymentSuccess from "../screens/tickets/TicketsPaymentSuccess";
import TicketsPaymentError from "../screens/tickets/TicketsPaymentError";

const Stack = createStackNavigator();

const AppNavigation = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
        title: "Gopick",
      }}
    >
      <Stack.Screen
        name="TicketsPaymentSuccess"
        component={TicketsPaymentSuccess}
      />
      <Stack.Screen
        name="TicketsPaymentError"
        component={TicketsPaymentError}
      />
      <Stack.Screen name="Tickets" component={TicketsShopScreen} />
      <Stack.Screen name="TicketDetailScreen" component={TicketDetailScreen} />
      <Stack.Screen name="NotFound" component={NotFound} />
      <Stack.Screen name="TicketTermsScreen" component={TicketTermsScreen} />
      <Stack.Screen
        name="TicketPrivacyScreen"
        component={TicketPrivacyScreen}
      />
    </Stack.Navigator>
  );
};

const TicketsNavigation = createSwitchNavigator({
  App: AppNavigation,
});

const TicketNavigator = createAppContainer(TicketsNavigation);

export default TicketNavigator;
