import React from "react";
import { Text, View } from "react-native";
import StyleSheet from "react-native-media-query";

// Constants
import { colors, family } from "../../../../constants/theme";

const WaitTimeTag = ({ waitTime }) => {
  return (
    <View style={styles.tag} dataSet={{ media: ids.tag }}>
      <Text style={styles.text} dataSet={{ media: ids.text }}>
        {waitTime}
      </Text>
    </View>
  );
};

export default WaitTimeTag;

const { ids, styles } = StyleSheet.create({
  tag: {
    top: 40,
    right: 20,
    zIndex:3,
    position: "absolute",
    borderRadius: 8,
    backgroundColor: colors.accentColor,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    flexShrink: 1,
    flexGrow: 0,
    paddingVertical: 3,
    paddingHorizontal: 6,
    "@media (max-height: 700px) or (max-width: 340px)": {
      borderRadius: 6,
    },
  },
  text: {
    fontFamily: family.semibold,
    fontSize: 15,
    color: colors.white,
    "@media (max-height: 700px) or (max-width: 340px)": {
      fontSize: 10,
    },
  },
});
