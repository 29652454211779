import React from "react";
import { Platform, StyleSheet, View } from "react-native";

// Components
import List from "../../../../../components/atoms/List";
import Tag from "../../../../../components/atoms/Tag";

// Constants
import { colors } from "../../../../../constants/theme";

const CategoriesCarousel = ({
  categoriesHandler,
  categoriesList,
  selectedCategory,
  listRef,
  shopData,
  ...props
}) => {
  const custom_color_2 = shopData?.custom_design?.custom_color_2 || colors.brown;
  const custom_color_letter_2 =
    shopData?.custom_design?.custom_color_letter_2 || colors.white;

  const tagHandler = (item) => {
    let auxIndex = -1;
    categoriesList.map((cat, index) => {
      if (cat.gid === item.gid) {
        auxIndex = index;
      }
    });
    categoriesHandler(auxIndex);
  };

  return (
    <View style={styles.container}>
      <List
        {...props}
        listRef={listRef}
        horizontal={true}
        data={categoriesList}
        showsHorizontalScrollIndicator={false}
        initialScrollIndex={0}
        onScrollToIndexFailed={(info) => {
          const wait = new Promise((resolve) => setTimeout(resolve, 500));
          wait.then(() => {
            listRef.current?.scrollToIndex({
              index: info.index,
              animated: true,
            });
          });
        }}
        renderItem={(itemData) => (
          <Tag
            backgroundColor={custom_color_2}
            textColor={custom_color_letter_2}
            name={itemData.item.name}
            key={itemData.item.gid}
            selected={
              selectedCategory === categoriesList.indexOf(itemData.item)
            }
            onPress={() => tagHandler(itemData.item)}
          />
        )}
      />
    </View>
  );
};

export default CategoriesCarousel;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    paddingTop: 10,
    overflow: "hidden",
    height: 60,
    maxWidth: Platform.OS === "web" ? 400 : null,
  },
});
