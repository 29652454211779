import React from "react";
import StyleSheet from "react-native-media-query";
import { Feather } from "@expo/vector-icons";
import { Text, View } from "react-native";

// Components
import Divider from "../atoms/Divider";
import ActionButton from "./ActionButton";

// Constants
import { colors, family } from "../../constants/theme";

const RemoveProductStatic = ({
  quantity,
  onPress,
  buttonSize = 25,
  iconSize = 20,
  customStyle,
}) => {
  return (
    <View style={styles.actions}>
      <Text
        dataSet={{ media: ids.qtyText }}
        style={[styles.qtyText, { color: customStyle?.letter1 }]}
      >
        {quantity}x
      </Text>
      <Divider height={5} />
      <ActionButton color={colors.lightRed} onPress={onPress} size={buttonSize}>
        <Feather
          name={quantity > 1 ? "minus" : "trash-2"}
          size={quantity > 1 ? iconSize : iconSize - 4}
          color={colors.red}
        />
      </ActionButton>
    </View>
  );
};

export default RemoveProductStatic;

const { ids, styles } = StyleSheet.create({
  actions: {
    height: "100%",
    minWidth: 30,
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    overflow: "hidden",
  },
  qtyText: {
    fontSize: 14,
    fontFamily: family.bold,
  },
});
