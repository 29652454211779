import React from "react";
import { StyleSheet, Text, TouchableOpacity } from "react-native";
import { Feather } from "@expo/vector-icons";

// Components
import Divider from "../../../../../components/atoms/Divider";

// Constants
import { colors, family } from "../../../../../constants/theme";

const CompletedButton = ({ selected, setSection }) => {
  const iconColor = selected ? colors.white : colors.secondary;

  const clickHandler = () => {
    if (!selected) {
      setSection(1);
    }
  };
  return (
    <TouchableOpacity
      style={[styles.container, selected && styles.selectedContainer]}
      onPress={clickHandler}
    >
      <Feather name="shopping-bag" size={14} color={iconColor} />
      <Divider width={5} />
      <Text style={[styles.text, selected && styles.selectedText]}>
        Completados
      </Text>
    </TouchableOpacity>
  );
};

export default CompletedButton;

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderColor: colors.secondary,
    paddingHorizontal: 8,
    paddingVertical: 4,
    flexDirection: "row",
    borderRadius: 50,
    alignItems: "center",
  },
  text: {
    fontFamily: family.normal,
    fontSize: 16,
    color: colors.secondary,
  },
  selectedContainer: {
    backgroundColor: colors.secondary,
  },
  selectedText: {
    color: colors.white,
  },
});
