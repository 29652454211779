import { URLbase } from "../../constants/urls";
import { generateLaterArray } from "./methods/generateLaterArray";
import {
  FETCH_ORDERS,
  UPDATE_ORDER,
  FETCH_LATER_ORDERS,
  FETCH_COMPLETED_ORDERS,
} from "./ordersTypes";
import { commonHeaders } from "../../services/backend";

export const fetchPastOrders = (userId, token) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${URLbase}/v2/order?userId=${userId}&statuses=scheduled&statuses=waiting&statuses=processing&statuses=await_pickup&statuses=await_shipment&statuses=partially_delivered&statuses=shipped&sortBy=desc`,
        {
          headers: commonHeaders(token),
          method: "GET",
        }
      );

      const resData = await response.json();
      const data = resData.status === "success" ? resData.data : [];
      const finalData = data.filter((el) => el.orderType !== "Later");

      if (resData.status === "fail") {
        return { code: resData.code, message: resData.message };
      }
      dispatch({
        type: FETCH_ORDERS,
        payload: finalData,
      });
    } catch (err) {
      console.log("error fetching past orders: ", err);
      return { code: 403, message: err.message };
    }
  };
};

export const fetchCompletedOrders = (userId, token) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${URLbase}/v2/order?userId=${userId}&statuses=completed&statuses=ready_to_consume&statuses=cancelled&statuses=reimbursed_pending&statuses=reimbursed&statuses=failed_reimbursement&order=desc`,
        {
          headers: commonHeaders(token),
          method: "GET",
        }
      );

      const resData = await response.json();
      const data = resData.status === "success" ? resData.data : [];
      dispatch({
        type: FETCH_COMPLETED_ORDERS,
        payload: data,
      });
    } catch (err) {
      console.log("error fetching past orders: ", err);
    }
  };
};

export const fetchLaterOrders = (userId, token) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${URLbase}/v2/order?userId=${userId}&statuses=ready_to_consume`,
        {
          headers: commonHeaders(token),
          method: "GET",
        }
      );

      const resData = await response.json();
      const data = resData.status === "success" ? resData.data : [];
      const finalArray = generateLaterArray(data);

      dispatch({
        type: FETCH_LATER_ORDERS,
        payload: finalArray,
      });
    } catch (err) {
      console.log("error fetching later orders: ", err);
    }
  };
};

export const updateOrder = (result) => {
  return (dispatch) => {
    if (result.status === "success") {
      dispatch({
        type: UPDATE_ORDER,
        payload: result.data,
      });
    }
  };
};
