import React from "react";
import { connect } from "react-redux";

// Components
import AddedProduct from "../../../../../../components/product/AddedProduct";
import Product from "../../../../../../components/product/Product";

// Redux
import { incrementProduct, decrementProduct } from "../../../../../../redux";

const MenuProduct = ({ props, incrementProduct, decrementProduct }) => {
  const {
    data,
    shopData,
    setModal,
    orderProductList,
    navigation,
    customStyle,
    isCompatibleType,
    isOnEvent,
  } = props;
  //todo el shop q se envia al addProduct puede ser el fk_shop del data

  const addHandler = () => {
    if (!isCompatibleType) {
      setModal("OutOfLater");
    } else if (!isOnEvent) {
      setModal("OutOfEvent");
    } else if (
      data?.stock === null ||
      data?.stock === undefined ||
      data?.stock > 0
    ) {
      navigation.navigate("EventsStack", {
        screen: "MenuSelectorScreen",
        params: {
          data: data,
          shopData: shopData,
        },
      });
    } else {
      setModal("Max");
    }
  };

  const addFormedProduct = (internal_id) => {
    incrementProduct(internal_id);
  };

  return (
    <>
      <Product
        data={data}
        onPress={addHandler}
        showActions={false}
        customStyle={customStyle}
      />
      {orderProductList.map((product) => (
        <AddedProduct
          customStyle={customStyle}
          onPress={() => addFormedProduct(product.internal_id)}
          key={product.internal_id}
          data={product}
          startAmount={data.amount}
          quantity={product.quantity}
          onAction={() => decrementProduct(product.internal_id)}
        />
      ))}
    </>
  );
};

const mapDispatchToProps = {
  incrementProduct,
  decrementProduct,
};

export default connect(null, mapDispatchToProps)(MenuProduct);
