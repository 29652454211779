import { Platform } from "react-native";

export const getLocationText = (locations, selectedLocation) => {
  let opcionesSelAux = [];
  let auxText = "";

  locations.map(function (localizacion, indice) {
    if (indice != 0) {
      let aux = {
        gid_location: localizacion?.gid,
        select: selectedLocation[indice - 1],
      };

      const value =
        Platform.OS === "ios"
          ? selectedLocation[indice - 1]?.value
          : selectedLocation[indice - 1];

      auxText += localizacion?.name + " " + value + " ";
      opcionesSelAux.push(aux);
    }
  });

  return { text: auxText, array: opcionesSelAux };
};
