import * as geolib from "geolib";

export const getDistance = ({ latA, lngA, latB, lngB }) => {
  const distance = geolib.getPreciseDistance(
    {
      latitude: latA,
      longitude: lngA,
    },
    {
      latitude: latB,
      longitude: lngB,
    }
  );
  return distance;
};

export const getDistances = ({ array, ref }) => {
  let distances = [];
  array.forEach((element) => {
    const { lat, lng } = element.coordinates;
    let distance = getDistance({
      latA: parseFloat(lat),
      lngA: parseFloat(lng),
      latB: ref.lat,
      lngB: ref.lng,
    });
    distances.push(distance);
  });
  return distances;
};
