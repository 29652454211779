import {
  LATER_ADD_PRODUCT,
  LATER_INCREMENT_PRODUCT,
  LATER_DELETE_PRODUCT,
  LATER_SET_COMMENT,
  LATER_SET_DATE,
  LATER_SET_SERVICE,
  LATER_SET_ORDER_TYPE,
  LATER_RESET_CART,
  LATER_SET_EVENT,
} from "./laterCartTypes";

export const addProduct = (data) => {
  const product = {
    pointSaleId: data.pointSale.gid,
    quantity: 1,
    internalId: data.internalId,
    productId: data.product.gid,
    productName: data.product.name,
    qtyAvailable: data.quantityAvailable,
    parentOrder: data.parentOrder,
  };
  return (dispatch) => {
    dispatch({
      type: LATER_ADD_PRODUCT,
      payload: {
        product: product,
        shop: data.shop,
      },
    });
  };
};

export const incrementProduct = ({ internalId, shopId }) => {
  return (dispatch) => {
    dispatch({
      type: LATER_INCREMENT_PRODUCT,
      payload: {
        internalId: internalId,
        shopId: shopId,
      },
    });
  };
};

export const deleteProduct = (data) => {
  return (dispatch) => {
    dispatch({
      type: LATER_DELETE_PRODUCT,
      payload: data,
    });
  };
};

export const setComment = (data) => {
  return (dispatch) => {
    dispatch({
      type: LATER_SET_COMMENT,
      payload: data,
    });
  };
};

export const setDate = (data) => {
  return (dispatch) => {
    dispatch({
      type: LATER_SET_DATE,
      payload: data,
    });
  };
};

export const setService = (props) => {
  return (dispatch) => {
    dispatch({
      type: LATER_SET_SERVICE,
      payload: {
        ...props,
      },
    });
  };
};

export const setOrderType = (data) => {
  return (dispatch) => {
    dispatch({
      type: LATER_SET_ORDER_TYPE,
      payload: data,
    });
  };
};

export const resetCart = () => {
  return (dispatch) => {
    dispatch({
      type: LATER_RESET_CART,
    });
  };
};

export const setEvent = (eventId) => {
  return (dispatch) => {
    dispatch({
      type: LATER_SET_EVENT,
      payload: eventId,
    });
  };
};
