import { getSingleProductCost } from "./productMethods";

export const filterByHavingOrderLines = (shop) =>
  shop?.order_lines && shop.order_lines.length;

export const hasOrders = (orders) => {
  var aux = false;
  orders.map((shop) => {
    if (shop?.order_lines.length > 0) {
      aux = true;
    }
  });
  return aux;
};

export const numOfProducts = (orders) => {
  let number = 0;
  orders.map((shop) => {
    shop?.order_lines.map((el) => {
      number += el.quantity;
    });
  });
  return number;
};

export const getServiceShopCost = (services, shop) => {
  let cost = 0;
  services.map((service) => {
    if (service.shopId === shop.id_shop) {
      cost = service.amount;
    }
  });
  return cost;
};

export const needRegistration = (orders) => {
  let result = false;
  orders.map((shop) => {
    if (shop.registration === 1) {
      result = true;
    }
  });
  return result;
};

export const getShopServiceCost = (services, shop) => {
  let cost = 0;
  let shopAmount = 0;
  let max = null;

  if (shop.max_waiter_commission !== null) {
    max = shop.max_waiter_commission;
  }
  shop.order_lines.map((line) => {
    shopAmount +=
      getSingleProductCost(line.amount, line?.variants, line?.plates) *
      line.quantity;
  });

  if (shopAmount > 0) {
    services.map((service) => {
      if (service.amount > 0 && service.shopId === shop.id_shop) {
        if (service.type_amount === "fixed") {
          cost += service.amount;
        }
        if (service.type_amount === "percentage") {
          cost = shopAmount * (service.amount * 0.0001);
        }
      }
    });
  }
  if (max !== null && cost > max) {
    cost = max;
  }
  return Math.floor(cost);
};

export const getServicesCostByShop = (services, orders) => {
  let costs = [];
  orders.filter(filterByHavingOrderLines).map((shop) => {
    costs.push({
      amount: getServiceShopCost(services, shop),
      shop: shop.id_shop,
      name: shop.shop_name,
    });
  });
  return costs;
};

export const getSingleShopCommission = (shop) => {
  let cost = 0;
  let max = null;
  let shopAmount = 0;
  if (shop.max_gopick_commission !== null) {
    max = shop.max_gopick_commission;
  }

  shop.order_lines.map((line) => {
    if (line.not_pay_commission === 0 || line.not_pay_commission === null) {
      const productCost = getSingleProductCost(
        line.amount,
        line.variants,
        line.plates
      );
      shopAmount += productCost * line.quantity;
    }
  });

  shop.order_lines_commission.map((line) => {
    if (line.amount > 0) {
      if (line.type === "fixed") {
        cost += line.amount;
      }
      if (line.type === "percentage") {
        cost = shopAmount * (line.amount * 0.0001);
      }
    }
  });

  if (max !== null && cost > max) {
    cost = max;
  }
  return Math.floor(cost);
};

export const getCommissionsCost = (orders) => {
  let cost = 0;
  orders.filter(filterByHavingOrderLines).map((shop) => {
    cost += getSingleShopCommission(shop);
  });
  return Math.floor(cost);
};

export const getTotalByShopForPayments = (shop, type) => {
  let total = 0;
  shop.order_lines.map((line) => {
    if (type === "commission") {
      if (line.not_pay_commission === 0 || line.not_pay_commission === null) {
        total += getSingleProductCost(line.amount, line.variants, line.plates);
      }
    } else {
      if (line.not_pay_service === 0 || line.not_pay_service === null) {
        total += getSingleProductCost(line.amount, line.variants, line.plates);
      }
    }
  });
  return total;
};

export const getShopCommission = (shop) => {
  let cost = 0;

  if (getTotalByShopForPayments(shop, "commission") > 0) {
    shop.order_lines_commission.map((line) => {
      if (line.amount > 0) {
        if (line.type === "fixed") {
          cost += line.amount;
        }
        if (line.type === "percentage") {
          cost =
            getTotalByShopForPayments(shop, "commission") *
            (line.amount * 0.0001);
        }
      }
    });
  }
  return cost;
};

export const getShopService = (shop, services) => {
  let cost = 0;

  if (getTotalByShopForPayments(shop, "service") > 0) {
    cost = getServiceShopCost(services, shop);
  }

  return cost;
};

export const getAlerts = (orders = [], showIn) => {
  let alert = { title: "", message: "" };
  let status = false;

  orders.filter(filterByHavingOrderLines).map((shop) => {
    if (shop.alerts.length > 0 && shop?.order_lines?.length > 0) {
      shop.alerts.map((popup) => {
        if (popup.show_in === showIn && popup.active === 1) {
          if (alert.message.includes(popup.message) === false) {
            status = true;
            alert.title = popup.name;
            alert.message = alert.message + popup.message + "\n";
          }
        }
      });
    }
  });

  if (status === true) {
    return alert;
  } else {
    return false;
  }
};

export const getShopAlert = (shop, showIn) => {
  // todo: alerts must be fetched

  // let alert = { title: "", message: "" };
  let status = false;
  // if (shop?.alerts !== null && shop?.alerts?.length > 0) {
  //   shop?.alerts?.map((popup) => {
  //     if (popup.show_in === showIn && popup.active === 1) {
  //       if (alert.message.includes(popup.message) === false) {
  //         status = true;
  //         alert.title = popup.name;
  //         alert.message = alert.message + popup.message + "\n";
  //       }
  //     }
  //   });
  // }

  if (status === true) {
    return alert;
  } else {
    return false;
  }
};

export const getLegalAge = (orders) => {
  let hasLegalAgeProducts = false;
  orders.filter(filterByHavingOrderLines).map((shop) =>
    shop.order_lines.map((product) => {
      if (product.only_legal_age === 1) {
        hasLegalAgeProducts = true;
      }
    })
  );
  return hasLegalAgeProducts;
};

export const discountAvailableProducts = ({ orders, discountData }) => {
  const { shops = [], products = [], events = [] } = discountData;
  let available = false;
  let data = [];

  orders.map((shop) => {
    if (
      events.includes(shop.id_event) === true ||
      shops.includes(shop.id_shop) === true
    ) {
      available = true;
      shop.order_lines.map((line) => {
        const separator = line.gid.indexOf(",");
        const realGid = line.gid.slice(0, separator);
        const type = line.gid.slice(separator + 1, line.gid.length);
        data.push({
          quantity: line.quantity,
          amount: getSingleProductCost(
            line?.amount,
            line?.variants,
            line?.plates
          ),

          gid: realGid,
          type: type,
        });
      });
    } else {
      shop.order_lines.map((line) => {
        const separator = line.gid.indexOf(",");
        const realGid = line.gid.slice(0, separator);
        const type = line.gid.slice(separator + 1, line.gid.length);
        if (products.includes(parseInt(realGid))) {
          available = true;
          data.push({
            quantity: line.quantity,
            amount: line.totalAmount,
            gid: realGid,
            type: type,
          });
        }
      });
    }
  });
  return { available, data };
};

export const discountAvailableTickets = ({
  productLines = [],
  ticketLines = [],
  shops = [],
  products = [],
  tickets = [],
}) => {
  let available = false;
  let data = [];

  ticketLines.map((ticket) => {
    if (
      shops.includes(ticket.fk_shop) === true ||
      tickets.includes(ticket.id_ticket) === true
    ) {
      available = true;
      data.push({
        quantity: ticket.quantity,
        amount: ticket.amount,
        gid: ticket.id_ticket,
        type: "ticket",
      });
    }
  });
  productLines.map((product) => {
    if (
      shops.includes(product.fk_shop) === true ||
      products.includes(product.gid) === true
    ) {
      available = true;
      data.push({
        quantity: product.quantity,
        amount: product.amount,
        gid: product.gid,
        type: "product",
      });
    }
  });
  return { available, data };
};

export const getProductObjectTickets = (product_lines, shop) => {
  let order_lines = [];
  const today = new Date().getTime() / 1000;
  const shopObject = (lines = [], commissions = []) => {
    let object = {
      date: Math.floor(today),
      id_event: shop.fk_event,
      service: "pick_up",
      id_shop: shop.gid,
      order_lines_discount: [],
      order_lines_commission: commissions,
      order_lines: lines,
    };

    return object;
  };

  let exclusiveProducts = [];
  let normalProducts = [];

  product_lines.map((product) => {
    if (product.exclusive_order === 1) {
      exclusiveProducts.push(product);
    } else {
      normalProducts.push(product);
    }
  });

  if (normalProducts.length > 0) {
    let lines = [];
    let commissions = [];
    normalProducts.map((product) => {
      if (product.payService === 0) {
        if (product?.commission > 0) {
          commissions.push({
            name: "Gastos de gestión",
            amount: parseInt(product.commission),
            id_product: product.id_product,
          });
        }
      }
      let vars = [];
      product.variants.map((group) => {
        let aux = [];
        group.options.map((el) => {
          aux.push({
            gid: el.gid,
            quantity: el.quantity,
          });
        });
        vars.push({
          id_group_variants: group.id_group_variants,
          variants: aux,
        });
        aux = [];
      });

      lines.push({
        id_product: product.id_product,
        quantity: product.quantity,
        id_point_sale: product.id_point_sale,
        variants: vars,
        amount: product.amount,
      });
      vars = [];
    });
    order_lines.push(shopObject(lines, commissions));
    commissions = [];
  }

  if (exclusiveProducts.length > 0) {
    let commissions = [];
    exclusiveProducts.map((product) => {
      if (product.payService === 0) {
        if (product?.commission > 0) {
          commissions.push({
            name: "Gastos de gestión",
            amount: Math.floor(product.commission / product.quantity),
            id_product: product.id_product,
          });
        }
      }
      let vars = [];
      product.variants.map((group) => {
        let aux = [];
        group.options.map((el) => {
          aux.push({
            gid: el.gid,
            quantity: el.quantity,
          });
        });
        vars.push({
          id_group_variants: group.id_group_variants,
          variants: aux,
        });
        aux = [];
      });

      for (let i = 0; i < product.quantity; i++) {
        const line = [
          {
            id_product: product.id_product,
            quantity: 1,
            id_point_sale: product.id_point_sale,
            variants: vars,
            amount: product.amount,
          },
        ];
        order_lines.push(shopObject(line, commissions));
      }
      commissions = [];
      vars = [];
    });
  }
  return order_lines;
};

export const getFormattedOrderTickets = (
  ticketData,
  shop,
  user,
  token,
  cardId,
  payment_method = "card"
) => {
  const { ticket_lines, discount, product_lines } = ticketData;
  const today = new Date().getTime() / 1000;
  let object = {
    id_user: user.gid,
    payment_method: payment_method,
    id_currency: 1,
    comment: "",
    orders: [],
  };

  if (payment_method === "card") {
    if (cardId) {
      object = { ...object, id_payment_card: cardId.gid };
    } else {
      object = { ...object, jetToken: token };
    }
  }

  const getTicketObject = (i) => {
    let object = {
      date: Math.floor(today),
      id_event: shop.fk_event,
      service: "pick_up",
      id_shop: shop.gid,
      order_lines_discount: [],
      order_lines_commission: [],
      order_lines_ticket: [
        {
          name: ticket_lines[i].name,
          quantity: ticket_lines[i].quantity,
          amount: ticket_lines[i].amount,
          id_ticket: ticket_lines[i].id_ticket,
          questions: ticket_lines[i]?.questions,
        },
      ],
    };

    if (ticket_lines[i].commission > 0) {
      object = {
        ...object,
        order_lines_commission: [
          {
            name: "Gastos de gestión",
            amount: Math.floor(ticket_lines[i].commission),
          },
        ],
      };
    }
    return object;
  };

  const productOrders = getProductObjectTickets(product_lines, shop);
  if (productOrders.length > 0) {
    object.orders = productOrders;
  }

  for (let i = 0; i < ticket_lines.length; i++) {
    const result = getTicketObject(i);
    object.orders.push(result);
  }
  if (discount.length > 0) {
    let auxOrders = object.orders;
    let indexToInsert = -1;
    let lineToInsert = -1;
    let type = "";
    discount.map((el) => {
      if (el.type === "ticket") {
        auxOrders.map((order, index) => {
          if (order.order_lines_discount.length === 0) {
            if (order.order_lines_ticket) {
              if (order?.order_lines_ticket[0]?.id_ticket === el.gid) {
                indexToInsert = index;
                type = "ticket";
              }
            }
          }
        });
      }

      if (el.type === "product") {
        auxOrders.map((order, index) => {
          if (order.order_lines) {
            order?.order_lines.map((line, lineIndex) => {
              if (line?.id_product === el.gid) {
                indexToInsert = index;
                lineToInsert = lineIndex;
                type = "product";
              }
            });
          }
        });
      }
      if (indexToInsert !== -1) {
        if (type === "ticket") {
          if (
            el.amount +
              auxOrders[indexToInsert]?.order_lines_ticket[0].amount ===
            0
          ) {
            auxOrders[indexToInsert].order_lines_commission = [];
          }
        }
        if (type === "product") {
          let auxAmount = 0;
          auxOrders[indexToInsert]?.order_lines.map((line) => {
            auxAmount += line.amount;
          });
          if (el.amount + auxAmount === 0) {
            let auxIndex = -1;
            auxOrders[indexToInsert].order_lines_commission.map(
              (commission, index) => {
                if (commission.id_product === el.gid) {
                  auxIndex = index;
                }
              }
            );
            if (auxIndex !== -1 && lineToInsert !== -1) {
              const selProd =
                auxOrders[indexToInsert].order_lines[lineToInsert];
              const selComm =
                auxOrders[indexToInsert].order_lines_commission[auxIndex];
              selComm.amount -= selComm.amount / selProd.quantity;
            }
            auxIndex = -1;
            lineToInsert = -1;
          }
        }

        auxOrders[indexToInsert].order_lines_discount.push({
          id_discount: el.id_discount,
          name: "Descuento",
          amount: el.amount,
        });
        indexToInsert = -1;
      }
    });
    object.orders = auxOrders;
  }

  return object;
};

export const purgueFinalOrder = (order) => {
  const auxOrder = order;
  auxOrder.orders.map((line) => {
    line.order_lines_commission = [];
    line.order_lines_service = [];
  });
  return auxOrder;
};
