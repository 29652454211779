import { validateMerchant } from "./functions/validateMerchant";

export const applePayConfiguration = (session) => {
  return {
    //onValidateMerchant is required if you're using your own Apple Pay certificate
    onValidateMerchant: async (resolve, reject, validationURL) => {
      try {
        console.log("onValidateMerchant");
        // Your server uses the validation URL to request a session from the Apple Pay server.
        // Call resolve(MERCHANTSESSION) or reject() to complete merchant validation.
        const merchSession = await validateMerchant(validationURL);

        if (merchSession) {
          resolve(merchSession);
        } else {
          // Complete merchant validation with reject() if any error occurs
          console.log("apple pay error");
          reject();
        }
      } catch (error) {
        console.log("error onValidateMerchant", error.message);
        reject();
      }
    },
  };
};
