import React, { useState, useEffect } from "react";
import { StyleSheet, View } from "react-native";

// Components
import Wrapper from "./Wrapper";
import Divider from "../../../../components/atoms/Divider";
import ForLaterProduct from "./ForLaterProduct";
import ServicePicker from "./ServicePicker";
import LoadingScreen from "./LoadingScreen";
import MessageScreen from "./MessageScreen";

// Constants
import { colors, family } from "../../../../constants/theme";

// Methods
import { fetchShopData } from "../../../../utils/shop/fetchShopData";
import { fetchPointSales } from "../../../../utils/pointSale/fetchPointSales";

const ShopItem = ({ data }) => {
  const { orders = [], gid } = data;

  const [status, setStatus] = useState("idle");
  const [message, setMessage] = useState();
  const [pointSales, setPointSales] = useState();
  const [shopData, setShopData] = useState();

  const fetchData = async (gid) => {
    try {
      setStatus("loading");
      const response = await fetchPointSales(gid);
      const shop = await fetchShopData(gid);
      if (shop.status === "success") {
        setShopData(shop?.data);
        if (response.status === "success") {
          setPointSales(response.data);
          setStatus("success");
        } else {
          setStatus("fail");
          setMessage(`Error al obtener los puntos de venta.\n${response.data}`);
        }
      } else {
        setStatus("fail");
        setMessage(`Error al obtener los datos de la tienda`);
      }
    } catch (error) {
      setMessage(
        `Ocurrió un error obteniendo los datos de esta tienda.\n${error.message}\nInténdalo más tarde`
      );
      setStatus("fail");
    }
  };

  useEffect(() => {
    fetchData(gid);
  }, []);

  if (status === "loading" || status === "idle") {
    return (
      <View style={styles.wrapper}>
        <LoadingScreen />
      </View>
    );
  }

  if (status === "fail") {
    return (
      <View style={styles.wrapper}>
        <MessageScreen message={message} />
      </View>
    );
  }

  return (
    <Wrapper data={{ ...data, ...shopData }}>
      <View style={styles.serviceContainer}>
        <ServicePicker shopServices={shopData?.services} shop={data} />
      </View>
      <Divider height={20} />
      {orders?.map((item) => {
        return (
          item.quantityAvailable > 0 && (
            <View style={styles.categoryWrapper} key={item.gid}>
              <ForLaterProduct
                data={item}
                shopData={shopData}
                shop={data}
                key={item.gid}
                psData={pointSales}
              />
              <Divider height={12} />
            </View>
          )
        );
      })}
    </Wrapper>
  );
};

export default ShopItem;

const styles = StyleSheet.create({
  title: {
    fontFamily: family.bold,
    fontSize: 14,
    color: colors.brown,
    textAlign: "center",
  },

  wrapper: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    height: 400,
  },
  categoryWrapper: {
    width: "100%",
  },
  serviceContainer: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  categoryName: {
    fontFamily: family.bold,
    color: colors.brown,
    fontSize: 20,
  },
});
