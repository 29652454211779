import React from "react";
import { StyleSheet, Text, View } from "react-native";

// Components
import CustomModal from "../../../../../components/atoms/CustomModal";
import SchedulesContainer from "./components/SchedulesContainer";
import CustomButton from "../../../../../components/atoms/CustomButton";

// Constants
import { colors, family } from "../../../../../constants/theme";

// Utils
import { filterByHavingOrderLines } from "../../../../../utils/orderMethods";

const ScheduleModal = ({ visible, setModal, shopList, customStyle }) => {
  const { primaryColor, backgroundColor } = customStyle;

  return (
    <CustomModal modalVisible={visible} handleModal={setModal}>
      <View style={styles.titleContainer}>
        <Text style={[styles.title, { color: primaryColor }]}>
          ¿Cuándo quieres tu pedido?
        </Text>
      </View>
      <SchedulesContainer
        shop={shopList.filter(filterByHavingOrderLines)[0]}
        customStyle={customStyle}
      />
      <View style={styles.buttonContainer}>
        <CustomButton
          onPress={() => {
            setModal();
          }}
          title={`Aceptar`}
          color={primaryColor}
          textColor={backgroundColor}
          active
        />
      </View>
    </CustomModal>
  );
};

export default ScheduleModal;

const styles = StyleSheet.create({
  titleContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginBottom: 20,
  },
  title: {
    fontFamily: family.bold,
    color: colors.brown,
    fontSize: 16,
  },
  buttonContainer: {
    width: "100%",
    marginTop: 30,
    height: 50,
  },
});
