import React, { useState, useEffect, useCallback, useContext } from "react";
import { View, StyleSheet } from "react-native";
import { useFocusEffect } from "@react-navigation/native";
import { connect } from "react-redux";

// Components
import LaterOrdersList from "../components/LaterOrdersList";
import CurrentOrdersList from "../components/CurrentOrdersList";
import LoadingScreen from "../components/LoadingScreen";
import ErrorScreen from "../components/ErrorScreen";

// Context
import { AuthContext } from "../../../../contexts/AuthContext";

// Redux
import { resetCart } from "../../../../redux/laterCart/laterCartActions";
import {
  fetchPastOrders,
  fetchLaterOrders,
  updateOrder,
} from "../../../../redux/orders/ordersActions";

// Services
import { initiateSocket, ordersUpdate } from "../../../../services/orders";

const InProcessOrders = ({
  orders,
  resetCart,
  updateOrder,
  navigation,
  fetchPastOrders,
  userData,
  laterOrders,
  fetchLaterOrders,
}) => {
  const { user: firebaseUser } = useContext(AuthContext);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  useFocusEffect(
    useCallback(() => {
      if (userData?.gid) {
        getData();
      }
      resetCart();
    }, [])
  );

  useEffect(() => {
    if (userData?.gid) {
      getData();
    }
  }, [userData]);

  async function initiateSocketWithToken() {
    if (firebaseUser) {
      const token = await firebaseUser.getIdToken(true);
      initiateSocket(token, "orders").then(() => ordersUpdate(updateOrder));
    }
  }

  useEffect(() => {
    initiateSocketWithToken();
  }, []);

  const getData = async () => {
    setLoading(true);
    setError();
    try {
      const token = await firebaseUser.getIdToken(true);
      await fetchPastOrders(userData.gid, token);
      await fetchLaterOrders(userData.gid, token);
    } catch (err) {
      setError(err.message);
      console.log("error fetching past orders");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <LoadingScreen
        text="No tienes ningún pedido en proceso"
        navigation={navigation}
      />
    );
  }

  if (error) {
    return <ErrorScreen error={error} />;
  }

  return (
    <View style={styles.container}>
      <CurrentOrdersList data={orders} navigation={navigation} />
      <LaterOrdersList data={laterOrders} navigation={navigation} />
    </View>
  );
};

const mapDispatchToProps = {
  fetchPastOrders,
  updateOrder,
  resetCart,
  fetchLaterOrders,
};

const mapStateToProps = (state) => {
  return {
    orders: state.orders.orders,
    laterOrders: state.orders.laterOrders,
    userData: state.user.user,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InProcessOrders);

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
