export const parseFireBaseErrors = (errorCode) => {
  console.log('errorCode',errorCode)
  switch (errorCode) {
    case "auth/claims-too-large":
      return "La carga útil es demasiado pesada";
    case "auth/email-already-exists":
      return "El correo electrónico proporcionado ya está en uso.";
    case "auth/id-token-expired":
      return "El token de acceso ha caducado.";
    case "auth/id-token-revoked":
      return "Se revocó el token de Identificación.";
    case "auth/insufficient-permission":
      return "La credencial que se usó para inicializar el SDK de Admin no tiene permisos suficientes.";
    case "auth/internal-error":
      return "El servidor de Authentication encontró un error inesperado cuando se intentaba procesar la solicitud.";
    case "auth/invalid-argument":
      return "Se proporcionó un argumento no válido para un método de autenticación.";
    case "auth/invalid-claims":
      return "Los atributos personalizados del reclamo que se entregaron no son válidos.";
    case "auth/invalid-continue-uri":
      return "La URL de continuación debe ser una string de URL válida.";
    case "auth/invalid-creation-time":
      return "La hora de creación debe ser una string de fecha en formato UTC válida.";
    case "auth/invalid-credential":
      return "La credencial que se intenta utilizar no es válido.";
    case "auth/invalid-disabled-field":
      return "El valor que se proporcionó para la propiedad del usuario disabled no es válido. Debe ser un booleano.";
    case "auth/invalid-display-name":
      return "El valor que se proporcionó para la propiedad del usuario displayName no es válido. Debe ser una string que no esté vacía.";
    case "auth/invalid-dynamic-link-domain":
      return "El dominio del vínculo dinámico proporcionado no se configuró o no se autorizó para el proyecto actual.";
    case "auth/invalid-email":
      return "El email no es válido ";
    case "auth/session-cookie-expired":
      return "La cookie proporcionada de la sesión de Firebase venció.";
    case "auth/session-cookie-revoked":
      return "Se revocaron las cookies de la sesión de Firebase.";
    case "auth/uid-already-exists":
      return "Otro usuario ya utiliza el uid proporcionado. Cada usuario debe tener un uid único.";
    case "auth/unauthorized-continue-uri":
      return "El dominio de la URL de continuación no está en la lista blanca. Inclúyelo en la lista en Firebase console.";
    case "auth/user-not-found":
      return "No existe ningún usuario que corresponda a los datos proporcionados.";
    case "auth/email-already-in-use":
      return "La dirección de correo electrónico ya está en uso.";
    case "auth/invalid-email":
      return "La dirección de correo electrónico no es válida.";
    case "auth/operation-not-allowed":
      return "Operación no permitida.";
    case "auth/weak-password":
      return "La contraseña es demasiado débil.";
    case "auth/wrong-password":
      return "La constraseña es incorrecta";
    case "auth/user-disabled":
      return "El usuario se encuentra deshabilitado";
    default:
      return "Error desconocido.";
  }
};
