import React, { useState } from "react";
import { StyleSheet, View, Platform } from "react-native";

// Components
import CustomModal from "../../../../../components/atoms/CustomModal";

// Utils
import PaymentContainer from "../../../../../adyen/components/PaymentContainer";
import WarningModal from "../../../../../components/organisms/modals/WarningModal";

const PaymentModal = ({ visible, setModal, navigation }) => {
  const [warningText, setWarningText] = useState();
  return (
    <>
      <CustomModal modalVisible={visible} handleModal={setModal}>
        <View style={styles.container}>
          <PaymentContainer
            modalHandler={setModal}
            navigation={navigation}
            setWarningText={setWarningText}
            visible={visible}
          />
        </View>
      </CustomModal>
      <WarningModal
        visible={warningText !== undefined}
        setModal={setWarningText}
        text={warningText}
      />
    </>
  );
};

export default PaymentModal;

const styles = StyleSheet.create({
  container: {
    width: "100%",
  },
  webWrapper: {
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
  },
});
