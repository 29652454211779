import React from "react";
import { AntDesign } from "@expo/vector-icons";
import {
  StyleSheet,
  TouchableOpacity,
  Text,
  View,
  Platform,
} from "react-native";

// Constants
import { colors, family } from "../../../../constants/theme";
import Divider from "../../../../components/atoms/Divider";
import Tag from "../../../../components/organisms/pastOrder/Tag";

const EventHeader = ({ title, navigation, expireAt }) => {
  const backHandler = () => {
    navigation.goBack();
  };

  const fecha = new Date(expireAt);
  const dia = fecha.getDate().toString().padStart(2, "0");
  const mes = (fecha.getMonth() + 1).toString().padStart(2, "0");
  const fechaFormateada = `${dia}/${mes}`;

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <TouchableOpacity onPress={backHandler}>
          <AntDesign name="arrowleft" size={24} color={colors.brown} />
        </TouchableOpacity>
        <Divider width={10} />
        <View style={styles.titleContainer}>
          <Text style={styles.title}>{title}</Text>
        </View>
        <Tag
          text={`Caduca el ${fechaFormateada}`}
          textColor={colors.brown}
          bgColor={colors.lightRed}
        />
      </View>
    </View>
  );
};

export default EventHeader;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    width: "100%",
    maxWidth: 400,
    height: Platform.OS === "ios" ? 100 : 70,
    paddingTop: Platform.OS === "ios" ? 30 : 0,
    flexDirection: "row",
    backgroundColor: colors.white,
    alignItems: "center",
    paddingHorizontal: 18,
  },
  titleContainer: {
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "center",
  },
  title: {
    color: colors.brown,
    fontFamily: family.bold,
    fontSize: 18,
    textAlign: "left",
  },
});
