import { environment } from "../../config";
export const googleApiKey = "AIzaSyC7OfmUcvSfyz8dis5U1zm17_DWQdoIQ6Y";
export const URLbase =
  environment === "dev"
    ? "https://api.dev.gopick-app.com"
    : "https://api.gopick-app.com";

export const UrlBaseV2 =
  environment === "dev"
    ? "https://api.dev.gopick-app.com/v2/"
    : "https://api.gopick-app.com/v2/";

export const URlManagement = `${URLbase}/management`;
export const URlOrder = `${URLbase}/order`;
export const URlUserManagement = `${URLbase}/user-management`;
export const URlTextualBase = `https://admin.gopick-app.com/api/textual-page`;

const jetIdDevelopment = "azXoDdE5NVCybi0ZwWgxGqIRUt1JcAPv";
const jetIdProduction = "yWFGsrvTwV8CZR6p04chnLjaMkfYqmAx";

export const adyenClientKey =
  environment === "dev"
    ? "test_WLQ32H5M3BA7PCWSALVMBXNDGYQ5OH5Q"
    : "live_E2L6QCTDBJBE7FTTVTTVERIQMMR65FFN";

export const jetId = environment === "dev" ? jetIdDevelopment : jetIdProduction;
