import React from "react";
import { Platform } from "react-native";
import { createStackNavigator } from "@react-navigation/stack";

// Components
import BackHeader from "../../components/Header/BackHeader";
import LiveHeader from "../../components/Header/LiveHeader";

// Screens
import HomeScreen from "../../screens/events/HomeScreen";
import EventScreen from "../../screens/events/EventScreen";
import ShopScreen from "../../screens/events/ShopScreen";
import MealVariablesScreen from "../../screens/events/MealVariablesScreen";
import MenuSelectorScreen from "../../screens/events/MenuSelectorScreen";
import EventListScreen from "../../screens/events/EventListScreen";
import ConfirmOrderScreen from "../../screens/events/ConfirmOrderScreen";
import PaymentSuccess from "../../screens/events/PaymentSuccess";
import PaymentError from "../../screens/events/PaymentError";
import PaymentResult from "../../screens/events/PaymentResult";

const Stack = createStackNavigator();

const EventsStack = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        title: "Gopick",
        presentation: "card",
        animationEnabled: Platform.OS === "ios" ? true : false,
      }}
    >
      <Stack.Screen
        name="HomeScreen"
        options={{ header: (props) => <LiveHeader {...props} /> }}
        component={HomeScreen}
      />

      <Stack.Screen
        name="EventScreen"
        options={{
          header: (props) => (
            <BackHeader title={props.route.params.name} {...props} />
          ),
        }}
        component={EventScreen}
      />

      <Stack.Screen
        name="EventListScreen"
        options={{
          header: (props) => (
            <BackHeader title={props.route.params.pageName} {...props} />
          ),
        }}
        component={EventListScreen}
      />

      <Stack.Screen
        name="ShopScreen"
        options={{ headerShown: false }}
        component={ShopScreen}
      />

      <Stack.Screen
        name="MealVariablesScreen"
        options={{ headerShown: false }}
        component={MealVariablesScreen}
      />

      <Stack.Screen
        name="ConfirmOrderScreen"
        options={{
          header: (props) => (
            <BackHeader
              title={"Tu pedido"}
              paddingTop={0}
              height={70}
              {...props}
            />
          ),
          animationEnabled: Platform.OS === "ios" ? true : false,
          presentation: "modal",
        }}
        component={ConfirmOrderScreen}
      />

      <Stack.Screen
        name="PaymentSuccess"
        options={{ headerShown: false }}
        component={PaymentSuccess}
      />

      <Stack.Screen
        name="PaymentError"
        options={{ headerShown: false }}
        component={PaymentError}
      />

      <Stack.Screen
        name="PaymentResult"
        options={{ headerShown: false }}
        component={PaymentResult}
      />

      <Stack.Screen
        name="MenuSelectorScreen"
        options={{ headerShown: false }}
        component={MenuSelectorScreen}
      />
    </Stack.Navigator>
  );
};

export default EventsStack;
