import React from "react";
import { Platform, Text, View } from "react-native";
import StyleSheet from "react-native-media-query";
import { Picker } from "@react-native-picker/picker";
import SelectDropdown from "react-native-select-dropdown";

// Constants
import { colors, family } from "../../../../../constants/theme";

// Utils
import {
  getCharFromIndex,
  getCharIndex,
} from "../../../../../utils/arrayMethods";

//** WARNING: LOCATIONS LOGIC IS LEGACY CODE AND MUST BE REFACORED ASAP */

const LocationsSelector = ({
  setSelectedLocation,
  selectedLocation,
  locations,
  setLocations,
  setAcceptAvailable,
  service,
}) => {
  function canOpen(service, locations) {
    let puedoAbrirTienda = true;
    if (
      service.levels_locations == "All" ||
      service.levels_locations == "all"
    ) {
      if (!locations) {
        puedoAbrirTienda = false;
      } else if (locations[locations.length - 1] != undefined) {
        if (
          locations[locations.length - 1].childs == undefined &&
          locations[locations.length - 1].type_amount == undefined
        ) {
          puedoAbrirTienda = true;
        } else {
          puedoAbrirTienda = false;
        }
      }
    } else {
      if (parseFloat(service.levels_locations) >= locations.length) {
        puedoAbrirTienda = false;
      }
    }
    setAcceptAvailable(puedoAbrirTienda);
  }

  function seleccionarValor(localizacion, indice, valor) {
    let localizacionesSelAux;
    if (valor != -1) {
      localizacionesSelAux = locations.slice();
      localizacionesSelAux[indice + 1] = localizacion;

      localizacionesSelAux.splice(
        indice + 2,
        localizacionesSelAux.length - indice + 2
      );

      setLocations(localizacionesSelAux);

      let opcionesSelAux = selectedLocation.slice();

      opcionesSelAux[indice] = valor;

      opcionesSelAux.splice(indice + 1, opcionesSelAux.length - indice + 1);

      setSelectedLocation(opcionesSelAux);
    } else {
      setLocations(locations.slice());
      setSelectedLocation([]);
    }
    canOpen(service, localizacionesSelAux);
  }

  function cargarOpciones(localizacion) {
    const arrayOpciones = [];
    const {
      structure: {
        type,
        number_from,
        number_to,
        even_odd,
        letter_from,
        letter_to,
        writing,
      },
      name,
    } = localizacion;

    const isEven = (num) =>
      even_odd === "even" ? num % 2 === 0 : num % 2 !== 0;
    const getLabel = (i, j) => `${name} ${i} ${getCharFromIndex(j, "upper")}`;
    const getValue = (i, j) => `${i} ${getCharFromIndex(j, "upper")}`;

    switch (type) {
      case "numeric":
      case "numeric_even_odd":
        for (let i = parseInt(number_from); i <= parseInt(number_to); i++) {
          if (type === "numeric_even_odd" && !isEven(i)) continue;
          arrayOpciones.push({ label: `${name} ${i}`, value: i });
        }
        break;

      case "alphanumeric":
      case "alphanumeric_even_odd":
        for (let i = parseInt(number_from); i <= parseInt(number_to); i++) {
          if (type === "alphanumeric_even_odd" && !isEven(i)) continue;
          for (
            let j = getCharIndex(letter_from);
            j <= getCharIndex(letter_to);
            j++
          ) {
            arrayOpciones.push({
              label: getLabel(i, j),
              value: getValue(i, j),
            });
          }
        }
        break;

      case "alphabetical":
        for (
          let i = getCharIndex(letter_from);
          i <= getCharIndex(letter_to);
          i++
        ) {
          arrayOpciones.push({
            label: `${name} ${getCharFromIndex(i, "upper")}`,
            value: getCharFromIndex(i, "upper"),
          });
        }
        break;

      case "writing":
        arrayOpciones.push(
          ...writing.map((opcion) => ({
            label: `${name} ${opcion}`,
            value: opcion,
          }))
        );
        break;
    }

    return arrayOpciones;
  }

  function pintarSelect(localizacion, indice) {
    let arrayHijos = [];
    if (localizacion.childs != undefined) {
      localizacion.childs.map(function (hijo, indice) {
        hijo.arrayOpciones = cargarOpciones(hijo);
        arrayHijos.push(hijo);
      });
    }
    if (localizacion.structure != undefined) {
      if (localizacion.structure.length > 0) {
        localizacion.structure.map(function (hijo, indice) {
          hijo.arrayOpciones = cargarOpciones(hijo);
          arrayHijos.push(hijo);
        });
      }
    }
    if (arrayHijos.length > 0) {
      let arrayLoc = [];
      if (Platform.OS !== "ios") {
        arrayHijos[0].arrayOpciones.unshift({
          label: "Seleccionar localización",
          value: -1,
        });
      }

      arrayHijos.map(function (hijo, indice) {
        let color = colors.brown;
        if (service.gid_active != undefined) {
          if (service.gid_active.indexOf(parseInt(hijo.gid)) == -1) {
            color = "#D7D7D7";
          }
        }
        return hijo.arrayOpciones.map(function (opcion, indice) {
          arrayLoc.push(opcion);
        });
      });
      return (
        <View style={styles.pickerContainer}>
          {Platform.OS === "ios" ? (
            <SelectDropdown
              data={arrayLoc}
              onSelect={function (itemValue, itemIndex) {
                let limiteZona = 0;
                let zonaSel = -1;
                arrayHijos.map(function (hijo, indice) {
                  limiteZona = limiteZona + hijo.arrayOpciones.length;
                  if (zonaSel == -1 && limiteZona > itemIndex) {
                    zonaSel = indice;
                  }
                });
                seleccionarValor(arrayHijos[zonaSel], indice, itemValue);
              }}
              defaultValue={selectedLocation[indice] || -1}
              defaultButtonText={"Elige localización"}
              renderCustomizedButtonChild={(selectedItem, index) => {
                return (
                  <View style={styles.dropdown4BtnStyle}>
                    <Text style={styles.dropdown4BtnTxtStyle}>
                      {selectedItem ? selectedItem.label : "Elige localización"}
                    </Text>
                  </View>
                );
              }}
              renderCustomizedRowChild={(item, index) => {
                return (
                  <View style={styles.dropdown4BtnStyle}>
                    <Text style={styles.dropdown4BtnTxtStyle}>
                      {item.label}
                    </Text>
                  </View>
                );
              }}
            />
          ) : (
            <Picker
              style={styles.picker}
              mode={"dropdown"}
              selectedValue={selectedLocation[indice] || -1}
              onValueChange={function (itemValue, itemIndex) {
                let limiteZona = 0;
                let zonaSel = -1;
                arrayHijos.map(function (hijo, indice) {
                  limiteZona = limiteZona + hijo.arrayOpciones.length;
                  if (zonaSel == -1 && limiteZona > itemIndex) {
                    zonaSel = indice;
                  }
                });
                seleccionarValor(arrayHijos[zonaSel], indice, itemValue);
              }}
              dataSet={{ media: ids.picker }}
              itemStyle={styles.pickerItem}
            >
              {arrayHijos.map(function (hijo, indice) {
                let color = colors.brown;
                if (service.gid_active != undefined) {
                  if (service.gid_active.indexOf(parseInt(hijo.gid)) == -1) {
                    color = "#D7D7D7";
                  }
                }

                return hijo.arrayOpciones.map(function (opcion, indice) {
                  return (
                    <Picker.Item
                      key={selectedLocation.value}
                      color={color}
                      label={opcion.label.toString()}
                      value={opcion.value}
                      fontFamily={family.normal}
                    />
                  );
                });
              })}
            </Picker>
          )}
        </View>
      );
    }
  }

  return (
    <View style={styles.container}>
      <Text style={styles.siteTitle} dataSet={{ media: ids.siteTitle }}>
        Escoge tu localización:
      </Text>
      <View style={styles.siteContainer}>
        {locations != undefined && locations.length > 0
          ? locations.map(function (localizacion, indice) {
              if (
                service.levels_locations == "all" ||
                service.levels_locations == "All" ||
                parseFloat(service.levels_locations) > indice
              ) {
                return pintarSelect(localizacion, indice);
              }
            })
          : null}
      </View>
    </View>
  );
};

export default LocationsSelector;

const { ids, styles } = StyleSheet.create({
  container: {
    width: "100%",
  },
  topTitle: {
    padding: 10,
    fontWeight: "bold",
    color: colors.brown,
    fontSize: 18,
    width: "100%",
    textAlign: "center",
    "@media (max-width:370px)": {
      padding: 5,
      fontSize: 12,
    },
  },
  siteTitle: {
    padding: 10,
    fontWeight: "bold",
    color: "#E22314",
    fontSize: 18,
    width: "100%",
    textAlign: "center",
    "@media (max-width:370px)": {
      padding: 5,
      fontSize: 12,
    },
  },
  servicesContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    marginVertical: 5,
    "@media (max-width:370px)": {
      marginVertical: 2,
    },
  },
  siteContainer: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  pickerItem: {
    borderRadius: 5,
    fontFamily: family.normal,
    color: colors.brown,
  },
  delivery: {
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  buttonWrapper: {
    height: 60,
    width: "100%",
    "@media (max-width:370px)": {
      marginVertical: 2,
      height: 40,
    },
  },
  pickerContainer: {
    borderRadius: 8,
    borderColor: "gray",
    marginBottom: 20,
  },
  picker: {
    height: 50,
    width: 200,
    borderWidth: 1,
    "@media (max-width:370px)": {
      height: 30,
    },
  },
  dropdown4BtnStyle: {
    width: "100%",
    height: 30,
    borderRadius: 8,
    justifyContent: "center",
    alignItems: "center",
  },
  dropdown4BtnTxtStyle: {
    color: colors.brown,
    textAlign: "center",
    fontFamily: family.normal,
    fontSize: 12,
  },
  loadingWrapper: {
    height: 50,
    justifyContent: "center",
    alignItems: "center",
  },
});
