import {
  FETCH_TEXTUAL_PAGES,
  FETCH_TEXTUAL_PRIVACY,
  FETCH_TEXTUAL_COOKIES,
  FETCH_TEXTUAL_LEGAL,
} from "./configTypes";

const initialState = {
  footer: {
    shown: true,
  },
  textualPages: {
    privacy: null,
    legal: null,
    cookies: null,
  },
};

const configReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TEXTUAL_PAGES: {
      const data = action.payload;
      let auxState = {
        privacy: null,
        legal: null,
        cookies: null,
      };
      if (data !== []) {
        for (item in data) {
          if (item.type === "ticket") {
            switch (item.page) {
              case "Privacy": {
                auxState.privacy = item.text || null;
              }
              case "Cookies": {
                auxState.cookies = item.text || null;
              }
              case "Legal": {
                auxState.legal = item.text || null;
              }
            }
          }
        }
      }

      return { ...state, textualPages: auxState };
    }
    case FETCH_TEXTUAL_PRIVACY: {
      const data = action.payload;
      let auxPrivacy = state.textualPages;

      return {
        ...state,
        textualPages: {
          ...auxPrivacy,
          privacy: data.text || null,
        },
      };
    }

    case FETCH_TEXTUAL_LEGAL: {
      const data = action.payload;
      let auxLegal = state.textualPages;

      return {
        ...state,
        textualPages: {
          ...auxLegal,
          legal: data.text || null,
        },
      };
    }

    case FETCH_TEXTUAL_COOKIES: {
      const data = action.payload;
      let auxCookies = state.textualPages;

      return {
        ...state,
        textualPages: {
          ...auxCookies,
          cookies: data.text || null,
        },
      };
    }

    default:
      return state;
  }
};

export default configReducer;
