import React from "react";
import { StyleSheet, View, ActivityIndicator } from "react-native";

// Constants
import { colors } from "../../../../constants/theme";

const LoadingScreen = () => (
  <View style={styles.container}>
    <ActivityIndicator size="small" color={colors.primaryColor} />
  </View>
);

export default LoadingScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white"
  },
});