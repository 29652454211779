import React from "react";
import { Text, View, StyleSheet } from "react-native";

// Contants
import { colors, family } from "../../../../../constants/theme";

const ClosedTag = () => {
  return (
    <View style={styles.tag}>
      <Text style={styles.text}>Cerrado</Text>
    </View>
  );
};

export default ClosedTag;

const styles = StyleSheet.create({
  tag: {
    borderRadius: 8,
    backgroundColor: colors.lightPrimary,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    flexShrink: 1,
    flexGrow: 0,
    paddingVertical: 3,
    paddingHorizontal: 6,
  },
  text: {
    fontFamily: family.normal,
    fontSize: 14,
    color: colors.white,
  },
});
