import React from "react";
import { StyleSheet, Text, View, Image } from "react-native";

// Components
import Divider from "../../../../components/atoms/Divider";

// Constants
import { colors, family } from "../../../../constants/theme";
import ClosedTag from "./ClosedTag";
import ScheduleTag from "./ScheduleTag";
import TimeLine from "../../../events/EventScreen/components/ShopItem/TimeLine";

const Header = ({ data }) => {
  const { orderTypes, opened, name, image, schedules } = data;
  const canSchedule =
    !opened && schedules.openToday && orderTypes.includes("Scheduled");
  const isOpen = (opened && schedules.openToday) || canSchedule;

  return (
    <View style={styles.container}>
      <Image style={styles.image} source={{ uri: image }} />
      <View style={styles.content}>
        <View style={styles.tagsContainer}>
          {!isOpen && <ClosedTag />}
          {canSchedule && <ScheduleTag />}
        </View>
        <Divider height={10} />
        <Text style={styles.title}>{name}</Text>
        {/* <View style={styles.schedulesContainer}>
          <TimeLine schedules={schedules} />
        </View> */}
      </View>
    </View>
  );
};

export default Header;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: 130,
    flexDirection: "row",
  },
  tagsContainer: {
    display: "flex",
    alignItems: "flex-start",
  },
  schedulesContainer: {
    flexDirection: "row",
  },
  image: {
    resizeMode: "cover",
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
  },
  content: {
    backgroundColor: "rgba(0,0,0,0.45)",
    flex: 1,
    height: "100%",
    paddingVertical: 12,
    paddingHorizontal: 30,
    justifyContent: "flex-end",
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    fontFamily: family.bold,
    color: colors.white,
    fontSize: 22,
  },
  text: {
    fontFamily: family.normal,
    color: colors.white,
    fontSize: 14,
  },
});
