import { baseVersion } from "../../../../../../../config";
import { URlManagement } from "../../../../../../constants/urls";

const fetchDiscount = async ( userGid, code ) => {
  try {
    const url = `${URlManagement}/discount/getOfUser/${userGid}?code=${code}`;
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Connection: "keep-alive",
        "X-Client-Version": baseVersion,
      },
      method: "GET",
    });

    const resData = await response.json();
    if (resData.message === "success") {
      return resData.data;
    } else {
      return null;
    }
  } catch (err) {
    console.log("error fetching event:", err);
  }
};

export default fetchDiscount
