import React from "react";
import { StyleSheet, View } from "react-native";

// Components
import CustomModal from "../../../../../components/atoms/CustomModal";

const Wrapper = ({ visible, setModal, canClose, children }) => {
  return (
    <CustomModal
      modalVisible={visible}
      handleModal={() => setModal(false)}
    >
      <View style={styles.container}>{children}</View>
    </CustomModal>
  );
};

export default Wrapper;

const styles = StyleSheet.create({
  container: {
    minHeight: 200,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
});
