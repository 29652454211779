import { getMessaging, getToken } from "firebase/messaging";
import * as Device from "expo-device";
import * as Notifications from "expo-notifications";
import { Platform } from "react-native";
import { getLocalData, storeLocalData } from "./miscelanusUtils";
import { firebaseApp } from "../services/firebase";

export const cargarNotificacionesWeb = async () => {
  const messaging = getMessaging(firebaseApp);

  Notifications.setNotificationHandler({
    handleNotification: async () => ({
      shouldShowAlert: true,
      shouldPlaySound: true,
      shouldSetBadge: true,
    }),
  });

  const permission = await Notification.requestPermission();
  if (permission) {
    const hasBeenAsked = await getLocalData("notifAsked");
    storeLocalData("notifAsked", true);
    if (permission === "granted") {
      const currentToken = await getToken(messaging, {
        vapidKey:
          "BJvbAFtVDBZxWfJJUDOYg8pKQgy68UWVXXGA6vCRjh8h5LXDpa7FJcqyV32GzboZo4Qntv2r4vosiMFI26NXMN8",
      });
      if (currentToken) {
        return currentToken;
      } else {
        // Show permission request UI
        console.log(
          "No registration token available. Request permission to generate one."
        );
        // ...
      }
    } else {
      if (hasBeenAsked !== true) {
        alert(
          `¡Activa las notificaciones!\nLas notificaciones sirven para estar al tanto de los cambios en tu pedido`
        );
        Notification.requestPermission();
      }
    }
  }
};

export const registerForPushNotificationsAsync = async () => {
  let token;
  if (Device.isDevice) {
    const { status: existingStatus } =
      Platform.OS === "ios"
        ? await Notifications.requestPermissionsAsync({
            ios: {
              allowAlert: true,
              allowBadge: true,
              allowSound: true,
              allowAnnouncements: true,
            },
          })
        : await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== "granted") {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== "granted") {
      // alert("No se cuenta con permisos de notificación");
      console.log("No se cuenta con permisos de notificación");
      return;
    } else {
      const appConfig = require("../../app.json");
      const projectId = appConfig?.expo?.extra?.eas?.projectId;
      token = (
        await Notifications.getExpoPushTokenAsync({
          projectId,
        })
      ).data;
    }
    Notifications.addNotificationResponseReceivedListener((response) => {
      //cuando se pulsa el la notificacion
      if (global.redirecToPedidos != undefined) {
        global.redirecToPedidos();
      }
    });
  } else {
    alert("Must use physical device for Push Notifications");
  }

  if (Platform.OS === "android") {
    Notifications.setNotificationChannelAsync("default", {
      name: "default",
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: "#FF231F7C",
    });
  }
  return token;
};
