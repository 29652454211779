import React, { useState } from "react";
import { StyleSheet, View } from "react-native";

// Components
import Screen from "../../../components/atoms/Screen";
import OptionsStep from "./OptionsStep";
import PrivacyText from "./PrivacyText";
import TermsText from "./TermsText";
import LegalAdviceText from "./LegalAdviceText";

const ProfileLegalScreen = () => {
  const [step, setStep] = useState(0);
  return (
    <Screen>
      <View style={styles.content}>
        {step === 0 && <OptionsStep setStep={setStep} />}
        {step === 1 && <LegalAdviceText setStep={setStep} />}
        {step === 2 && <PrivacyText setStep={setStep} />}
        {step === 3 && <TermsText setStep={setStep} />}
      </View>
    </Screen>
  );
};

export default ProfileLegalScreen;

const styles = StyleSheet.create({
  content: {
    padding: 18,
  },
});
