import React from "react";
import { StyleSheet, Text, View, Image } from "react-native";

// Components
import AccordionListItem from "../../../components/atoms/AccordionListItem";
import TitleRow from "./TitleRow";

// Constants
import { colors, family } from "../../../constants/theme";

const LocationLine = ({ location }) => {
  const qrText = "Muestra el QR cuando te atiendan para recoger tu pedido";
  const locationText = "Ver ubicación de recogida";
  return (
    <View style={styles.container}>
      <TitleRow name={"Detalles de entrega"} />
      <Text style={styles.text}>{qrText}</Text>
      {location && (
        <AccordionListItem
          title={
            <View style={styles.locationTextContainer}>
              <Text style={styles.accentStyle}>{locationText}</Text>
            </View>
          }
          hasIcon={false}
          backgroundColor="transparent"
          style={{ padding: 0 }}
        >
          <Image style={styles.image} source={{ uri: location }} />
        </AccordionListItem>
      )}
    </View>
  );
};

export default LocationLine;

const styles = StyleSheet.create({
  container: {
    width: "100%",
  },
  locationTextContainer: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  accentStyle: {
    fontFamily: family.bold,
    fontSize: 14,
    color: colors.green,
  },
  image: {
    width: "100%",
    resizeMode: "cover",
    height: 400,
  },
  text: {
    fontFamily: family.normal,
    fontSize: 14,
    color: colors.brown,
  },
});
