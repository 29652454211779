import React from "react";
import { Feather } from "@expo/vector-icons";
import { Text, View } from "react-native";
import StyleSheet from "react-native-media-query";

// Components
import Divider from "../../../../../components/atoms/Divider";

// Constants
import { colors, family } from "../../../../../constants/theme";

const TimeLine = ({ schedules, bold = true }) => {
  const schedulesAvailable =
    typeof schedules !== undefined &&
    schedules !== null &&
    !Array.isArray(schedules);
  const { day, hours, openToday } = schedules;

  if (!schedulesAvailable) {
    return <View></View>;
  }
  return (
    <View style={styles.container}>
      <View style={styles.row}>
        <Feather name="clock" size={14} color={colors.white} />
        <Divider width={5} />
        <Text
          style={bold ? styles.boldText : styles.text}
          dataSet={{ media: ids.text }}
        >
          {!openToday && day + " "}
          {hours.map((schedule) => schedule + " ").join(" / ")}
        </Text>
      </View>
    </View>
  );
};

export default TimeLine;

const { ids, styles } = StyleSheet.create({
  container: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    flexShrink: 1,
    flexGrow: 0,
    paddingVertical: 3,
  },
  boldText: {
    fontFamily: family.bold,
    fontSize: 14,
    textAlign: "left",
    color: colors.white,
    
  },
  text: {
    fontFamily: family.normal,
    textAlign: "center",
    fontSize: 14,
    color: colors.white,
    
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
});
