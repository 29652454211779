import { environment } from "../../config";
import { adyenClientKey } from "../constants/urls";
import { applePayConfiguration } from "./applePayConfig";

export const paymentConfig = (totalToPay, session) => {
  const appleConfig = applePayConfiguration(session);
  return {
    error: "",
    session: "",
    orderRef: null,
    paymentDataStoreRes: null,
    config: {
      storePaymentMethod: true,
      paymentMethodsConfiguration: {
        storedCard: {
          hideCVC: true,
        },
        card: {
          enableStoreDetails: true,
          name: "Pago con tarjeta",
          amount: {
            value: totalToPay,
            currency: "EUR",
          },
          hasHolderName: true,
          holderNameRequired: true,
          clickToPayConfiguration: {
            merchantDisplayName: "GOPICKAPP_MKT",
            shopperEmail: session?.shopperEmail,
          },
        },
        dropin: {
          showPaymentMethods: true,
        },
        applepay: appleConfig,
        googlePay: { buttonSizeMode: "fill", buttonType: "pay" },
      },

      locale: "es-ES",
      showPayButton: true,
      clientKey: adyenClientKey,
      environment: environment === "dev" ? "test" : "live",
    },
  };
};
