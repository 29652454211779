import React from "react";
import { TouchableOpacity } from "react-native";
import { StyleSheet, Text } from "react-native";

// Constans
import { colors, family } from "../../../../../constants/theme";

const ServiceItem = ({ selected, service, onPress }) => {
  return (
    <TouchableOpacity
      style={[
        styles.container,
        selected && { backgroundColor: colors.secondary },
      ]}
      onPress={onPress}
    >
      <Text style={[styles.text, selected && { color: colors.white }]}>
        {service.label}
      </Text>
    </TouchableOpacity>
  );
};

export default ServiceItem;

const styles = StyleSheet.create({
  container: {
    borderRadius: 100,
    paddingHorizontal: 16,
    paddingVertical: 8,
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    minWidth: 120
  },
  text: {
    fontFamily: family.normal,
    fontSize: 14,
    color: colors.brown,
  },
});
