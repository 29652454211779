import AsyncStorage from "@react-native-async-storage/async-storage";
import { baseVersion } from "../../config";
import { URLbase } from "../constants/urls";

export const storeLocalData = async (name, data) => {
  try {
    AsyncStorage.setItem(name, JSON.stringify(data));
  } catch (err) {
    console.log("error: storing", name, ": ", err);
  }
};

export const getLocalData = async (name) => {
  try {
    const value = await AsyncStorage.getItem(name);
    if (value !== null) {
      return JSON.parse(value);
    }
  } catch (err) {
    console.log("error fetching local data, ", name, err);
    return null;
  }
};

export const getIcon = (name) => {
  switch (name) {
    case "card":
      return "credit-card";
    case "apple_pay":
      return "apple";
    case "bizum":
      return "dollar";
    case "google_pay":
      return "google";
    case "cash":
      return "dollar";
    case "dataphone":
      return "money";
    default:
      return "dollar";
  }
};

export const fetchVersion = async () => {
  try {
    const response = await fetch(
      `${URLbase}/v2/constant/APP_MIN_SUPPORTED_VERSION`,
      {
        headers: {
          "Content-Type": "application/json",
          "X-Client-Version": baseVersion,
        },
        method: "GET",
      }
    );

    const resData = await response.json();
    if (resData.status === "success") {
      return { status: resData.status, value: resData.data.value };
    } else {
      return { status: resData.status, message: resData.message };
    }
  } catch (error) {
    console.log("[error fetchVersion] ", JSON.stringify(error));
    return { status: "fail", message: error.message };
  }
};

export const compareVersions = (v1 = "", v2 = "") => {
  const parts1 = v1.split(".");
  const parts2 = v2.split(".");

  for (let i = 0; i < Math.max(parts1.length, parts2.length); i++) {
    const num1 = parseInt(parts1[i]) || 0;
    const num2 = parseInt(parts2[i]) || 0;

    if (num1 > num2) return 1;
    else if (num2 > num1) return -1;
  }

  return -1;
};
