import React from "react";
import { StyleSheet, View } from "react-native";

// Components
import CustomButton from "../../../../../components/atoms/CustomButton";
import Divider from "../../../../../components/atoms/Divider";

// Contants
import { colors } from "../../../../../constants/theme";

const Actions = ({ onCancel, onAccept, canCancel, canAccept }) => {
  return (
    <View style={styles.container}>
      {canCancel && (
        <>
          <CustomButton
            active
            color={colors.white}
            textColor={colors.primaryColor}
            title={"Cancelar"}
            onPress={onCancel}
          />
          <Divider width={20} />
        </>
      )}
      <CustomButton active={canAccept} title={"Aceptar"} onPress={onAccept} />
    </View>
  );
};

export default Actions;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexDirection: "row",
  },
});
