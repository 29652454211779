import React from "react";
import { ImageBackground, View, Text } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import StyleSheet from "react-native-media-query";

// Components
import TimeLine from "../../EventScreen/components/ShopItem/TimeLine";
import BackButton from "../../../orders/MyProductsScreen/components/BackButton";
import Divider from "../../../../components/atoms/Divider";
import WaitTimeTag from "./WaitTimeTag";

// Constants
import { colors, family } from "../../../../constants/theme";

const Header = ({ navigation, data, servicePicker }) => {
  const { name, image, services, schedules, waitTime } = data;
  const isPrecompras = services.some((service) => service.service_id === 4);

  return (
    <ImageBackground style={styles.container} source={{ uri: image }}>
      <BackButton navigation={navigation} />
      <WaitTimeTag waitTime={waitTime} />
      <View style={styles.content} dataSet={{ media: ids.content }}>
        <Text style={styles.title} dataSet={{ media: ids.title }}>
          {name}
        </Text>
        {!isPrecompras && <TimeLine schedules={schedules} bold={false} />}
        <Divider height={5} />
        {servicePicker}
        <Divider height={10} />
      </View>
      <LinearGradient
        colors={["transparent", "rgba(0,0,0,1)"]}
        style={styles.gradient}
        start={{ x: 0, y: 0 }}
        end={{ x: 0, y: 1.1 }}
      />
    </ImageBackground>
  );
};

export default Header;

const { ids, styles } = StyleSheet.create({
  container: {
    width: "100%",
    maxWidth: 440,
    height: 180,
    resizeMode: "cover",
    position: "relative",
  },
  gradient: {
    width: "100%",
    maxWidth: 440,
    height: "100%",
    resizeMode: "cover",
    position: "absolute",
    zIndex: 1,
  },
  content: {
    width: "100%",
    height: "100%",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingHorizontal: 36,
    zIndex: 2,
    "@media (max-height: 700px) or (max-width: 340px)": {
      paddingHorizontal: 18,
    },
  },
  title: {
    fontSize: 24,
    fontFamily: family.bold,
    color: colors.white,
    textAlign: "center",
    "@media (max-height: 700px) or (max-width: 340px)": {
      fontSize: 16,
    },
  },
  description: {
    fontSize: 14,
    fontFamily: family.normal,
    color: colors.white,
    textAlign: "center",
    "@media (max-height: 700px) or (max-width: 340px)": {
      fontSize: 12,
    },
  },
});
