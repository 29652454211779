import React from "react";
import { ScrollView, useWindowDimensions } from "react-native";
import { ActivityIndicator } from "react-native";
import { Platform } from "react-native";
import { View } from "react-native";
import StyleSheet from "react-native-media-query";

// Components
import CustomButton from "../../../atoms/CustomButton";
import CustomError from "../../../atoms/CustomError";
import HtmlNative from "../../../atoms/HtmlNative";
import Title from "../../../atoms/Title";

const LegalPage = ({ legalText, title, customStyle, error, setStep }) => {
  const height = useWindowDimensions().height;

  return (
    <View
      style={[
        styles.container,
        Platform.OS === "web" && { height: height - 200 },
      ]}
    >
      <Title color={customStyle.textColor} title={title} />
      <ScrollView
        style={{
          marginVertical: 10,
          paddingHorizontal: 18,
          flex: 1,
          width: "100%",
        }}
        showsVerticalScrollIndicator={false}
      >
        {legalText?.length > 0 ? (
          <HtmlNative
            value={legalText}
            style={`p{color: ${customStyle.textColor}; font-family: 'Raleway', sans-serif}`}
          />
        ) : error ? (
          <CustomError error={error} />
        ) : (
          <ActivityIndicator size="small" color={customStyle.backgroundColor} />
        )}
      </ScrollView>

      <View
        style={styles.buttonContainer}
        dataSet={{ media: ids.buttonContainer }}
      >
        <CustomButton
          active
          textColor={customStyle.buttonTextColor}
          color={customStyle.backgroundColor}
          title={"Acepto"}
          onPress={() => {
            setStep(0);
          }}
        />
      </View>
    </View>
  );
};

export default LegalPage;

const { ids, styles } = StyleSheet.create({
  container: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonContainer: {
    width: "100%",
    height: 70,
    "@media (max-height: 700px) or (max-width: 340px)": {
      height: 32,
    },
  },
});
