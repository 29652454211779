import React, { useState, useEffect } from "react";
import { Text, View, Image, Platform } from "react-native";
import StyleSheet from "react-native-media-query";

// Constants
import { family } from "../../constants/theme";
import TicketScreen from "../../components/tickets/components/TicketScreen";
import { URlTextualBase } from "../../constants/urls";
import Title from "../../components/atoms/Title";
import { ScrollView } from "react-native";
import { ActivityIndicator } from "react-native";
import HtmlNative from "../../components/atoms/HtmlNative";
import { TouchableOpacity } from "react-native";
import i18n from "../../i18n/i18n";
import { baseVersion } from "../../../config";

const TicketPrivacyScreen = ({ navigation }) => {
  const [legalText, setLegalText] = useState();
  const [status, setStatus] = useState("Loading");

  useEffect(() => {
    const getData = async () => {
      setStatus("Loading");
      try {
        const response = await fetch(
          `${URlTextualBase}/get/ticket/privacy`,
          {
            headers: {
              "Content-Type": "application/json",
              Connection: "keep-alive",
              "X-Client-Version": baseVersion,
            },
            method: "GET",
          }
        );

        const resData = await response.json();
        setLegalText(resData?.text);
      } catch (err) {
        console.log("error fetching legal text: ", err);
        setStatus(i18n.t("tickets.error.default"));
      } finally {
        setStatus("Success");
      }
    };

    getData();
  }, []);

  return (
    <TicketScreen navigation={navigation}>
      <Image
        style={styles.heroImage}
        source={require("../../assets/concert2.webp")}
      />
      <View style={styles.heroContent} dataSet={{ media: ids.heroContent }}>
        <View style={styles.wrapper}>
          <View style={{ width: "100%", alignItems: "center", marginTop: 20 }}>
            <Text style={styles.title} dataSet={{ media: ids.title }}>
              {i18n.t("tickets.privacy")}
            </Text>
          </View>
          <ScrollView
            style={{
              marginVertical: 10,
              paddingHorizontal: 18,
              height: "100%",
            }}
            showsVerticalScrollIndicator={false}
          >
            {status === "Loading" ? (
              <ActivityIndicator size="small" color="white" />
            ) : status === "Error" ? (
              <Text style={styles.text}>{status}</Text>
            ) : (
              <HtmlNative
                value={legalText}
                style={"p{color: white; font-family: 'Raleway', sans-serif}"}
              />
            )}
          </ScrollView>
        </View>
      </View>
    </TicketScreen>
  );
};

export default TicketPrivacyScreen;

const { ids, styles } = StyleSheet.create({
  heroContent: {
    paddingTop: 75,
    width: "100%",
    paddingHorizontal: 40,
    justifyContent: "center",
    alignItems: "center",
    zIndex: 2,
    "@media (max-width: 500px)": {
      paddingHorizontal: 0,
    },
  },
  wrapper: {
    marginTop: 50,
    width: "100%",
    maxWidth: 1440,
    backgroundColor: "rgba(0,0,0,0.8)",
    borderRadius: 20,
  },
  heroImage: {
    width: "100%",
    height: "100%",
    position: Platform.OS === "web" ? "fixed" : "relative",
    top: 0,
    resizeMode: "cover",
    zIndex: 1,
  },
  text: {
    fontFamily: family.normal,
    fontSize: 14,
    color: "white",
  },
  title: {
    fontFamily: family.bold,
    fontSize: 18,
    color: "white",
    "@media (max-width: 500px)": {
      fontSize: 12,
    },
  },
  buttonContainer: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 18,
  },
  button: {
    width: "100%",
    maxWidth: 200,
    height: 46,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: 10,
    "@media (max-width: 400px)": {
      maxWidth: 100,
      height: 30,
    },
  },
  buttonText: {
    fontFamily: family.bold,
    fontSize: 18,
    textAlign: "center",
    color: "white",
    "@media (max-width: 400px)": {
      fontSize: 12,
    },
  },
});
