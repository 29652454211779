import React from "react";
import { StyleSheet, Image, Text, View, Platform } from "react-native";
import { connect } from "react-redux";

// Constants
import { colors, family } from "../../../constants/theme";

const Header = ({ name }) => {
  const text =
    name? `¡Hola, ${name.charAt(0).toUpperCase() + name.slice(1)}!` : "¡Hola!";
  return (
    <View style={styles.container}>
      <Image
        style={styles.image}
        source={require("../../../assets/profilebg.png")}
      />
      <View style={styles.content}>
        <Text style={styles.text}>{text}</Text>
      </View>
    </View>
  );
};

const mapStateToProps = (state) => {
  return {
    name: state.user.user.name,
  };
};

export default connect(mapStateToProps)(Header);

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: Platform.select({ ios: 200, android: 180, web: 160 }),
  },
  image: {
    width: "100%",
    height: "100%",
    resizeMode: "cover",
    position: "absolute",
    top: 0,
  },
  content: {
    width: "100%",
    height: "100%",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    padding: 18,
  },
  text: {
    fontFamily: family.bold,
    fontSize: 28,
    color: colors.white,
  },
});
