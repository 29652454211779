import React, { useState, useEffect } from "react";
import StyleSheet from "react-native-media-query";
import { Text, View, Image, useWindowDimensions } from "react-native";

// Components
import Divider from "../../atoms/Divider";
import TicketProductVariantsModal from "../modals/TicketProductVariantsModal/TicketProductVariantsModal";

// Constants
import { family, size } from "../../../constants/theme";

// Utils
import {
  getFormattedPrice,
  getIngredientsText,
} from "../../../utils/productMethods";
import { getVariantsText } from "../../../utils/variantsMethods";
import IconButtonTickets from "./IconButtonTickets";
import TicketWarningModal from "../modals/TicketWarningModal";

const TicketProductItem = ({ data, onRemove, onAdd, productLines }) => {
  const {
    gid,
    name,
    amount,
    product_type,
    ingredients,
    variants,
    long_description,
    image,
    short_description,
  } = data;
  const [qty, setQty] = useState(0);
  const [modal, setModal] = useState();
  const [otherProducts, setOtherProducts] = useState([]);
  const width = useWindowDimensions().width;

  const getOtherProductsQuantity = () => {
    let count = 0;
    otherProducts.map((product) => {
      count += product.quantity;
    });
    return count;
  };

  const addHandler = (data) => {
    const otherProductsQty = getOtherProductsQuantity();
    if (
      isComplex() === true ||
      (long_description !== undefined &&
        long_description !== null &&
        long_description !== "")
    ) {
      setModal("Variants");
    } else {
      if (data.sold_out === 0) {
        if (qty + otherProductsQty >= data?.stock) {
          setModal("Max");
        } else {
          setQty(qty + 1);
          onAdd({ data: data });
        }
      }
    }
  };

  const formedProductAddHandler = (product) => {
    if (data.sold_out === 0) {
      if (product.quantity >= data?.stock) {
        setModal("Max");
      } else {
        onAdd({
          data: product,
          variants: product.variants,
          ingredients: product.ingredients,
        });
      }
    }
  };

  const removeHandler = (data) => {
    if (qty > 0) {
      setQty(qty - 1);
      onRemove(data);
    }
  };

  useEffect(() => {
    let auxProducts = [];
    productLines?.map((product) => {
      if (product.gid === gid) {
        if (product.type === "simple") {
          setQty(product.quantity);
        } else {
          auxProducts.push(product);
        }
      }
    });

    setOtherProducts(auxProducts);
  }, [productLines]);

  const isComplex = () => {
    if (product_type === "individual") {
      if (variants) {
        if (variants?.length > 0 && variants[0].gid) {
          return true;
        }
      } else {
        if (ingredients) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      if (product_type !== "individual") {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <View style={{ flexDirection: "column" }}>
      <View
        style={[
          styles.container,
          { marginTop: 10 },
          otherProducts.length > 0 && {
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
          },
        ]}
        dataSet={{ media: ids.container }}
      >
        {data.sold_out === 1 ||
          (data?.stock === 0 && (
            <View style={styles.imageContainer}>
              <Image
                style={styles.soldOut}
                source={require("../../../assets/sold_out.png")}
              />
            </View>
          ))}
        {image !== null && image !== undefined && width > 300 && (
          <Image
            style={[
              styles.imageWrapper,
              otherProducts.length > 0 && {
                borderBottomLeftRadius: 0,
              },
            ]}
            source={{ uri: image }}
          />
        )}
        <View
          style={[
            styles.infoContainer,
            data.sold_out === 1 && {
              paddingLeft: data.image !== null ? 10 : 50,
            },
            data.sold_out === 1 && width < 300 && { paddingLeft: 50 },
          ]}
        >
          <Text
            numberOfLines={2}
            style={styles.title}
            dataSet={{ media: ids.title }}
          >
            {isComplex() === true
              ? `${name} (${getFormattedPrice(amount)})`
              : name}
          </Text>
          {short_description !== null && short_description !== undefined && (
            <Text
              numberOfLines={2}
              style={styles.text}
              dataSet={{ media: ids.text }}
            >
              {short_description}
            </Text>
          )}
        </View>
        <View style={styles.actionsContainer}>
          {isComplex() === true ? (
            <View
              style={styles.actionWrapper}
              dataSet={{ media: ids.actionWrapper }}
            >
              <IconButtonTickets
                color={"white"}
                textColor={"black"}
                icon="plus"
                onPress={() => addHandler(data)}
              />
            </View>
          ) : (
            <View
              style={styles.actionWrapper}
              dataSet={{ media: ids.actionWrapper }}
            >
              <View style={styles.priceContainer}>
                <Text style={styles.qtyText}>{getFormattedPrice(amount)}</Text>
              </View>
              <Divider height={10} />
              <View style={styles.actions} dataSet={{ media: ids.actions }}>
                <IconButtonTickets
                  color={"white"}
                  textColor={"black"}
                  icon="minus"
                  onPress={() => removeHandler(data)}
                />
                <View style={{ alignItems: "center", width: 30 }}>
                  <Text style={styles.qtyText}>{qty}</Text>
                </View>
                <IconButtonTickets
                  color={"white"}
                  textColor={"black"}
                  icon="plus"
                  onPress={() => addHandler(data)}
                />
              </View>
            </View>
          )}
        </View>
        <TicketProductVariantsModal
          visible={modal === "Variants"}
          setModal={setModal}
          data={data}
          onAdd={onAdd}
        />
        <TicketWarningModal
          setModal={setModal}
          visible={modal === "Max"}
          text={"Se ha alcanzado el máximo de existencias"}
        />
      </View>
      {otherProducts.map((product, index) => (
        <View
          key={product.hash}
          style={[
            styles.extraContainer,
            index + 1 === otherProducts.length && {
              borderBottomLeftRadius: 15,
              borderBottomRightRadius: 15,
            },
          ]}
          dataSet={{ media: ids.extraContainer }}
        >
          <View style={styles.infoContainer}>
            {product?.variants?.length > 0 && (
              <Text
                key={product.variants.variants}
                style={styles.text}
                dataSet={{ media: ids.text }}
              >
                {getVariantsText(product.variants).map((text, index) => (
                  <Text
                    key={index}
                    style={[
                      styles.text,

                      {
                        fontFamily:
                          text.type === "bold" ? family.bold : family.normal,
                      },
                    ]}
                    dataSet={{ media: ids.text }}
                  >
                    {text.content}
                    {"  "}
                  </Text>
                ))}
              </Text>
            )}
            {product.ingredients?.length > 0 && (
              <Text style={styles.text} dataSet={{ media: ids.text }}>
                {getIngredientsText(product.ingredients).map((text, index) => (
                  <Text
                    key={index}
                    dataSet={{ media: ids.text }}
                    style={[
                      styles.text,
                      {
                        fontFamily:
                          text.type === "bold" ? family.bold : family.normal,
                      },
                    ]}
                  >
                    {text.content}
                  </Text>
                ))}
              </Text>
            )}
          </View>
          <View style={styles.actionsContainer}>
            <View
              style={styles.actionWrapper}
              dataSet={{ media: ids.actionWrapper }}
            >
              <View style={styles.priceContainer}>
                <Text style={styles.qtyText} dataSet={{ media: ids.qtyText }}>
                  {getFormattedPrice(product.amount * product.quantity)}
                </Text>
              </View>
              <Divider height={10} />
              <View style={styles.actions} dataSet={{ media: ids.actions }}>
                <IconButtonTickets
                  color={"white"}
                  textColor={"black"}
                  icon="minus"
                  onPress={() => onRemove(product)}
                />
                <View style={{ alignItems: "center", width: 30 }}>
                  <Text style={styles.qtyText} dataSet={{ media: ids.qtyText }}>
                    {product.quantity}
                  </Text>
                </View>
                <IconButtonTickets
                  color={"white"}
                  textColor={"black"}
                  icon="plus"
                  onPress={() => formedProductAddHandler(product)}
                />
              </View>
            </View>
          </View>
        </View>
      ))}
    </View>
  );
};

export default TicketProductItem;

const { ids, styles } = StyleSheet.create({
  container: {
    width: 320,
    height: 80,
    borderRadius: 15,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0,0,0,0.7)",
    "@media (max-width: 700px)": {
      width: "100%",
    },
  },
  extraContainer: {
    width: 320,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0,0,0,0.7)",
    "@media (max-width: 700px)": {
      width: "100%",
    },
  },

  imageContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 10,
  },
  soldOut: {
    position: "absolute",
    top: 0,
    left: 0,
    height: 80,
    width: 80,
    borderBottomLeftRadius: 15,
    borderTopLeftRadius: 15,
  },
  imageWrapper: {
    height: 80,
    width: 80,
    borderBottomLeftRadius: 15,
    borderTopLeftRadius: 15,
  },
  qtyText: {
    fontSize: 14,
    fontFamily: family.normal,
    color: "white",
    "@media (max-width: 500px)": {
      fontSize: 10,
    },
  },
  infoContainer: {
    padding: 10,
    width: "100%",
    height: "100%",
    flex: 1,
    justifyContent: "space-evenly",
  },
  actionWrapper: {
    flexDirection: "column",
    alignItems: "center",
    "@media (max-width: 700px)": {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    "@media (max-width: 500px)": {
      flexDirection: "column",
    },
  },
  actionsContainer: {
    height: "100%",
    padding: 10,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  actions: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width: 700px)": {
      marginLeft: 10,
    },
    "@media (max-width: 500px)": {
      marginLeft: 0,
    },
  },
  priceContainer: {
    paddingTop: 2,
    justifyContent: "center",
    alignItems: "flex-start",
  },
  text: {
    fontFamily: family.normal,
    fontSize: 14,
    color: "white",
    "@media (max-width: 500px)": {
      fontSize: 10,
    },
  },
  title: {
    fontFamily: family.bold,
    fontSize: 14,
    color: "white",
    "@media (max-width: 500px)": {
      fontSize: 10,
    },
  },
});
