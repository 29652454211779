import React from "react";
import { StyleSheet, Platform, View, TouchableOpacity } from "react-native";
import { Feather } from "@expo/vector-icons";

// Components
import AddedInfoContainer from "./AddedInfoContainer";
import RemoveProductStatic from "./RemoveProductStatic";
import Divider from "../atoms/Divider";
import ActionButton from "./ActionButton";

// Constants
import { colors } from "../../constants/theme";

// Utils
import { getFormattedPrice, getSingleProductCost } from "../../utils/productMethods";

const AddedProduct = ({
  data,
  startAmount,
  onPress,
  quantity,
  onAction,
  customStyle,
}) => {
  const amount = getFormattedPrice(
    getSingleProductCost(startAmount, data.variants, data?.plates)
  );

  return (
    <TouchableOpacity
      onPress={onPress}
      style={[styles.container, { backgroundColor: customStyle?.darken1 }]}
    >
      <View style={styles.content}>
        <RemoveProductStatic
          quantity={quantity}
          onPress={onAction}
          customStyle={{
            ...customStyle,
            letter1: customStyle?.letter2,
          }}
        />
        <Divider width={12} />
        <AddedInfoContainer
          data={data}
          amount={amount}
          customStyle={customStyle}
        />
        <View style={styles.buttonContainer}>
          <ActionButton color={colors.lightenGreen} onPress={onPress} size={25}>
            <Feather name="plus" size={20} color={colors.lightGreen} />
          </ActionButton>
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default AddedProduct;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#F8F8F8",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    flex: 1,
    width: "100%",
    maxWidth: Platform.OS === "web" ? 400 : null,
    minHeight: 55,
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 18,
    paddingVertical: 10,
  },
  buttonContainer: {
    height: "100%",
    maxWidth: 60,
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
});
