export const getShopById = (orders, id) => {
  let shopIndex = -1;
  let shop;

  orders.map((orderShop, index) => {
    if (orderShop.id_shop === id) {
      shop = orderShop;
      shopIndex = index;
    }
  });

  return [shop, shopIndex];
};

export const getCommissionArray = (detailShop) => {
  let aux = [];
  if (
    detailShop.gopickCommission !== null &&
    detailShop.gopickCommission !== 0
  ) {
    aux.push({
      name: "Comisión Gopick",
      amount: detailShop.gopickCommission || 0,
      type: detailShop.typeGopickCommission || null,
    });
  }
  if (
    detailShop.waiterCommission !== null &&
    detailShop.waiterCommission !== 0
  ) {
    aux.push({
      name: "Comisión Camarero",
      amount: detailShop.waiterCommission || 0,
      type: detailShop.typeWaiterCommission || null,
    });
  }
  return aux;
};

export const hasLaterOrders = (shop) => {
  let result = false;
  shop?.services?.map((service) => {
    if (service.key === "no_service") {
      result = true;
    }
  });
  return result;
};
