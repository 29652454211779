import React from "react";
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ScrollView,
  ActivityIndicator,
} from "react-native";
import HtmlNative from "../../../components/atoms/HtmlNative";

// Constants
import { colors, family } from "../../../constants/theme";

const LegalContainer = ({ text, loading, setStep }) => {
  if (loading) {
    return (
      <View style={styles.container}>
        <ActivityIndicator size="small" color={colors.primaryColor} />
      </View>
    );
  }

  if (!text) {
    return (
      <View style={styles.container}>
        <Text style={styles.errorText}>No se pudo cargar el texto legal</Text>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <View style={styles.textContainer}>
        <ScrollView style={{ height: 1 }} showsVerticalScrollIndicator={false}>
          <HtmlNative
            value={text}
            style={
              "p{color: #424242; font-family: 'Raleway', sans-serif, font-size: 14, line-height:15}"
            }
            styleSheet={styles.text}
          />
        </ScrollView>
      </View>
      <View style={styles.backContainer}>
        <TouchableOpacity onPress={() => setStep(0)}>
          <Text style={styles.backText}>Volver</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default LegalContainer;

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  textContainer: { flex: 1 },
  backContainer: {
    height: 80,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    borderTopWidth: 1,
    borderTopColor: colors.grey,
  },
  backText: {
    fontSize: 18,
    color: colors.brown,
    fontFamily: family.bold,
  },
  text: {
    fontFamily: family.normal,
    fontSize: 12,
    lineHeight: 15,
    height: "auto",
  },
  errorText: {
    fontSize: 18,
    color: colors.brown,
    fontFamily: family.normal,
  },
});
