import { validGid } from "../../screens/events/HomeScreen/methods/validGid";
import { fetchPointSales } from "../pointSale/fetchPointSales";
import { getPointSalesArray } from "../pointSale/getPointSalesArray";
import { fetchShopData } from "../shop/fetchShopData";
import { isShopOpened } from "../shop/isShopOpened";
import { isShopScheduleable } from "../shop/isShopScheduleable";

export const navigateToShop = async ({
  gid,
  onError,
  navigation,
  onStart = () => {},
  location,
  addShop,
}) => {
  if (validGid(gid)) {
    const response = await fetchShopData(gid);
    const { status, data } = response;
    if (status === "success") {
      onStart();
      const { opened, name, gid, services, orderTypes } = data;

      const isPrecompras = services.some((service) => service.service_id === 4);
      const scheduleable = isShopScheduleable(data);
      const shopOpened = isShopOpened(data);

      const orderType = isPrecompras ? "Later" : orderTypes[0];
      if (shopOpened) {
        const pointSalesRes = await fetchPointSales(gid, opened, scheduleable);
        if (pointSalesRes.status === "success") {
          const pointSales = getPointSalesArray(
            pointSalesRes.data,
            isPrecompras,
            scheduleable
          );
          if (pointSales.length > 0) {
            addShop(data, orderType);
            navigation.navigate("EventsStack", {
              screen: "ShopScreen",
              params: {
                name: name,
                pointSales,
                shopId: gid,
                data: data,
                location,
              },
            });
          } else {
            onError(
              `La tienda ${name} no cuenta con productos disponibles en este momento`
            );
          }
        }
      } else {
        onError(`La tienda ${name} se encuentra cerrada`);
      }
    } else {
      onError("Tienda no encontrada");
    }
  }
};
