export function getArrayOfSchedules(schedule, shop) {
  const { time_ranges: timeRange, previousTimeScheduled } = shop;
  const [startHour, endHour] = schedule.split("-").map((hour) => hour.trim());
  const [hhStart, mmStart] = startHour.split(":").map(Number);
  const [hhEnd, mmEnd] = endHour.split(":").map(Number);

  const now = new Date();
  const currentDate = new Date(
    now.getTime() + (timeRange + previousTimeScheduled) * 60000
  );

  const auxHour = currentDate.getHours();
  const auxMinute = currentDate.getMinutes();
  const currentHour = +auxHour.toString().padStart(2, "0");
  const currentMinute = +auxMinute.toString().padStart(2, "0");

  const minutesStart = hhStart * 60 + mmStart;
  const minutesEnd = hhEnd * 60 + mmEnd;

  const schedules = [];

  for (let minutes = minutesStart; minutes < minutesEnd; minutes += timeRange) {
    const hh = Math.floor(minutes / 60);
    const mm = minutes % 60;

    if (hh < currentHour) {
      continue;
    } else if (hh === currentHour) {
      if (mm < currentMinute) {
        continue;
      }
    }
    const hour = `${hh.toString().padStart(2, "0")}:${mm
      .toString()
      .padStart(2, "0")}`;
    schedules.push(hour);
  }

  return schedules;
}
