import { combineReducers } from "redux";

import userReducer from "./user/userReducer";
import shopReducer from "./shops/shopReducer";
import ordersReducer from "./orders/ordersReducer";
import cartReducer from "./cart/cartReducer";
import configReducer from "./config/configReducer";
import ticketsReducer from "./tickets/ticketsReducer";
import laterCartReducer from "./laterCart/laterCartReducer";

const rootReducer = combineReducers({
  user: userReducer,
  shops: shopReducer,
  orders: ordersReducer,
  cart: cartReducer,
  config: configReducer,
  tickets: ticketsReducer,
  laterOrders: laterCartReducer,
});

export default rootReducer;
