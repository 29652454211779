import React, { useState, useCallback } from "react";
import { useFocusEffect } from "@react-navigation/native";
import {
  ScrollView,
  Text,
  View,
  StyleSheet,
  ActivityIndicator,
} from "react-native";

// Components
import Divider from "../../../../components/atoms/Divider";
import EventItem from "./EventItem";

// Constants
import { colors, family } from "../../../../constants/theme";

// Utils
import { fetchEventTypes } from "../../../../utils/event/fetchEventTypes";

const EventList = ({ navigation, events }) => {
  const [status, setStatus] = useState("idle");
  const [error, setError] = useState();
  const [eventTypes, setEventTypes] = useState([]);

  const getData = async () => {
    setStatus("loading");
    const response = await fetchEventTypes();
    const { status, data, error } = response;
    if (status === "fail") {
      setStatus("fail");
      setError(error);
    } else {
      setStatus("success");
      setEventTypes(data);
    }
  };

  useFocusEffect(
    useCallback(() => {
      getData();
    }, [])
  );

  if (status === "loading") {
    return <ActivityIndicator size="small" color={colors.primaryColor} />;
  }
  if (status === "fail") {
    <View style={styles.container}>
      <Text>{error}</Text>
    </View>;
  }
  return (
    <View style={styles.container}>
      <View style={styles.content}>
        {eventTypes.map((eventType) => {
          const { name, gid } = eventType;
          const list = events.filter((event) => event.eventTypeId === gid);
          if (list.length === 0) {
            return <View></View>;
          }
          return (
            <View key={name + gid} style={styles.event}>
              <View style={styles.titleContainer}>
                <Text style={styles.title}>{name}</Text>
              </View>
              <ScrollView showsHorizontalScrollIndicator={false} horizontal>
                <Divider width={18} />
                {list.map((item) => (
                  <View key={item?.gid} style={{ marginRight: 10 }}>
                    <EventItem data={item} navigation={navigation} />
                  </View>
                ))}
              </ScrollView>
              <Divider width={10} />
            </View>
          );
        })}
        <Divider height={100} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  title: {
    fontFamily: family.bold,
    fontSize: 18,
    color: colors.brown,
  },
  container: {
    width: "100%",
    alignItems: "center",
  },
  content: {
    width: "100%",
    maxWidth: 1200,
  },
  event: {
    marginBottom: 20,
  },
  titleContainer: {
    paddingLeft: 18,
    marginBottom: 10,
  },
});

export default EventList;
