import React from "react";
import {
  ImageBackground,
  TouchableOpacity,
  StyleSheet,
  Text,
} from "react-native";

// Components
import CustomModal from "../../../../components/atoms/CustomModal";

// Constants
import { colors, family, RADIUS, size } from "../../../../constants/theme";

const LiveModal = ({ data, setModal, modal, liveItemHandler }) => {
  const buttonHandler = () => {
    setModal(false);
    liveItemHandler(data);
  };

  return (
    <CustomModal
      size="window"
      modalVisible={modal}
      handleModal={() => setModal()}
      height={500}
      canClose
      closeColor={colors.primaryColor}
    >
      <ImageBackground
        source={{
          uri: data[0]?.popUp?.image,
        }}
        resizeMode="cover"
        imageStyle={{ borderRadius: RADIUS }}
        style={styles.background}
      ></ImageBackground>
      <TouchableOpacity style={styles.confirmButton} onPress={buttonHandler}>
        <Text style={[styles.confirmText, { color: data[0]?.popUp?.color }]}>
          Pedir
        </Text>
      </TouchableOpacity>
    </CustomModal>
  );
};

export default LiveModal;

const styles = StyleSheet.create({
  background: {
    width: "100%",
    height: "100%",
    borderRadius: RADIUS,
  },
  confirmButton: {
    width: "80%",
    height: 46,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.primaryColor,
    borderRadius: RADIUS,
    position: "absolute",
    bottom: 10,
  },
  confirmText: {
    color: "white",
    fontFamily: family.bold,
    fontSize: size.big,
  },
});
