import React from "react";
import { Text, StyleSheet } from "react-native";

// Constants
import { colors, family } from "../../../../../constants/theme";

const Title = ({ title }) => <Text style={styles.title}>{title}</Text>;

export default Title;

const styles = StyleSheet.create({
  title: {
    fontFamily: family.bold,
    color: colors.white,
    fontSize: 24,
  },
});
