import React, { useState, useEffect } from "react";

// Components
import LegalPage from "../components/LegalPage";

// Constants
import { URlTextualBase } from "../../../../constants/urls";
import { baseVersion } from "../../../../../config";

const PrivacySection = ({ sectionProps }) => {
  const { step, setStep, customStyle } = sectionProps;
  const [legalText, setLegalText] = useState(null);
  const [error, setError] = useState("");

  useEffect(() => {
    setError();
  }, [step]);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await fetch(`${URlTextualBase}/get/general/privacy`, {
          headers: {
            "Content-Type": "application/json",
            Connection: "keep-alive",
            "X-Client-Version": baseVersion,
          },
          method: "GET",
        });

        const resData = await response.json();

        setLegalText(resData?.text);
        setError();
      } catch (err) {
        setError(err.message);
        console.log("error fetching legal text: ", err);
      }
    };

    if (step === 3) {
      getData();
    }
  }, [step]);

  return (
    <LegalPage
      legalText={legalText}
      customStyle={customStyle}
      title="Política de privacidad"
      error={error}
      setStep={setStep}
    />
  );
};

export default PrivacySection;
