import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

// Components
import LegalContainer from "./LegalContainer";

// Redux
import { fetchTextualLegal } from "../../../redux/config/configActions";

const LegalAdviceText = ({ legal, setStep, fetchTextualLegal }) => {
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    setLoading(true);
    await fetchTextualLegal();
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <LegalContainer
      text={legal}
      loading={loading}
      setStep={setStep}
    />
  );
};

const mapStateToProps = (state) => {
  return { legal: state.config.textualPages.legal };
};

const mapDispatchToProps = {
  fetchTextualLegal,
};

export default connect(mapStateToProps, mapDispatchToProps)(LegalAdviceText);
