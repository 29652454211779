import React from "react";
import { useWindowDimensions, ScrollView } from "react-native";

// Components
import Screen from "../../../components/atoms/Screen";
import Divider from "../../../components/atoms/Divider";
import ConfirmButton from "./components/ConfirmButton";
import ShopItem from "./components/ShopItem";
import EventHeader from "./components/EventHeader";

const MyProductsScreen = ({ navigation, route }) => {
  const height = useWindowDimensions().height;

  const { shops, gid, name, expireAt } = route.params;

  return (
    <Screen screenColor={"white"}>
      <EventHeader title={name} expireAt={expireAt} navigation={navigation} />
      <ScrollView
        style={{ height: height - 100 }}
        showsVerticalScrollIndicator={false}
      >
        {shops?.map((item) => (
          <ShopItem data={{ ...item, eventId: gid }} key={item.gid} />
        ))}
        <Divider height={100} />
      </ScrollView>
      <ConfirmButton navigation={navigation} />
    </Screen>
  );
};

export default MyProductsScreen;
