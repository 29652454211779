import React, { useContext } from "react";
import { Platform } from "react-native";
import { createStackNavigator } from "@react-navigation/stack";

// Components
import BackHeader from "../../components/Header/BackHeader";

// Context
import { AuthContext } from "../../contexts/AuthContext";

// Screens
import ProfileHomeScreen from "../../screens/profile/ProfileHomeScreen";
import ProfileDataScreen from "../../screens/profile/ProfileDataScreen";
import ProfileAuthScreen from "../../screens/profile/ProfileAuthScreen";
import ProfileLegalScreen from "../../screens/profile/ProfileLegalScreen";


const Stack = createStackNavigator();

const ProfileStack = () => {
  const { user: firebaseUser } = useContext(AuthContext);
  return (
    <Stack.Navigator
      screenOptions={{
        title: "Gopick",
        animationEnabled: Platform.OS === "ios" ? true : false,
      }}
    >
      <Stack.Screen
        name="ProfileHomeScreen"
        options={{
          headerShown: false,
        }}
        component={!!firebaseUser ? ProfileHomeScreen : ProfileAuthScreen}
      />

      <Stack.Screen
        name="ProfileDataScreen"
        options={{
          header: (props) => <BackHeader title={"Mi perfil"} {...props} />,
        }}
        component={ProfileDataScreen}
      />

      <Stack.Screen
        name="ProfileLegalScreen"
        options={{
          header: (props) => <BackHeader title={"Información"} {...props} />,
        }}
        component={ProfileLegalScreen}
      />
    </Stack.Navigator>
  );
};

export default ProfileStack;
