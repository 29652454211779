import React from "react";
import { StyleSheet, View } from "react-native";

// Components
import Header from "./Header";

const Wrapper = ({ children, data }) => {
  return (
    <View style={styles.container}>
      <Header data={data} />
      <View style={styles.content}>{children}</View>
    </View>
  );
};

export default Wrapper;

const styles = StyleSheet.create({
  container: {
    width: "100%",
  },
  content: {
    paddingHorizontal: 18,
    paddingVertical: 14,
  },
});
