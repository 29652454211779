import React from "react";
import { StyleSheet, View, Platform } from "react-native";

// Components
import CustomButton from "../../../components/atoms/CustomButton";
import Title from "../../../components/atoms/Title";
import Animation from "../../../components/atoms/Animation";

// Constants
import { size, colors, family } from "../../../constants/theme";

// Assets
import error from "../../../assets/animations/error.json";

const PaymentError = ({ navigation, route }) => {
  const handleOut = () => {
    const from = route?.params?.from;
    if (from === "payment") {
      navigation.navigate("ConfirmOrderScreen");
    } else if (from === "events") {
      navigation.navigate("HomeScreen");
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.wrapper}>
        <View style={styles.content}>
          <Animation
            animation={error}
            name="error"
            loop={false}
            style={{ width: 200, height: 200, marginBottom: 50 }}
          />
          <Title
            color={colors.primaryColor}
            multiline
            title={"No se pudo finalizar el pago"}
          />
          {route?.params?.errorData && (
            <Title
              color={colors.primaryColor}
              multiline
              title={route?.params?.errorData}
            />
          )}
        </View>
        <CustomButton
          width="100%"
          active
          onPress={handleOut}
          title={`Aceptar`}
          alignSelf="center"
        />
      </View>
    </View>
  );
};

export default PaymentError;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    padding: 18,
    backgroundColor: colors.white,
  },
  wrapper: {
    width: "100%",
    height: "100%",
    maxWidth: Platform.OS === "web" ? 400 : null,
  },
  content: {
    paddingTop: 80,
    width: "100%",
    alignItems: "center",
    flex: 1,
    justifyContent: "space-around",
    backgroundColor: colors.white,
  },
  text: {
    fontSize: size.medium,
    color: colors.brown,
    fontFamily: family.bold,
    textAlign: "center",
  },
});
