import React from "react";
import {
  TouchableOpacity,
  StyleSheet,
  useWindowDimensions,
} from "react-native";

// Components
import EventImage from "./EventImage";

const Wrapper = ({ onPress, image, children }) => {
  const width = useWindowDimensions().width;
  const itemWidth = width < 376 ? width * 0.8 : 320;
  return (
    <TouchableOpacity
      activeOpacity={0.7}
      style={[styles.wrapper, { width: itemWidth }]}
      onPress={onPress}
    >
      <EventImage image={image} />
      {children}
    </TouchableOpacity>
  );
};
export default Wrapper;

const styles = StyleSheet.create({
  wrapper: {
    height: 480,
    width: 320,
    borderRadius: 15,
  },
});
