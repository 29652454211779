import React from "react";
import {
  StyleSheet,
  Platform,
  ActivityIndicator,
  TouchableOpacity,
  View,
} from "react-native";

// Components
import CustomText from "../../../../components/atoms/CustomText";

// Constants
import { colors } from "../../../../constants/theme";

const SkipButton = ({ onPress, loading }) => {
  if (loading) {
    return (
      <View style={styles.header}>
        <ActivityIndicator size="small" color={colors.primaryColor} />
      </View>
    );
  }
  return (
    <View style={styles.header}>
      <TouchableOpacity onPress={onPress}>
        <CustomText bold color={colors.primaryColor} text="Omitir" />
      </TouchableOpacity>
    </View>
  );
};

export default SkipButton;

const styles = StyleSheet.create({
  header: {
    justifyContent: "flex-end",
    alignItems: "flex-end",
    width: "100%",
    position: "absolute",
    top: Platform.OS === "android" ? 10 : 36,
    left: -18,
    zIndex: 3,
  },
});
