import React from "react";
import { Text, StyleSheet, TouchableOpacity } from "react-native";

// Constants
import { family } from "../../../../../../constants/theme";

const ScheduleItem = ({ onPress, selected, customStyle, text }) => {
  const { backgroundColor, primaryColor, textColor } = customStyle;

  const containerStyle = [
    styles.container,
    selected
      ? { backgroundColor: primaryColor, borderColor: primaryColor }
      : { backgroundColor: backgroundColor, borderColor: textColor },
  ];
  const textStyle = [
    styles.text,
    { color: selected ? backgroundColor : textColor },
  ];
  return (
    <TouchableOpacity onPress={onPress} style={containerStyle}>
      <Text style={textStyle}>{text}</Text>
    </TouchableOpacity>
  );
};

export default ScheduleItem;

const styles = StyleSheet.create({
  container: {
    width: 80,
    height: 30,
    borderRadius: 6,
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    marginBottom: 10,
  },
  text: {
    fontFamily: family.normal,
    fontSize: 10,
  },
});
