import React from "react";
import StyleSheet from "react-native-media-query";
import { Platform } from "react-native";
import { Text } from "react-native";

// Constants
import { family } from "../../constants/theme";

const ProductName = ({ name, amount, color }) => {
  return (
    <Text
      dataSet={{ media: ids.name }}
      numberOfLines={2}
      style={[
        styles.name,
        { color: color },
        Platform.OS === "web" && { lineHeight: 12 },
      ]}
    >
      {name} {amount && "(" + amount + ")"}
    </Text>
  );
};

export default ProductName;

const { ids, styles } = StyleSheet.create({
  name: {
    fontSize: 14,
    fontFamily: family.bold,
  },
});
