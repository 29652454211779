import { validGid } from "../../screens/events/HomeScreen/methods/validGid";
import { fetchEventData } from "../event/fetchEventData";
import { fetchShopsByEvent } from "../event/fetchShopsByEvent";
import { navigateToShop } from "./navigateToShop";

export const navigateToEvent = async ({
  gid,
  onError,
  onStart = () => {},
  navigation,
  addShop,
}) => {
  if (validGid(gid)) {
    const eventData = await fetchEventData(gid);
    const shops = await fetchShopsByEvent(gid);
    const { status, data } = shops;
    if (status === "success") {
      onStart();
      switch (data.length) {
        case 0:
          onError("El evento no cuenta con ninguna tienda");
          break;
        case 1:
          navigateToShop({ gid: data[0].gid, onError, navigation, addShop });
          break;
        default:
          navigation.navigate("EventsStack", {
            screen: "EventScreen",
            params: {
              name: eventData.data.name,
              eventData: eventData.data,
              eventId: eventData.data?.gid,
            },
          });
      }
    } else {
      onError("Error al obtener los datos del evento");
    }
  }
};
