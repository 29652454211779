import React from "react";
import { StyleSheet, View, ScrollView } from "react-native";

// components
import Divider from "../../../../components/atoms/Divider";
import ShopItem from "./ShopItem";

const ShopList = ({ navigation, data, eventData }) => {
  return (
    <View style={styles.container}>
      <ScrollView style={{ height: 1 }} showsVerticalScrollIndicator={false}>
        {data.map((item) => (
          <View key={item.gid || item.id}>
            <Divider height={15} />
            <ShopItem
              navigation={navigation}
              data={item}
              eventId={eventData.gid}
            />
          </View>
        ))}
        <Divider height={100} />
      </ScrollView>
    </View>
  );
};

export default ShopList;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});
