import React from "react";
import { Text, Image, View } from "react-native";
import { useWindowDimensions } from "react-native";
import StyleSheet from "react-native-media-query";

// Constants
import { colors, family } from "../../../../constants/theme";

// Components
import Divider from "../../../../components/atoms/Divider";

const EmptyForLater = () => {
  const height = useWindowDimensions().height;
  return (
    <View style={styles.container}>
      <Text style={styles.title}>Mi despensa</Text>
      <Divider height={20} />
      {height > 700 && (
        <View style={styles.imageContainer}>
          <Image
            style={styles.image}
            source={require("../../../../assets/pantry.png")}
          />
        </View>
      )}
      {height > 700 && <Divider height={10} />}
      <Text style={styles.text} dataSet={{ media: ids.text }}>
        Todavía no tienes ningún producto almacenado en tu despensa
      </Text>
      <Divider height={20} />
      <Text style={styles.text} dataSet={{ media: ids.text }}>
        Los productos de tu despensa son pedidos ya pagados que puedes consumir
        cuando quieras
      </Text>
      <Divider height={20} />
      <Text style={styles.text} dataSet={{ media: ids.text }}>
        Para añadir productos a tu despensa marca "Preventa" en la ventana de
        pago. Los productos en despensa son pedidos ya pagados que todavía no
        quieres consumir
      </Text>
      <Divider height={20} />
    </View>
  );
};

export default EmptyForLater;

const { ids, styles } = StyleSheet.create({
  container: {
    width: "100%",
    paddingHorizontal: 40,
    alignItems: "center",
  },
  title: {
    fontFamily: family.bold,
    color: colors.brown,
    fontSize: 20,
  },
  imageContainer: {
    width: 80,
    height: 80,
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    width: 80,
    height: 80,
    resizeMode: "cover",
  },
  text: {
    fontFamily: family.normal,
    color: colors.brown,
    fontSize: 16,
    textAlign: "center",
    "@media (max-height: 700px)": {
      fontSize: 12,
    },
  },
});
