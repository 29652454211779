const CHAR_LIST = "abcdefghijklmnñopqrstuvwxyz";
const UPPER_CHAR_LIST = "ABCDEFGHIJKLMNÑOPQRSTUVWXYZ";

export const arrayRemove = (arr, value) => {
  return arr.filter(function (ele) {
    return ele != value;
  });
};

export const validateMail = (text) => {
  let result = true;
  let reg = /^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/gm;
  if (reg.test(text) === false) {
    result = false;
  }
  return result;
};

export const validatePassword = (text) => {
  if (text.length < 8) {
    return "La contraseña debe tener más de 8 caracteres";
  }
  //  else if (!/\d/.test(text)) {
  //   return "La contraseña debe contener al menos un número";
  // } else if (!/[A-Z]/.test(text)) {
  //   return "La contraseña debe contener al menos una mayúscula";
  // }
  else {
    return true;
  }
};

export const containsObject = (obj, list) => {
  var i;
  for (i = 0; i < list.length; i++) {
    if (list[i] === obj) {
      return true;
    }
  }
  return false;
};

export const removeDuplicates = (array) => {
  const uniqueArray = array.filter((value, index) => {
    const _value = JSON.stringify(value);
    return (
      index ===
      array.findIndex((obj) => {
        return JSON.stringify(obj) === _value;
      })
    );
  });
  return uniqueArray;
};

export const getCharIndex = (character) => {
  return CHAR_LIST.indexOf(character.toLowerCase());
};

export const getCharFromIndex = (index = 0, type = "lower") => {
  const charList = type === "lower" ? CHAR_LIST : UPPER_CHAR_LIST;
  return charList[index];
};
